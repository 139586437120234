    .cookie__section{
        max-width: 1335px;
        width: 100%;
        margin: auto;
        margin-bottom: 115px;
    }

    .cookie__section--heading{
        font-size:32px;
        color:#424242;
        margin-top: 63px;
        margin-bottom: 20px;
        font-family: 'Open Sans', sans-serif;
    }
    
    .cookie__section--subheading{
        font-size:24px;
        color: #424242;
        margin-top: 40px;
        font-family: 'Open Sans', sans-serif;
    }
    
    .cookie__section--paragraph{
        font-size:14px;
        color:#707070;
        margin-bottom: 25px;
        font-family: 'Open Sans', sans-serif;
    }
    
    .cookie__section--row{
        background-color:#F7F8F9;
    }
    
    .cookie__section--row1{
        background-color:#FBFCFC;
    }
    
    .cookie__section--head{
        font-size:14px;
        color: #515151;
    }
    
    .cookie__section--data{
       font-size:14px; 
       color:#424242;  
       font-family: 'Open Sans', sans-serif;
    }
    
    .version{
        font-size:14px;
        color:#8E8E8E;
        margin-top:30px;
        font-family: 'Open Sans', sans-serif;
    }
    
    .cookie__section--bold{
        font-size:14px;
        color: #424242;
        font-weight: bold;
        font-family: 'Open Sans', sans-serif;
    
    }

    .cookie__title{
        margin-top: 34px;
    }
    
    .url{
        color:#0097E0;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
    }

    .table td {
        font-family: 'Open Sans', sans-serif;
    }

    .table thead th {
        font-weight: 700;
        font-family: 'Open Sans', sans-serif;
    }

    .cookie__title{
        margin-top: -28px;
    }

    .cookie-banner{
        height: 149px !important;
    }
     
    @media(max-width: 767px) {
        .cookie__section {
            table, 
            table td, 
            table tr, 
            table th {
                text-align: left;
                border-bottom: 1px solid #ffffff;
            }

            table th,
            table td {
                margin: 0;
            }

            table td {
                list-style: none;
                display: list-item;
                padding-left: 0;
            }

            table thead {
                display: none;
            }
        }

        .cookie__title{
            margin-top: 0px;
        }
    }

  
  @media(max-width: 576px) {
    .cookie__section--heading {
        font-size: 23px;
    }

    .cookie__section--subheading {
        font-size: 20px;
    }
    .table thead th {
        display: none;
    }
  }
    