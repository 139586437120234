@media only screen and (max-width: 600px)  {

.segnalazioni-mobile-header-background {
    background-color: #1AA1E3;
}

.segnalazioni-mobile-header-background h1 {
    font-size: 22px;
    text-align: center;
    color: #FFFFFF;
    font-weight: bold;
    font-family: "Open Sans", sans-serif;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 60px;
    padding-right: 60px;
    line-height: 30px;
}

.segnalazione-mobile-button {
    text-align: center;
}

button.btn.btn-primary.segnalazione-mobile-btn {
    max-width: 302px;
    height: 57px;
    width: 100%;
    background-color: #ffffff;
    border-radius: 0px;
    border: 2px solid #3490dc;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    color: #3490dc;
    margin-top: 12px;
}

button.btn.btn-primary.segnalazione-mobile-btn-1 {
    max-width: 302px;
    height: 57px;
    width: 100%;
    background-color: #3490dc;
    border-radius: 0px;
    border: 2px solid #3490dc;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 60px;
}

.segnalazione-mobile-line hr{
    width: 302px;
    margin-top: 27px;
    margin-bottom: 27px;
}



.segnalazione-mobile-paragraph{
    font-size: 14px !important;
    font-family: "Open Sans", sans-serif;
    color: #424242;
    font-weight: 600;
}

.segnalazione-mobile-paragraph-bold{
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
    color:#666666;
    line-height: 19px;

}

.segnalazione-mobile-paragraph-regular{
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    color:#666666;
    line-height: 19px;
}

}