
.accordion {
    .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding-right: 75px;

        .btn-collapse {
            background-color: #EDEFF0;
            color: #000000;
            text-decoration: none;
            padding-left: 30px;
            padding-right: 30px;
            position: absolute; 
            top: 0;
            right: 0;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            &.child-btn {
               padding-left: 20px;
               padding-right: 20px; 
            }
        }
    }
}

@media(max-width: 767px) {
    .accordion {
        .card-header {
            padding-right: 60px;

            .btn-collapse {

                padding-left: 20px;
                padding-right: 20px;

                &.child-btn {
                   padding-left: 15px;
                   padding-right: 15px; 
                }
            }
        }
    }
}


// breadcrumbs
.course-details, 
.course-details__holderNav {
    background-color: #F7FCFD;
}


.faq__section{
    margin:auto;
    max-width:1279px; 
    width:100%;
    margin-bottom: 160px;
}


.faq__banner--titles {
    background-color: #0097E0;
    margin-top: auto;
    margin-bottom: auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1112px;
    padding-right: 30px;
}

.faq__section--heading{
    text-transform: uppercase;
    font-size: 32px;
    color: #424242;
    font-family: 'Open Sans', sans-serif;
    margin-top: 55px;
}

.faq__section--paragraph{
    font-size: 16px;
    color: #515151;
    margin-top: 20px;
    margin-bottom: 60px;
    font-family: 'Open Sans', sans-serif;
}

.faq_image{
    background-image:url(/resources/assets/img/Faq-img.png);
    max-width: 48%;
    opacity: 1;
    z-index: 9999999999999;
    float: right;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: 0 33%;
    max-height: 100%;
}


.card {
    border-radius: 0px;
    border: none;
    margin-bottom: 20px;
    .card-header {
        border-bottom:0px;
        height: auto;
    }
    .card-body {
        background-color: #F8F9FA;
    }
}


.child-header{
    background-color: #FFFFFF;
    height: auto !important;
    font-family: 'Open Sans', sans-serif;
}

.child__card--body {
    background-color: #FFFFFF !important;
    font-family: "Open Sans", sans-serif;
    color: #515151;
    font-size: 14px;
    line-height: 22px;
    padding-right: 30px;
}

.child-img {
    background-color: #EDEFF0;
    color: #000000;
    padding: 25px;
    margin-top: -23px;
    margin-right: -20px;
    margin-bottom: -20px;
}

.btn-link{
    padding-top: 4px;
    font-size: 18px;
    color: #0097E0;
    font-family: 'Open Sans', sans-serif;
}

.btn-link:hover{
    color: #0097E0;
    text-decoration: none;
}


.faq__banner--image img{
 width: 100%;
 height:auto;
}

.faq__banner--main-title{
    font-size: 32px;
    color:#FFFFFF;
    margin-left: 183px;
    margin-top: 42px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
}

.faq__banner--paragraph{
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
    border-left: 4px solid #FFFFFF;
    margin-left: 183px;
    padding-left: 12px;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 40px;
    font-weight: 400;
}

.faq__banner--button{
    width: 209px;
    height: 55px;
    border:2px solid #FFFFFF;
    color: #FFFFFF;
    text-transform: uppercase;
    border-radius: 0px;
    padding:3px;
    margin-left: 183px;
    margin-top: 13px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    margin-bottom: 67px;
}

.faq__banner--button:hover{
    color: #FFFFFF;
}


.faq__banner {
    position: relative;

}

h5.mb-0.d-inline.accordion-heading {
    font-size: 25px;
    font-weight: normal;
    color: #0097E0;
    font-family: 'Open Sans', sans-serif;
    line-height: 46px;
}

h5.mb-0.d-inline.accordion-heading-1 {
     font-size: 15px;
     font-family: 'Open Sans', sans-serif;
     font-weight: 500;
     color: #0097E0;
}

 

@media(max-width: 1558px) {
    .faq__banner--titles {
        position: relative;
        top: 0px;
        transform: translateY(0);
        width: auto;
        margin-top: -42px;
    }

    .faq__banner--image img {
        display: none;
    }

    .faq__banner--main-title {
        margin-left: 0px;
        text-align: center;
        padding-top: 45px;
    }

    .faq__banner--paragraph {
        border-left: none;
        margin-left: 0px;
        padding-left: 0px;
        text-align: center;
    }

    .faq__banner--button {
        margin-left: 0px;
    }

    .faq__btn {
        text-align: center;
    }
}


@media(max-width: 767px) {
    .child-img {
        background-color: #EDEFF0;
        color: #000000;
        padding: 5px !important;
        margin-top: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        position: absolute !important;
        top: 0px !important;
        right: 0px !important;
    }
   
    h5.mb-0.d-inline.accordion-heading {
        font-size: 16px;
        line-height: 30px;
        text-align: left;
    }

    .faq__banner--main-title {
        font-size: 22px;
    
    }

    .faq__banner--paragraph {
        font-size: 14px;
    }
    .faq__section--heading {
        font-size: 22px;
    }  
    .faq__section--paragraph {
        font-size: 12px;
    }  
    .faq-banner-mobile-title{
        padding-top: 0px;
    }  
}



    @media(max-width: 576px) {
        .faq__banner--paragraph {
            margin-bottom: 20px;
        }
        .faq__section--heading{
            margin-top: 23px;
            padding-left: 15px;
        }
        .faq__section--paragraph{
            margin-bottom: 15px;
            margin-top: 10px;
            padding-left: 15px;
        }
        .card .card-header {
            border-bottom: 0px;
        }
        
        .faq__banner--main-title {
            padding-top: 0;
        }
        
        .faq__banner--button {
            margin-top: -16px;
            margin-bottom: 23px;
        }

        .faq__section {
            margin-bottom: 45px;
        }

        .card-header{
            height: 50px !important;
        } 

        .child-header{
            height: auto !important;
        } 

    }
        
    


    