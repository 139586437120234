.footer {
    background: #F1F3F4;
    margin: 0!important;
    .container {
        padding: 0;
    }
}
.footer__logo {
    img {
        cursor: pointer;
    }
    div {
        font-size: 12px;
        line-height: 18px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        letter-spacing: 0px;
        color: #666666;
        opacity: 1;
        padding-top: 12px;
        padding-bottom: 15px;
        max-width: 275px;
    }
    span {
        text-align: left;
        font-size: 14px;
        line-height: 30px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        letter-spacing: 0px;
        color: #666666;
        opacity: 1;
    }
}
.footer__catalog, 
.footer__risorse,
.footer__suport,
.footer__follow {
    h5 {
        text-align: left;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        font-size: 14px;
        line-height: 30px;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        display: flex;
        align-items: center;
    }
    li {
        margin-bottom: 6px;
        a {
            text-decoration: none;
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            font-size: 14px;
            line-height: 26px;
        }
    } 
}
.footer__suport {
    div {
        text-align: left;
        font-size: 12px;
        line-height: 20px;
        font-family: 'Open Sans', sans-serif;
        letter-spacing: 0px;
        color: #666666;
        opacity: 1;
        margin-top: 12px;
        margin-bottom: 10px;
        max-width: 225px;
    }
    a {
        text-align: left;
        line-height: 30px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        font-size: 14px;
        letter-spacing: 0px;
        color: #0097E0;
        opacity: 1;
        text-decoration: none;
    }
}

.footer__follow {
    
}
.footer__follow__container {
    display: flex;
    justify-content: space-between;
    margin-top: 23px;
    max-width: 250px;
    img[alt=youtoobe] {
        margin-top: 2px;
    }
}

.copyright-background {
    height: 62px;
    background: #000000 0% 0% no-repeat padding-box;
    opacity: 1;
    display: flex;
    align-items: center;
}
.copyright-content {
    display: flex;
    justify-content: space-between;
    span {
        text-align: center;
        font-size: 12px;
        line-height: 30px;
        font-family: 'Open Sans', sans-serif;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
    }
    .cookie {
        color: #FFFFFF;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        font-size: 14px;
        line-height: 30px;
        opacity: 1;
        margin-right: 25px;
        text-decoration: none;
    }
    .privacy {
        color: #FFFFFF;
        opacity: 1;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        font-size: 14px;
        line-height: 30px;
        text-decoration: none;
    }
}

.info-section h5{
    margin-bottom: -4px;
}

@media (max-width: 767px) {
    .footer {
        .footer__logo {
            text-align: center;
            div {
                max-width: unset;
            }
        }
        .footer__catalog {
            text-align: center;
            h5 {
                display: block;
                text-align: center;
            }
        }
        .footer__risorse {
            text-align: center;
            h5 {
                display: block;
                text-align: center;
            }
        }
        .footer__suport {
            text-align: center;
            h5 {
                display: block;
                text-align: center;
            }
            div {
                max-width: unset;
                text-align: center;
            }
            a {
                display: block;
                text-align: center;
            }
        }
        .footer__follow {
            text-align: center;
            h5 {
                display: block;
                text-align: center;
            }
            div {
                margin: auto;
            }
        }
    }
}
