.privacy__section{
    max-width: 1335px;
    width: 100%;
    margin: auto;
    margin-bottom: 115px;
    }
    
    .privacy__section--heanding{
        font-size:32px;
        color:#424242;
        margin-top: 63px;
        margin-bottom: 20px;
        font-family: 'Open Sans', sans-serif;

    }
    
    .privacy__section--subheading{
      font-size:24px;
      color: #424242;
      margin-top: 50px;
    
    }
    
    .privacy__section--subheading2{
        font-size:20px;
        color:#424242;
        margin-top: 40px;
    }
    
    .privacy__section--modifica{
    font-size:14px;
    color: #8E8E8E;
    font-family: 'Open Sans', sans-serif;
    }
    
    .privacy__section--parapraph{
        font-size:14px;
        color:#707070;
        margin-bottom: 25px;
        font-family: 'Open Sans', sans-serif;
    
    }
    
    .privacy__section--blue{
        font-size:15px;
        text-transform: uppercase;
        color:#0097E0;
        font-family: 'Open Sans', sans-serif;
        font-weight: bold;
    }
    
    .privacy__section--blue1{
        font-size:15px;
        color:#0097E0;
        font-family: 'Open Sans', sans-serif;
        font-weight: bold;
    }

    .privacy__section{
        max-width: 1335px;
        width: 100%;
        margin: auto;
        margin-bottom: 115px;
        }
        
        .privacy__section--heanding{
            font-size:32px;
            color:#424242;
            margin-top: 63px;
            margin-bottom: 20px;
        }
        
        .privacy__section--subheading{
          font-size:24px;
          color: #424242;
          margin-top: 50px;
        
        }
        
        .privacy__section--subheading2{
            font-size:20px;
            color:#424242;
            margin-top: 40px;
        }
        
        .privacy__section--modifica{
        font-size:14px;
        color: #8E8E8E;
        }
        
        .privacy__section--parapraph{
            font-size:14px;
            color:#707070;
            margin-bottom: 25px;
        
        }
        
        .privacy__section--blue{
            font-size:15px;
            text-transform: uppercase;
            color:#0097E0;
        }
        
        .privacy__section--blue1{
            font-size:15px;
            color:#0097E0;
        }
        
        .cookie__title{
                margin-top: 34px;
            }
        
            @media(max-width: 768px) {
                .cookie__title{
                margin-top: 0px;
            }
        }
    
        @media(max-width: 576px) {
            .privacy__section--heanding {
            font-size: 24px;
                }
            }

