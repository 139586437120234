.corsi__all-corsi {
    display: flex;
    justify-content: center;
    .btn-primary {
        width: 270px;
        height: 57px;
        background: #0097E0 0% 0% no-repeat padding-box;
        opacity: 1;
        border-radius: 0;
        text-align: center;
        letter-spacing: 0px;
        margin-bottom: 96px;
        margin-top: -20px;
        color: #FFFFFF;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 26px;
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        text-decoration: none;
        padding-top: 15px;
    }
}

.calendar-btn {
    border: 2px solid #1897E0;
    border-radius: 0;
    color: #1897E0;
    text-transform: uppercase;
    font-weight: bold;
    font-family: "Open Sans", sans-serif;
    width: 100%;
    padding: 18px 20px;
}

.corsi__container__nav {
    background-image: url('../assets/img/prodotti-white.svg');
}

.corsi__nav {
    height: 46px;
    opacity: 1;
    display: flex;
    align-items: center;
    .corsi__nav-home {
        color: $blueDefault;
        font-size: 14px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0px;
        cursor: pointer;
        &:after {
            content: ' ';
            color: #424242;
            margin-left: 8px;
            margin-right: 8px;
            border-right: 1px solid #666666;
        }
    }
    .corsi__nav-corsiSingoli {
        font-size: 14px;
        font-family: 'Open Sans', sans-serif;
        line-height: 22px;     
        letter-spacing: 0px;
        color: #424242;
        cursor: pointer;
    }
}

.corsi__header-background {
    background-image: url('../assets/img/prodotti.svg');
    min-height: 300px;
    background-color: #006393;
}

.corsi__header {
    opacity: 1;
    margin: auto;
    padding-top: 47px;
    padding-bottom: 62px;
    .welcome_title{
        color: white;
        text-align: center;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        font-size: 32px;
        line-height: 45px;
        letter-spacing: 0px;
        opacity: 1;
        margin-bottom: 0;
    }
    h1 {
        color: white;
        text-align: center;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        font-size: 32px;
        line-height: 45px;
        letter-spacing: 0px;
        opacity: 1;
        margin-bottom: 0;
    }
    h3 {
        color: white;
        text-align: center;
        font-size: 18px;
        font-family: 'Open Sans', sans-serif;
        line-height: 35px;
        letter-spacing: 0px;
        text-shadow: 0px 0px 17px #0C0E2967;
        opacity: 1;
    }
}
.corsi__header__inputs {
    display: flex;
    justify-content: center;
    margin-top: 45px;
}
.corsi__header__input-search {
    max-width: 380px;
    width: 100%;
    position: relative;
    margin-right: 8px;
    input {
        height: 56px;
        background: #F7FCFD 0% 0% no-repeat padding-box;
        //border: 1px solid #E6E6E6;
        opacity: 1;
        width: 100%;
        padding-left: 20px;
        text-align: left;
        letter-spacing: 0px;
        color: #8E8E8E;
        outline: none;
        border: none;
        &::placeholder {
            font-size: 14px;
            line-height: 28px;
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
        }
    }
    img {
        position: absolute;
        right: 15px;
        top: 13px;
        width: 25px;
        height: 25px;
        cursor: pointer;
    }
}
.corsi__header__input-filter {
    max-width: 380px;
    width: 100%;
    position: relative;
    margin-left: 8px;
    padding-right: 18px;
    background: #F7FCFD 0% 0% no-repeat padding-box;
    //#select {
    //    width: 380px;
    //}
    select {
        box-shadow: none!important;
        height: 57px;
        background: #F7FCFD 0% 0% no-repeat padding-box;
        opacity: 1;
        width: 100%;
        padding-left: 20px;
        text-align: left;
        letter-spacing: 0;
        color: #8E8E8E;       
        outline: none;
        border: none;
        font-size: 14px;
        line-height: 28px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        border-radius: 0;
        &:focus {
            background: #F7FCFD 0% 0% no-repeat padding-box;
        }
    }
}

.corsi__header__input-filter.no-slider {
    input::placeholder {
        color: #424242;
    }
}

.corsi__slider-title {
    text-align: center;
    font-size: 28px;
    font-family: 'Open Sans', sans-serif;
    line-height: 35px;
    letter-spacing: 0px;
    color: #424242;
    opacity: 1;
    display: block;
    padding-bottom: 30px;
    padding-top: 58px;
}

.corsi__online {
    margin-bottom: 60px;
    position: relative;
    max-width: 1500px;
}
.corsi__online-title {
    text-align: center;
    font-size: 28px;
    line-height: 35px;
    font-family: 'Open Sans', sans-serif;
    color: #424242;
    padding-top: 45px;
}

.corsi__online-title-noSlider {
    text-align: center;
    font-size: 28px;
    line-height: 35px;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0px;
    color: #424242;
    opacity: 1;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
}
.corsi__online__result {
    margin-top: 73px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
    margin-left: auto;
    span {
        text-align: left;
        font-size: 14px;
        line-height: 28px;
        font-family: "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #424242;
        margin-right: 21px;
        padding-top: 5px;
        b {
            padding-right: 4px;
        }
    }
    span.text {
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        line-height: 28px;
        letter-spacing: 0;
        color: #424242;
        margin-right: 2px;
    }
    .dropdown {
        padding-top: 4px;
        padding-right: 15px;
        .dropdown-toggle {
            z-index: 9999;
            position: relative;
            text-align: left;
            font-weight: 700;
            font-size: 14px;
            line-height: 28px;
            font-family: "Open Sans", sans-serif;
            letter-spacing: 0px;
            color: #0097E0;
            opacity: 1;
            cursor: pointer;
        }
    }
    .dropdown-menu {
        top: -40px !important;
        text-align: left;
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        line-height: 28px;
        font-weight: bold;
        letter-spacing: 0px;
        color: #0097E0;
        opacity: 1;
        min-width: 222px;
        padding-top: 65px;
        left: 20px !important;
        border: none;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 36px #0725361A;
        a {
            text-align: left;
            font-family: "Open Sans", sans-serif;
            font-size: 14px;
            font-weight: 600;
            line-height: 28px;
            letter-spacing: 0;
            opacity: 1;
            color: #424242;
            height: 53px;
            &:hover {
                color: #0097E0;
            }
        }
    }
}

.corsi__online__item {
    border: none;
}

.corsi__online__item__gradient {
    width: 100%;
    height: 190px;
    background: transparent linear-gradient(180deg, #D3EFFF 0%, #35B2FA 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    position: relative;
    img {
        position: absolute;
        margin: auto;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }
    .gradient-text {
        letter-spacing: 0px;
        color: #FFFFFF;
        font-family: 'Open Sans', sans-serif;
        font-size: 24px;
        position: absolute;
        bottom: 12px;
        left: 24px;
    }
}
.corsi__online__item__grey {
    width: 100%;
    height: 118px;
    font-size: 20px;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0px;
    color: $blueDefault;
    text-transform: capitalize;
    opacity: 1;
    padding: 12px 20px 20px 24px;
    background: #F8F9FA 0% 0% no-repeat padding-box;
    position: relative;
}
.corsi__online__item__grey-text {
    font-size: 20px;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0px;
    color: $blueDefault;
    text-transform: capitalize;
    opacity: 1;
}
.corsi__online__item__grey-images {
    position: absolute;
    right: 20px;
    display: flex;
    bottom: 20px;
    .heart {
        padding-left: 18px;
        opacity: 0.5;
    }
}

.corsi__training {
    width: 100%;
    max-width: 1510px;
    min-height: 260px;
    background: #DEEEF5 0% 0% no-repeat padding-box;
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 30px auto 45px;
}
.corsi__training-img {
    position: relative;
    margin-left: 60px;
    margin-right: 80px;
    width: 250px;
    img {
        position: absolute;
        bottom: -25px;
    }
}
.holder-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.corsi__training__text {
    margin-top: 67px;
    margin-bottom: 79px;
}
.corsi__training__text-title {
    margin-bottom: 16px;
    text-align: left;
    letter-spacing: 0px;
    color: #424242;
    opacity: 1;
    font-size: 32px;
    line-height: 35px;
    font-family: 'Open Sans', sans-serif;
}
.corsi__training__text-description {
    text-align: left;
    line-height: 30px;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0px;
    color: #515151;
    opacity: 1;
    font-size: 18px;
}
.corsi__training__button {
    align-self: center;
    margin-right: 118px;
    .banner-btn {
        background: #0097E0 0% 0% no-repeat padding-box;
        text-transform: uppercase;
        font-size: 14px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        line-height: 26px;
        border-radius: 1px;
        padding: 20px 30px;
    }
}
// .corsi__training.container {
//     max-width: 1510px;
// }
.button__primary {
    align-self: center;
    margin-right: 25px;
    border-radius: 0px;
    button {
        width: 274px;
        height: 57px;
        opacity: 1;
        text-transform: uppercase;
        border-radius: 0px !important;
    }
}

.corsi__install {
    margin-top: 60px;
}
.corsi__install-title {
    text-align: center;
    font-size: 28px;
    line-height: 35px;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0px;
    color: #424242;
    opacity: 1;
    display: block;
}

.corsi__install-subtitle {
    // text-align: center;
    font-size: 18px;
    line-height: 30px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    color: #515151;
    margin-top: 0;
    margin-bottom: 40px;
}

.corsi__stage {
    margin-top: 60px;
}
.corsi__stage-title {
    text-align: center;
    font-size: 28px;
    line-height: 35px;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0px;
    color: #424242;
    opacity: 1;
    display: block;
}
.corsi__stage-subtitle {
    text-align: center;
    font-size: 18px;
    line-height: 30px;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0px;
    padding-top: 7px;
    display: block;
    padding-bottom: 42px;
    color: #515151;
    opacity: 1;
}

.corsi__license {
    margin-top: 60px;
}
.corsi__license-title {
    text-align: center;
    font-size: 28px;
    line-height: 35px;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0px;
    color: #424242;
    opacity: 1;
    display: block;
}
.corsi__license-subtitle {
    text-align: center;
    font-size: 18px;
    line-height: 30px;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0px;
    padding-top: 7px;
    display: block;
    padding-bottom: 42px;
    color: #515151;
    opacity: 1;
}

.corsi__service {
    margin-top: 60px;
    margin-bottom: 170px;
}
.corsi__service-title {
    text-align: center;
    font-size: 28px;
    line-height: 35px;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0px;
    color: #424242;
    opacity: 1;
    display: block;
    margin-bottom: 35px;
}

.no-slider-training {
    margin-bottom: 176px;
}

.orange-bg{
        position: absolute;
        left: 143px;
        background-color: #FABB45;
        border-radius: 12px;
        font-size: 10px;
        color: #ffffff;
        top: 10px;
        font-family: 'Open Sans', sans-serif;
}

.blue-bg{
    background-color: #87C7EC;
    left: 159px;
    font-family: 'Open Sans', sans-serif;
}

.red-bg{
    background-color: #EF6660 ;
    left: 159px;
    font-family: 'Open Sans', sans-serif;
}
.round-div{
    background-color: #FFF500;
    width: 139px;
    z-index: 9;
    cursor: pointer;
    margin-right: -161px;
}

.main-div {
    margin-top: 18%;
    text-align: center;
}

.main-div span{
    color: #151515;
text-align: left;
font: normal normal normal 15px/20px Open Sans;
letter-spacing: 0px;
color: #515151;
opacity: 1;
font-size: 14px;
}

.popup-window{
    width:988px;
     position:absolute; 
     background-color: white;
     right: auto;
     height: auto;
     z-index: 1;
     border: 3px solid #FFF500;
     padding-right: 176px;
    padding-left: 151px;
    padding-top: 37px;
    padding-bottom: 20px;
}

.blue-bg-popup{
    background-color: #87C7EC;
    color: #ffffff;
    padding: 10px;
    border-radius: 20px;
    font-size: 10px;
    font-family: 'Open Sans', sans-serif;
}

.red-bg-popup{
    background-color: #EF6660;
    color: #ffffff;
    padding: 10px;
    border-radius: 20px;
    font-size: 10px;
    font-family: 'Open Sans', sans-serif;
}

.green-bg-popup{
    background-color: #79BC74;
    color: #ffffff;
    padding: 10px;
    border-radius: 20px;
    font-size: 10px;  
    font-family: 'Open Sans', sans-serif; 
}

.orange-bg-popup{
    background-color: #FABB45;
    color: #ffffff;
    padding: 10px;
    border-radius: 20px;
    font-size: 10px;
    font-family: 'Open Sans', sans-serif;
}

.popup-section-1{
    
        text-align: right;
}

.bold-popup{
    font-size:10px;
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;
    text-align: right;
}


@media (max-width: 1600px) {
    .corsi__training-img {
        img {
            position: relative;
        }
    }
    .holder-content {
        flex-flow: column    
    }
    .corsi__training__text {
        margin-top: 45px;
    }
    .corsi__training__button {
        margin-right: 30px;
        margin-left: 25px;
        margin-bottom: 25px;
        button {
            width: 240px;
        }
    }
    .corsi__training.container {
        max-width: 930px;
    }
}

.close-window{
    position: absolute;
    right: 7px;
    top: 5px;
}

@media (max-width: 991px) {
    .corsi__header {
        padding-top: 30px;
    }

    .corsi__header__inputs {
        flex-flow: column;
        margin-top: 25px;
        .corsi__header__input-search {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 20px;
            max-width: 300px;
        }
        .corsi__header__input-filter {
            margin-left: auto;
            margin-right: auto;
            max-width: 300px;
        }
    }

    .corsi__online-title {
        font-size: 22px;
        line-height: 32px;
        text-align: left;
        margin-bottom: 30px;
        padding-top: 25px;
        padding-bottom: 0;
    }

    .corsi__online-title-noSlider {
        top: -55px;
        left: 15px;
        font-size: 22px;
        text-align: left;
    }
    .corsi__online__result {
        justify-content: space-between;
        margin-bottom: 8px;
    }
    .home__slider__holderBox {
        justify-content: center;
    }
    
    .corsi__training {
        .holder-content {
            flex-flow: column;
        }
        .corsi__training__text {
            margin-bottom: 45px;
            margin-right: 30px;
        }
        .corsi__training__button {
            margin-bottom: 30px;
            margin-left: auto;
        }
    }
    .corsi__training.container {
        max-width: 690px;
    }
    .round-div  {
        display: none;
    }
    .popup-window {
        display: none;
    }
    h3.mycourses-title {
        margin-top: 20px !important ;

    }
    
}
@media (max-width: 767px) {
    .corsi__online__item {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
    }
    
    .corsi__training {
        flex-flow: column;
        .corsi__training-img {
            margin: auto;
            img {
                margin-right: 0;
                margin-left: 0;
            }
        }
        .corsi__training__text {
          margin: 34px;
            div {
                text-align: center;
            }
        }
        .corsi__training__button {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 30px;
        }
    }

    .my-courses-1 {
        margin-top: 113px !important;
    }
}
.mobile-courses {
    .view-more{
        display: none;
    }
}

@media (max-width: 575px) {
    .my-courses-1 {
        margin-top: 30px !important;
    }
    #catalogo-corsi-section{
        .corsi__item{
            width: 100%;
            max-width: 100%;
        }
    }
    .corsi__online {
        margin-bottom: 30px;
    }
    .corsi__online-title {
        margin-bottom: 15px;      
    }
    .corsi__install-subtitle{
        margin-bottom: 20px;
        font-size: 16px;
    }
    .mobile-courses {
        padding-right: 0;
        .view-more{
            display: block;
            position: absolute;
            right: 20px;
            bottom: 15px;
            color: #0097E0;
            font-size: 14px;
            font-weight: bold;
            font-family: 'Open Sans', sans-serif;
            i{
                font-weight: 600;
            }
        }
        .owl-item:nth-child(n+8){
            .corsi__item{
                display: none;
            }
        }
        // .owl-pagination{
        //     span {
        //           display: none !important;
        //         }
        //   }
        .owl-pagination :nth-child(n+6){
          span {
            display: none !important;
              }
        }
    }
    .corsi__header {
        padding-bottom: 42px;
        .welcome_title{
            font-size: 22px;
            padding-top: 24px;
            padding-bottom: 5px;
            line-height: 20px;
        }
        .welcome_subtitle{
            font-size: 14px;
            padding: 0;
            line-height: 20px;
            font-weight: normal;
        }
        h1 {
            font-size: 28px;
        }
        h3 {
            font-size: 16px;
        }
    }
    .modal-info-1{
        border-bottom: 1px solid #E6E6E6;
    }
}

@media (max-width: 375px) {
    .corsi__training__text-description {
     font-size:12px !important;
        line-height: 22px;
    }
    .owl-theme .owl-controls .owl-page span {
        width: 9px !important;
        height: 9px !important;
        background-color: #E6E6E6 !important;
    }
    .owl-theme .owl-controls .owl-page.active span, .owl-theme .owl-controls.clickable .owl-page:hover span{
        background-color: #6FCDE4 !important;
    }
}


.mobile-courses {
    .owl-pagination {
        text-align: left;
    }
}