.corsi__item {
    // width: 380px;
    height: 315px;
    background: #fff;
    margin-top: 10px;
    padding-right: 10px;
    position: relative;
}

.corsi__item--link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    text-indent: 200%;
    white-space: nowrap;
    font-size: 0;
    opacity: 0;
}

.corsi__item-gradient {
    width: 100%;
    height: 190px;
    background: transparent linear-gradient(180deg, #D3EFFF 0%, #35B2FA 100%) 0% 0% no-repeat padding-box;
    position: relative;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent linear-gradient(180deg, rgba(211,239,255, .3) 0%, rgba(53,178,250, .6) 100%) 0% 0% no-repeat padding-box;
        // background: transparent linear-gradient(180deg, rgba(14, 131, 188, 0) 0%, rgba(14, 131, 188, 0.37) 35%, rgba(14, 131, 188, 0.8) 70%, rgba(14, 131, 188, 0.93) 100%) 0% 0% no-repeat padding-box;
    }

    img {
        position: absolute;
        margin: auto;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top center;
    }
    .gradient-text {
        color: #FFFFFF;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        font-size: 10px;
        line-height: 26px;
        position: absolute;
        bottom: 12px;
        left: 20px;
        z-index: 2;
        span {
            font-weight: 700;
            font-size: 22px;
        }
        .iva {
            font-size: 14px;
        }
    }
}

.corsi__item-grey {
    width: 100%;
    height: 120px;
    font-size: 20px;
    color: $blueDefault;
    text-transform: capitalize;
    padding: 12px 20px 20px 25px;
    background: #F8F9FA 0% 0% no-repeat padding-box;
    position: relative;
}

.corsi__item-grey-text {
    font-size: 20px;
    line-height: 30px;
    font-family: 'Open Sans', sans-serif;
    color: $blueDefault;
    text-transform: capitalize;
}

.corsi__item-grey-images {
    position: absolute;
    right: 33px;
    display: flex;
    bottom: 20px;
    
    .heart {
        padding-left: 15px;
        opacity: 0.5;
    }
}

@media (max-width: 991px) {
    .owl-item{
        max-width: 255px;
    }
    .corsi__item {
        height: 265px;
    }

    .corsi__item-gradient {
        height: 130px;
    }

    .corsi__item-grey {
        height: 135px;
    }
}
@media (max-width: 576px) {
    .owl-item{
        max-width: 255px;
    }
    .corsi__item {
        max-width: 255px;
        height: 265px;
    }
}


// @media (max-width: 575px) {
//     .corsi__item-grey-text {
//         font-size: 14px;
//         line-height: 22px;
//     }
// }