.video {
    background: #F7FCFD;
    .navbar {
        min-height: 46px;
        opacity: 1;

        .nav-item {
            color: $blueDefault;
            font-size: 14px;
            line-height: 22px;
            font-family: 'Open Sans', sans-serif;
            font-weight: 700;
            letter-spacing: 0px;
            cursor: pointer;
            padding: 0;

            &:after {
                content: ' ';
                color: #424242;
                margin-left: 8px;
                margin-right: 8px;
                border-right: 1px solid #666666;
            }

            &:last-child {
                text-align: left;
                color: #666666;
                font-weight: 400;

                &:after {
                    content: none;
                }
            }
        }
    }

    .result {
        margin-top: 51px;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 15px;
        span {
            text-align: left;
            font-size: 14px;
            line-height: 28px;
            font-family: 'Open Sans', sans-serif;
            letter-spacing: 0px;
            color: #424242;
            margin-right: 21px;
            padding-top: 5px;

            b {
                padding-right: 4px;
            }
        }

        .dropdown {
            padding-top: 4px;
            padding-right: 15px;
        }
    }

    .dropdown .dropdown-toggle {
        z-index: 9999;
        position: relative;
        text-align: left;
        font-weight: 700;
        font-size: 14px;
        line-height: 28px;
        font-family: 'Open Sans', sans-serif;
        letter-spacing: 0px;
        color: #0097E0;
        opacity: 1;
        cursor: pointer;
    }


    .dropdown-menu {
        top: -40px!important;
        text-align: left;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        line-height: 28px;
        font-weight: bold;
        letter-spacing: 0px;
        color: #0097E0;
        opacity: 1;
        min-width: 222px;
        padding-top: 65px;
        left: -64px !important;
        border: none;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 36px #0725361A;
        a {
            text-align: left;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            font-weight: 600;
            line-height: 28px;
            letter-spacing: 0;
            opacity: 1;
            color: #424242;
            height: 53px;
            &:hover {
                background: #F7FCFD 0% 0% no-repeat padding-box;
                opacity: 1;
                color: #0097E0;;
            }
        }
    }

    .dropdown-toggle[aria-expanded=true]:after {
        top: -2px;
        transform: rotate(180deg);
    }

}
.video-header {
    opacity: 1;
    margin: auto;
    //padding-bottom: 52px;
    h1 {
        color: white;
        text-align: center;
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        font-size: 32px;
        line-height: 45px;
        letter-spacing: 0px;
        opacity: 1;
        padding-top: 77px;
        margin-bottom: 60px;
    }
}
.video-result {
    margin-top: 40px!important;
    margin-bottom: 38px!important;
    margin-left: auto!important;
    margin-right: auto!important;
    padding-left: 30px;
}
.video-header-background {
    background-image: url('../assets/img/prodotti.svg');
    background-color: #006292;
    min-height: 300px;
    opacity: 1;
    width: 100%;
}
.video-header__inputs {
    display: flex;
    justify-content: center;
    margin-top: 45px;
}
.video-header__first-input {
    max-width: 380px;
    width: 100%;
    position: relative;
    margin-right: 8px;
    padding-right: 11px;
    background: #F7FCFD 0% 0% no-repeat padding-box;
    select {
        height: 57px;
        background: #F7FCFD 0% 0% no-repeat padding-box;
        opacity: 1;
        width: 100%;
        padding-left: 20px;
        text-align: left;
        letter-spacing: 0;
        color: #8E8E8E;
        outline: none;
        border: none;
        font-size: 14px;
        line-height: 28px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        border-radius: 0;
        box-shadow: none!important;
        &:focus {
            background: #F7FCFD 0% 0% no-repeat padding-box;
        }
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';
        &::-ms-expand {
            display: none;
        }
    }
    .fas {
        position: absolute;
        top: 0;
        right: 15px;
        bottom: 0;
        margin: auto;
        color: #8E8E8E;
        height: 12px;
    }
}
.video-header__second-input {
    max-width: 380px;
    width: 100%;
    position: relative;
    margin-left: 8px;
    padding-right: 11px;
    background: #F7FCFD 0% 0% no-repeat padding-box;
    select {
        height: 57px;
        background: #F7FCFD 0% 0% no-repeat padding-box;
        opacity: 1;
        width: 100%;
        padding-left: 20px;
        text-align: left;
        letter-spacing: 0;
        color: #8E8E8E;
        outline: none;
        border: none;
        font-size: 14px;
        line-height: 28px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        border-radius: 0;
        box-shadow: none!important;
        &:focus {
            background: #F7FCFD 0% 0% no-repeat padding-box;
        }
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';
        &::-ms-expand {
            display: none;
        }
    }
    .fas {
        position: absolute;
        top: 0;
        right: 15px;
        bottom: 0;
        margin: auto;
        color: #8E8E8E;
        height: 12px;
    }
}


.holder-video-card {
    .card {
        border: none;
        margin-bottom: 25px;
    }
    .card-body {
        background: #F8F9FA;
        max-width: 518px;
    }
    .holder-img {
        max-width: 518px;
        height: 300px;
        background: transparent linear-gradient(180deg, #D3EFFF 0%, #35B2FA 100%) 0% 0% no-repeat padding-box;
        position: relative;
        display: block;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            margin: 0;
        }
    }
    .card-title {
        text-align: left;
        font-size: 20px;
        line-height: 26px;
        font-weight: 400;
        font-family: 'Open Sans', sans-serif;
        letter-spacing: 0px;
        color: #0097E0;
        text-transform: capitalize;
        opacity: 1;
        margin-bottom: 35px;
        a {
            text-decoration: none;
        }
    }
    .card-text {
        text-align: left;
        font-size: 12px;
        line-height: 23px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        color: #515151;
        margin-top: 0;
        margin-bottom: 0;
        span {
            text-align: left;
            font-weight: 700;
            color: #424242;
            opacity: 1;
            margin-left: 8px;
        }
    }
}
.video.video-all {
    margin-top: 60px;
    margin-bottom: 60px;
    display: flex;
    justify-content: center;
    background: white;
    .btn-outline-primary {
        border: 2px solid #0097E0;
        opacity: 1;
        text-align: center;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        font-family: 'Open Sans', sans-serif;
        letter-spacing: 0px;
        color: #0097E0;
        &:hover {
            color: white;
        }
    }
}


.singleVideo {
    background: #F7FCFD;
    .navbar {
        min-height: 46px;
        opacity: 1;
        .nav-item {
            color: $blueDefault;
            font-size: 14px;
            line-height: 22px;
            font-family: 'Open Sans', sans-serif;
            font-weight: 700;
            letter-spacing: 0px;
            cursor: pointer;
            padding: 0;

            &:after {
                content: ' ';
                color: #424242;
                margin-left: 8px;
                margin-right: 8px;
                border-right: 1px solid #666666;
            }

            &:last-child {
                text-align: left;
                color: #666666;
                font-weight: 400;

                &:after {
                    content: none;
                }
            }
        }
    }

    .result {
        margin-top: 51px;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 15px;
        span {
            text-align: left;
            font-size: 14px;
            line-height: 28px;
            font-family: 'Open Sans', sans-serif;
            letter-spacing: 0px;
            color: #424242;
            margin-right: 21px;
            padding-top: 5px;

            b {
                padding-right: 4px;
            }
        }

        .dropdown {
            padding-top: 4px;
            padding-right: 15px;
        }
    }

    .dropdown .dropdown-toggle {
        z-index: 9999;
        position: relative;
        text-align: left;
        font-weight: 700;
        font-size: 14px;
        line-height: 28px;
        font-family: 'Open Sans', sans-serif;
        letter-spacing: 0px;
        color: #0097E0;
        opacity: 1;
        cursor: pointer;
    }


    .dropdown-menu {
        top: -40px!important;
        text-align: left;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        line-height: 28px;
        font-weight: bold;
        letter-spacing: 0px;
        color: #0097E0;
        opacity: 1;
        min-width: 222px;
        padding-top: 65px;
        left: -64px !important;
        border: none;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 36px #0725361A;
        a {
            text-align: left;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            font-weight: 600;
            line-height: 28px;
            letter-spacing: 0;
            opacity: 1;
            color: #424242;
            height: 53px;
            &:hover {
                background: #F7FCFD 0% 0% no-repeat padding-box;
                opacity: 1;
                color: #0097E0;;
            }
        }
    }

    .dropdown-toggle[aria-expanded=true]:after {
        top: -2px;
        transform: rotate(180deg);
    }

}
.holder-singleVideo {
    .card {
        border: none;
        max-width: 1024px;
        height: auto;
        margin: 0 auto;
    }
    .card-body {
        padding-left: 0;
        background: white;
    }
    .holder-img {
        max-width: 1024px;
        background: transparent linear-gradient(180deg, #D3EFFF 0%, #35B2FA 100%) 0% 0% no-repeat padding-box;
        position: relative;
    }
    .card-title {
        text-align: center;
        color: #424242;
        opacity: 1;
        font-size: 32px;
        line-height: 51px;
        font-weight: 600;
        font-family: 'Open Sans', sans-serif;
        letter-spacing: 0px;
        text-transform: capitalize;
        margin-bottom: 35px;
        margin-top: 29px;
    }
    .card-text {
        text-align: left;
        font-size: 12px;
        line-height: 23px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        color: #515151;
        margin-top: 0;
        margin-bottom: 0;
        span {
            text-align: left;
            font-weight: 700;
            color: #424242;
            opacity: 1;
            margin-left: 8px;
        }
    }
}
.video-all {
    margin-top: 60px;
    margin-bottom: 60px;
    display: flex;
    justify-content: center;
    .btn-outline-primary {
        padding: 15px 50px;
        border: 2px solid #0097E0;
        opacity: 1;
        text-align: center;
        font: Bold 14px/24px Open Sans;
        letter-spacing: 0px;
        color: #0097E0;
        &:hover {
            color: white;
        }
    }
}


.corsi__install-mobile,
.corsi__training-mobile,
.hide-button,
.order-status-menu {
    display: none;
}

.video-mobile{
   display: none;
}



@media (max-width: 992px) {
    .video-header-background {
        h1 {
            margin-bottom: 30px;
            padding-top: 50px;
        }
        .video-header__inputs {
            flex-flow: column;
            flex-direction: column;
            margin-top: 0;
            .video-header__first-input {
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 15px;
            }
            .video-header__second-input {
                margin: auto;
            }
        }
    }
}

@media (max-width: 767px) {
    div#nonloop {
        display: block !important;
    }

    div#nonloop1 {
        display: block !important;
    }

    .see-more-mobile {
        text-align: right;
        margin-top: -49px;
    }

    .corsi__install-mobile h3{
        font-size: 22px;
        color:#424242 ;
        font-family: 'Open Sans', sans-serif;
        margin-top: 33px;
    }
    .see-more-mobile a{
       font-size: 14px;
       text-decoration: none;
        color: #0097E0;
        font-weight: bold;
        text-transform: uppercase;
        font-family: 'Open Sans', sans-serif;
    }

    .calendario-button {
        text-align: center;
        margin-top: 50px;
        margin-bottom: 50px;
    }
    
    button.btn.calendario-btn-mobile {
        max-width: 302px;
        height: 57px;
        border: 2px solid #1897E0 ;
        background-color: #FFFFFF;
        border-radius: 0;
        color: #1897E0;
        text-transform: uppercase;
        font-weight: bold;
        font-family: 'Open Sans', sans-serif;
        width: 100%;

    }
    .corsi__training.container {
        margin-top: 70px;
    }
}

@media (max-width: 576px) {
    .singleVideo {
        .navbar {
            .nav-item {
                font-size: 12px;
            }
        }
    }
    .video-all .btn-outline-primary {
        border: 2px solid #0097e0;
        opacity: 1;
        text-align: center;
        font: 700 14px/24px Open Sans;
        letter-spacing: 0;
        color: #0097e0;
        max-width: 302px;
        width: 100%;
        border-radius: 0px;
        height: 57px;
    }

    .holder-singleVideo .card-title {
        font-size: 22px;
    }

    .holder-singleVideo .card {
        height: 408px;
        margin-left: 17px;
        margin-right: 17px;
    }

    .sidenav {
        display: block !important;
        z-index: 999999;
    }

    .corsi__slider.container,
    .corsi__install.container,
    .corsi__stage,
    .corsi__license,
    .corsi__service,
    .corsi__training-web{
        display: none;
    }

    .corsi__install-mobile,
    .corsi__training-mobile {
        display: block;
    }

    .hide-button{
        display: inline;
    }

    .corsi__online.corsi-online-2.container {
        display: block;
    }
    span.order-status-menu-info {
        text-align: center;
        color: #ffffff;
        font-family: "Open Sans", sans-serif;
        font-weight: bold;
        letter-spacing: 0px;
        color: #FFFFFF;
        font-size: 13px;
        
    }
    .order-status-menu {
        display: block;
        text-align: center;
    }

    .video-mobile{
        display: block;
        margin-bottom: 38px;
    }

    span.order-status-menu-info.active {
        border-bottom: 2px solid #0097e0;;
    }

 
    .software-inputs{
        margin-left: 15px;
        margin-right: 15px;
    }

    .twitter-typeahead{
        position: initial !important;
        display: inline-block;
    }

    .video-mobile-results {
        margin-top: -27px !important;
    }

    .software-result {
        margin-top: -35px  !important;
    } 
    .holder-video-card .holder-img {
        max-height: 175px;
    }
}