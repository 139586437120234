@media only screen and (max-width: 600px)  {


.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #ffffff;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
    box-shadow: -3px 0 5px 0 #555;
}

.sidebar-section-1 {
    background-color: #ffffff;
    padding-bottom: 13px;

}

.sidebar-section-2 h3 {
    padding-top: 14px;
    padding: 8px 8px 8px 32px;
    font-weight: normal;
    font-size: 22px;
    color: #0097E0;
    margin-top: 13px;
    font-family: "Open Sans", sans-serif;
}

.sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 22px;
    color: #515151;
    display: block;
    transition: 0.3s;
    font-family: "Open Sans", sans-serif;
}

.sidenav a:hover {
    color: #f1f1f1;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;

    font-size: 36px;

}

.sidebar-section-2 {
    background-color: #F8F9FA;
    padding-bottom: 410px;
    padding-top: 1px;
}


.sidebar-section-2 a {
    font-size: 14px;
    color: #515151;
    display: block;
    transition: 0.3s;
    font-family: "Open Sans", sans-serif;
    line-height: 6px;
    margin-top: 13px;

}

.sidebar-section-margin {
    margin-top: -28px;
}

.prove {
    display: flex;
    margin-top: 19px;
}

.home__notification__text h2 {
    font-size: 15px;
}

.btn-success {
    margin-top: 10px !important;
    margin-left: 67px;
}

.home__training-title {
    text-align: left;
    font-size: 24px;
}

.home__training-subtitle {
    text-align: left;
    font-size: 14px;
    line-height: 23px;
}

.home__training__item__text h2 {
    font-size: 14px;
    line-height: 17px;
}

.home__insertImage__img__content p {
    font-size: 17px;
}

.home__slider-title {
    text-align: left;
    font-size: 23px;
    margin-top: -211px;
}

.home__slider-subtitle {
    text-align: left;
    font-size: 11px;

}

.home__slider-subtitle {
    margin-bottom: -5px;
}

.home__video__container__text div {
    font-size: 14px;
}

.home__video__container__text h1 {
    font-size: 22px;
}

.home__video__container__video {
    margin-bottom: 41px;
}

// .menu-line {
//     width: 259px;
//     border-bottom: 1px solid #F1F3F4;
// }

.mobile-header {
    padding: 20px;
    display: flex ;
    align-items: center;
    justify-content: space-between;
}

.mobile-header-logo {
    text-align: center;
    // margin-top: -27px;
}

.mobile-header-search {
    text-align: right;
    // margin-top: -29px;
    font-size: 22px;
    cursor: pointer;
}

.mobile-header span {
    font-size: 22px;
    cursor: pointer;
}

.menu-line {
    width: 259px;
    border-bottom: 1px solid #F1F3F4;
    margin-top: 0px;
    margin-bottom: 0;
}

/*footer*/
.footer-card-header {
    background-color: #F1F3F4;
    height: 45px !important;
}

.footer-collapse-img {
    margin-top: -22px;
}



.footer-card-body {
    margin-top: -6px;
    background-color: #F1F3F4 !important;
    display: grid;
    text-align: center;

}

.footer .footer__logo {
    text-align: left;
    margin-bottom: 22px;
}

.footer__logo img {
    width: 127px;
}

.footer .footer__follow div {
    padding-top: 14px;
}

.footer-heading {
    font-size: 14px;
    color: #000000;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;

}

.footer-card-link {
    text-decoration: none;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #6c757d;
    line-height: 30px;
}



/*home*/
.home__baner--mobile {
    position: relative;
    height: 525px;
    background-color: #DAEBF3;
    opacity: 1;
}

.image-mobile {
    text-align: center;
}


.image-mobile img {
max-width: 660px;
width: 100%;
height: auto;
margin-top: 9px;
}

.program-mobile h2 {
    font-size: 23px;
    color: #000000;
    font-weight: 600;
    font-family: "Open Sans", sans-serif;

}

.program-mobile {
    margin-left: 20px;
}

.home__baner--mobile .program-mobile ul li:before {
    content: url(../public/assets/img/np_done.svg);

}

.home__baner--mobile .program-mobile ul {
    list-style: none;
    text-decoration: none;
    padding-left: 0;
    margin-bottom: 30px;
}

.home__baner--mobile .program-mobile ul li {
    text-align: left;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 10px;
}

.program-mobile span {
    font-size: 12px;
    color: #000000;
    font-family: "Open Sans", sans-serif;

}

.program-mobile a {
    max-width: 302px;
    height: 57px;
    padding-top: 14px;
    width: 100%;
    border-radius: 0pp;
    border-radius: 0px;
    font-size: 14px;
    font-weight: bold;
    font-family: "Open Sans", sans-serif;
    margin-top: 24px;
}

.home__notification__text-mobile h2 {
    text-align: left;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0px;
    color: #424242;
    text-transform: lowercase;
    font-size: 13px;
}

.home__notification__text-mobile button {
    width: 65px;
    height: 30px;
    font-size: 10px;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    background: #4CD161 0% 0% no-repeat padding-box;
    border-radius: 19px;
    align-items: center;
    display: flex;
    justify-content: center;
    outline: none;
    border: none;
    margin-top: 25px;
    margin-bottom: 12px;
    text-transform: uppercase;
}

.home__notification__text-mobile span {
    text-align: left;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    letter-spacing: 0px;
    color: #666666;
    opacity: 1;
    display: block;
}

.home__notification__text-mobile a {
    text-align: left;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    line-height: 28px;
    font-size: 14px;
    letter-spacing: 0px;
    color: #0097E0;
    opacity: 1;
    display: block;
    margin-top: 18px;
    text-decoration: none;
}

.home__notification-mobile {
    background: #F8F9FA 0% 0% no-repeat padding-box;
    opacity: 1;
    min-height: 257px;
}

.home__notification__text-mobile.col-sm-5 {
    padding-left: 32px;
    padding-right: 20px;
}

.home__training-title-mobile {
    margin-top: 70px;
    margin-bottom: 15px;
    text-align: left;
    line-height: 35px;
    font-size: 22px;
    font-family: "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #424242;
    opacity: 1;
    margin-left: 20px;
}

.home__training-subtitle-mobile {
    text-align: left;
    font-size: 12px;
    line-height: 24px;
    font-family: "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #666666;
    opacity: 1;
    max-width: 900px;
    margin-bottom: 41px;
    margin-left: 20px;
    margin-right: 23px;
}

.home__training__container-mobile {
    display: flex;
    justify-content: center;
    margin-bottom: 26px;
}

.home__training__container-item-mobile {
    display: flex;
    opacity: 1;
    align-items: center;
    padding: 20px;
    height: 90px;
    box-shadow: 0px 0px 20px #0F44651F;
    border-radius: 8px;
    justify-content: space-between;
    max-width: 499px;
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 10px;
}

.home__training__item__text-mobile {
    width: 100%;
    margin-left: 15px;
}

.home__training__item__text-mobile h2 {
    text-align: left;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0px;
    color: #424242;
    text-transform: capitalize;
    opacity: 1;
    margin-bottom: 2px;
    margin-top: 5px;
}

.home__training__item__text-mobile a {
    text-align: left;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0px;
    color: #0097E0;
    opacity: 1;
    display: block;
    text-decoration: none;
}

.home__training__item-img-mobile img {
    width: 59px;
    height: 59px;
}

.home__training__item-img {
    height: 80px;
}

.home-mobile-button {
    max-width: 293px;
    height: 57px;
    width: 100%;
    border-radius: 0px;
    font-size: 14px;
    font-weight: bold;
    font-family: "Open Sans", sans-serif;
    margin-top: 15px;

}

.home-mobile-btn {
    text-align: center;
}

.home__insertImage__text-mobile h1 {
    font-size: 22px;
    font-family: "Open Sans", sans-serif;
    color: #424242;

}

.home__insertImage__text-mobile div {
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    color: #666666;
    line-height: 24px;

}

.home__insertImage__text-mobile a {
    font-size: 14px;
    color: #0097E0;
    font-family: "Open Sans", sans-serif;
    font-weight: bold;

}

.home__insertImage__text-mobile {
    margin-top: 51px;
    margin-bottom: 78px;
    padding-left: 27px;
}

.home__insertImage__img-mobile {
    text-align: center;
}


img.home__insertImage__img-image-mobile {
    width: 306px;
}

.owl-carousel {
    display: block;
}

h1.home__slider-title-mobile {
    font-size: 22px;
    color: #424242;
    margin-top: 62px;
    font-family: "Open Sans", sans-serif;
}


.home__slider-subtitle-mobile {
    font-size: 10px;
    color: #424242;
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
    margin-top: 13px;
}


.home__slider__holderBox__box-mobile {
max-width: 586px;
width: 100%;
height: auto;
display: flex;
opacity: 1;
padding: 22px 22px;
box-shadow: 0px 0px 20px #0F44651F;
border-radius: 8px;
justify-content: space-between;
margin-bottom: 25px;
margin-left: 20px;
margin-right: 20px;
}

.home__slider__holder__box-img-mobile img{
width: 20px;
height: 27px;
margin-top: 9px;

}

.home__slider__holder__box__text-mobile {
width: 100%;
height: auto;
}

.home__slider__holder__box__text-mobile h2 {
font-family: "Open Sans", sans-serif;
font-weight: 600;
text-align: left;
letter-spacing: 0px;
color: #424242;
opacity: 1;
font-size: 18px;
line-height: 30px;
margin-bottom: 12px;
margin-top: 5px;
margin-left: 5px;
}

.home__slider__holder__box__text-mobile span {
display: block;
text-align: left;
font-size: 14px;
font-family: "Open Sans", sans-serif;
line-height: 22px;
letter-spacing: 0px;
color: #666666;
opacity: 1;
min-height: 65px;
}

.home__slider__holder__box__text-mobile a {
text-align: left;
letter-spacing: 0px;
color: #0097E0;
text-transform: uppercase;
opacity: 1;
font-size: 14px;
font-family: "Open Sans", sans-serif;
font-weight: 700;
display: block;
text-decoration: none;
margin-top: 12px;
}

button.btn.btn-primary.border-0.btn-calendar-mobile {
max-width: 242px;
width: 100%;
margin-left: 21px;
height: 55px;
font-size: 14px;
font-weight: bold;
color: #ffffff;
font-family: "Open Sans", sans-serif;
border-radius: 0px;
margin-bottom:57px;
}

.home__slider__holderBox {
margin-top: 32px;
}

}

@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }

    .sidenav a {
        font-size: 18px;
    }
}