.rapid-news {
    background: #F7FCFD;
    .navbar {
        min-height: 46px;
        opacity: 1;

        .nav-item {
            color: $blueDefault;
            font-size: 14px;
            line-height: 22px;
            font-family: 'Open Sans', sans-serif;
            font-weight: 700;
            letter-spacing: 0px;
            cursor: pointer;
            padding: 0;

            &:after {
                content: ' ';
                color: #424242;
                margin-left: 8px;
                margin-right: 8px;
                border-right: 1px solid #666666;
            }

            &:last-child {
                text-align: left;
                color: #666666;
                font-weight: 400;

                &:after {
                    content: none;
                }
            }
        }
    }
}

.rapid-news-header {
    opacity: 1;
    margin: auto;
    // padding-bottom: 52px;

    h1 {
        color: white;
        text-align: center;
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        font-size: 32px;
        line-height: 45px;
        padding-top: 77px;
        margin-bottom: 60px;
    }
}

.rapid-news-header-background {
    background-image: url('../assets/img/prodotti.svg');
    min-height: 300px;
    width: 100%;
    background-color: #006393;
}

.rapid-news-header__inputs {
    display: flex;
    justify-content: center;
}

.rapid-news-header__first-input {
    max-width: 380px;
    width: 100%;
    position: relative;
    margin-right: 8px;
    padding-right: 18px;
    background: #F7FCFD 0% 0% no-repeat padding-box;

    select {
        height: 57px;
        background: #F7FCFD 0% 0% no-repeat padding-box;
        opacity: 1;
        width: 100%;
        padding-left: 20px;
        text-align: left;
        letter-spacing: 0;
        color: #8E8E8E;
        outline: none;
        border: none;
        font-size: 14px;
        line-height: 28px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        border-radius: 0;
        box-shadow: none !important;

        &:focus {
            background: #F7FCFD 0% 0% no-repeat padding-box;
        }
    }
}

.rapid-news-header__second-input {
    max-width: 380px;
    width: 100%;
    position: relative;
    margin-left: 8px;
    padding-right: 18px;
    background: #F7FCFD 0% 0% no-repeat padding-box;

    select {
        height: 57px;
        background: #F7FCFD 0% 0% no-repeat padding-box;
        opacity: 1;
        width: 100%;
        padding-left: 20px;
        text-align: left;
        letter-spacing: 0;
        color: #8E8E8E;
        outline: none;
        border: none;
        font-size: 14px;
        line-height: 28px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        border-radius: 0;
        box-shadow: none !important;

        &:focus {
            background: #F7FCFD 0% 0% no-repeat padding-box;
        }
    }
}

.pagination-content {
    position: relative;
    opacity: 1;
    height: auto;
    display: flex;
    flex-flow: column-reverse;
    .rapid_news-result {
        top: -25px;
        bottom: unset!important;
        padding-left: 15px;
    }

    table {
        max-width: 1360px;
        margin: 0 auto;

        tr {
            th {
                font-weight: 600;
                font-size: 14px;
                //line-height: 30px;
                line-height: 0;
                font-family: 'Open Sans', sans-serif;
                letter-spacing: 0px;
                color: #515151;
                text-transform: capitalize;
                opacity: 1;
                background: white;
                white-space: pre;
                padding-bottom: 20px;
                border: none;
                cursor: pointer;
                img {
                    width: 6px;
                    height: 9px;
                    margin-left: 14px;
                    margin-bottom: 2px;
                }
            }
            
            td.first-td {
                border: 0;
                .first-td-content {
                    display: flex;
                    align-items: center;
                }

                img {
                    width: 19px;
                    height: 19px;
                    margin-right: 22px;
                    margin-left: 10px;
                }


                .title {
                    text-align: left;
                    font: Bold 14px/30px Open Sans;
                    font-family: 'Open Sans', sans-serif;
                    font-size: 14px;
                    line-height: 17px;
                    font-weight: 700;
                    letter-spacing: 0px;
                    color: #0097E0;
                    text-transform: capitalize;
                    opacity: 1;
                }

                .text {
                    text-align: left;
                    font-size: 14px;
                    line-height: 22px;
                    font-family: 'Open Sans', sans-serif;
                    letter-spacing: 0px;
                    color: #424242;
                    text-transform: capitalize;
                    opacity: 1;
                }
            }

            .publication, .category, .dimension {
                padding-top: 30px;
                border: 0;
            }

            .file-name {
                border: 0;
                padding-top: 30px;
            }

            td {
                text-align: left;
                font-family: 'Open Sans', sans-serif;
                font-size: 14px;
                line-height: 17px;
                letter-spacing: 0px;
                color: #515151;
                opacity: 1;
            }

            &:nth-child(even) {
                background: rgba(247, 248, 249, 0.31);
            }

            &:nth-child(odd) {
                background: rgba(243, 245, 246, 1);
            }
        }

        tbody {
            tr {
                border-top: unset!important;
                border-bottom: unset!important;
                td {
                    border-bottom: 4px solid white !important;
                }
            }
        }
    }
    
    #softwareTable_wrapper {
        #softwareTable_length {
            margin-left: 92px;
            position: absolute;
            right: 99px;
            bottom: 121px;
            font-weight: 700;
            font-size: 14px;
            line-height: 28px;
            font-family: "Open Sans", sans-serif;
            letter-spacing: 0;
            color: #0097E0;
            opacity: 1;
            select {
                cursor: pointer;
                border: 0;
                color: #0097E0;
            }      
        }
        #softwareTable_filter {
            display: none;
        }
        .dataTables_info {
            position: absolute;
            bottom: 129px;
            right: 188px;
            font-size: 14px;
            line-height: 28px;
            font-family: "Open Sans", sans-serif;
            letter-spacing: 0;
            color: #424242;
            margin-right: 27px;
        }
        #softwareTable_paginate {
            max-width: 1334px;
            margin-top: 36px;
            margin-bottom: 126px;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            justify-content: center;
            .paginate_button.first {
                cursor: pointer;
                display: flex;
                align-items: center;
                &:focus {
                    border: 0;
                    outline: 0;
                }
                &::before {
                    content: "\F100";
                    font-family: FontAwesome;
                    opacity: 1;
                    text-decoration: none;
                    font-size: 14px;
                    white-space: nowrap;
                    padding: 0 16px;
                }
            }
            .paginate_button.previous {
                cursor: pointer;
                display: flex;
                align-items: center;
                &:focus {
                    border: 0;
                    outline: 0;
                }
                &::before {
                    content: "\f104";
                    font-family: FontAwesome;
                    opacity: 1;
                    text-decoration: none;
                    font-size: 14px;
                    white-space: nowrap;
                    padding: 0 16px;
                }
            }
            .paginate_button.next {
                cursor: pointer;
                display: flex;
                align-items: center;
                &:focus {
                    border: 0;
                    outline: 0;
                }
                &::before {
                    content: "\f105";
                    font-family: FontAwesome;
                    opacity: 1;
                    text-decoration: none;
                    font-size: 14px;
                    white-space: nowrap;
                    padding: 0 16px;
                }
            } 
            .paginate_button.last {
                cursor: pointer;
                display: flex;
                align-items: center;
                &:focus {
                    border: 0;
                    outline: 0;
                }
                &::before {
                    content: "\f101";
                    font-family: FontAwesome;
                    opacity: 1;
                    text-decoration: none;
                    font-size: 14px;
                    white-space: nowrap;
                    padding: 0 16px;
                }
            }
            span {
                display: flex;
                .paginate_button {
                    cursor: pointer;
                    opacity: 1;
                    text-decoration: none;
                    white-space: nowrap;
                    padding: 0 16px;
                    height: 30px;
                    width: 30px;
                    display: flex;
                    align-items: center;
                    &:focus {
                        border: 0;
                        outline: 0;
                    }
                }
                .paginate_button.current {
                    cursor: pointer;
                    background-color: #0097E0;
                    padding: 0 16px;
                    color: white;
                    border-radius: 50px;
                    opacity: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-decoration: none;
                    text-align: left;
                    font-weight: 700;
                    white-space: nowrap;
                    height: 30px;
                    width: 30px;
                }
            }
            .paginate_button.disabled {
                &::before {
                    opacity: 0.5;
                }
            }
        }
    }
   
    #rapidNewsTable_wrapper {
        #rapidNewsTable_length {
            margin-left: 92px;
            position: absolute;
            right: 99px;
            bottom: 121px;
            font-weight: 700;
            font-size: 14px;
            line-height: 28px;
            font-family: "Open Sans", sans-serif;
            letter-spacing: 0;
            color: #0097E0;
            opacity: 1;
            select {
                cursor: pointer;
                border: 0;
                color: #0097E0;
            }
        }
        #rapidNewsTable_filter {
            display: none;
        }
        .dataTables_info {
            position: absolute;
            bottom: 129px;
            right: 188px;
            font-size: 14px;
            line-height: 28px;
            font-family: "Open Sans", sans-serif;
            letter-spacing: 0;
            color: #424242;
            margin-right: 27px;
        }
        #rapidNewsTable_paginate {
            max-width: 1334px;
            margin-top: 36px;
            margin-bottom: 126px;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            justify-content: center;
            .paginate_button.first {
                cursor: pointer;
                display: flex;
                align-items: center;
                &:focus {
                    border: 0;
                    outline: 0;
                }
                &::before {
                    content: "\F100";
                    font-family: FontAwesome;
                    opacity: 1;
                    text-decoration: none;
                    font-size: 14px;
                    white-space: nowrap;
                    padding: 0 16px;
                }
            }
            .paginate_button.previous {
                cursor: pointer;
                display: flex;
                align-items: center;
                &:focus {
                    border: 0;
                    outline: 0;
                }
                &::before {
                    content: "\f104";
                    font-family: FontAwesome;
                    opacity: 1;
                    text-decoration: none;
                    font-size: 14px;
                    white-space: nowrap;
                    padding: 0 16px;
                }
            }
            .paginate_button.next {
                cursor: pointer;
                display: flex;
                align-items: center;
                &:focus {
                    border: 0;
                    outline: 0;
                }
                &::before {
                    content: "\f105";
                    font-family: FontAwesome;
                    opacity: 1;
                    text-decoration: none;
                    font-size: 14px;
                    white-space: nowrap;
                    padding: 0 16px;
                }
            }
            .paginate_button.last {
                cursor: pointer;
                display: flex;
                align-items: center;
                &:focus {
                    border: 0;
                    outline: 0;
                }
                &::before {
                    content: "\f101";
                    font-family: FontAwesome;
                    opacity: 1;
                    text-decoration: none;
                    font-size: 14px;
                    white-space: nowrap;
                    padding: 0 16px;
                }
            }
            span {
                display: flex;
                .paginate_button {
                    cursor: pointer;
                    opacity: 1;
                    text-decoration: none;
                    white-space: nowrap;
                    padding: 0 16px;
                    height: 30px;
                    width: 30px;
                    display: flex;
                    align-items: center;
                    &:focus {
                        border: 0;
                        outline: 0;
                    }
                }
                .paginate_button.current {
                    cursor: pointer;
                    background-color: #0097E0;
                    padding: 0 16px;
                    color: white;
                    border-radius: 50px;
                    opacity: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-decoration: none;
                    text-align: left;
                    font-weight: 700;
                    white-space: nowrap;
                    height: 30px;
                    width: 30px;
                }
            }
            .paginate_button.disabled {
                &::before {
                    opacity: 0.5;
                }
            }
        }
    }
    .result {
        bottom: 126px;
        position: absolute;
        right: 0;
        display: flex;
        margin-right: 100px;
        margin-top: 0;
        margin-bottom: 0;
        span {
            text-align: left;
            font-size: 14px;
            line-height: 28px;
            font-family: 'Open Sans', sans-serif;
            letter-spacing: 0px;
            color: #424242;
            //margin-right: 27px;
            padding-top: 5px;

            b {
                padding-right: 4px;
            }
        }
        .dropdown-menu {
            padding-top: 0;
            top: -30px !important;
            min-width: 205px;
            left: 5px !important;
            .dropdown-toggle {
                top: 6px;
                left: 67px;
                &:after {
                    content: none;
                }
            }
        }
        .dropdown {
            padding-top: 4px;
            padding-right: 0;
            //.dropdown-toggle {
            //    z-index: 0;
            //}
        }
    }
}

#software {
    .pagination-content {
        table {
            tr {
                .file-name {
                    border: 0;
                    padding-top: 30px;
                }
            }
        }
    }
}

//th.pl-0.text-left.sorting_asc {
//    padding-bottom: 50px;
//}


//th.pl-0.text-left.sorting_desc {
//    padding-bottom: 50px;
//}
@media (min-width: 1280px) and (max-width: 1599px){
    .pagination-content{
        
    }
}


@media (max-width: 2560px) {
.result{
    display: flex;
}
}

@media (max-width: 1668px) {
    .mycourses-table {
        overflow:scroll;
    }
}

@media (max-width: 1600px) {
    .pagination-content {
        .result {
            margin-right: 15px;
        }
        #softwareTable_wrapper {
            #softwareTable_length {
                margin-left: 0;
                bottom: 75px;
                right: 387px;
                label {
                    margin-bottom: 7px;
                }
            }
            .dataTables_info {
                bottom: 82px;
                right: 467px;
            }
        }
        #rapidNewsTable_wrapper {
            #rapidNewsTable_length {
                margin-left: 0;
                bottom: 75px;
                right: 390px;
                label {
                    margin-bottom: 7px;
                }
            }
            .dataTables_info {
                bottom: 82px;
                right: 467px;
            }
        }
    }

  
}

@media (max-width: 992px) {
    .rapid-news-header-background {
        h1 {
            margin-bottom: 30px;
            padding-top: 50px;
        }
        .rapid-news-header__inputs {
            flex-flow: column;
            flex-direction: column;
            .rapid-news-header__first-input {
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 15px;
            }

            .rapid-news-header__second-input {
                margin: auto;
            }
        }
    }
    .pagination-content {
        #softwareTable_wrapper {
            #softwareTable_length {
                right: 267px;
            }
            .dataTables_info {
                right: 345px;
            }
        }
        #rapidNewsTable_wrapper {
            #rapidNewsTable_length {
                right: 267px;
            }
            .dataTables_info {
                right: 350px;
            }
        }
    }
}

@media (max-width: 768px) {
    .pagination-content table tr td.first-td {
        display: block;
    }

    .pagination-content {
        .rapid_news-result {
            justify-content: space-between!important;
            .dropdown {
                right: 15px;                
            }
        }
        #rapidNewsTable {
            tr {
                td.first-td {
                    img {
                        display: block!important;
                    }
                }
                td.date {
                    display: none!important;
                }
            }
        }
        .result {
            bottom: 70px;
            right: 0;
            left: 0;
            margin: 0;
            display: flex;
            justify-content: center;
        }
        #softwareTable_wrapper {
            #softwareTable_length {
                right: 180px;
            }
            .dataTables_info {
                right: 260px;
            }
        }
        #rapidNewsTable_wrapper {
            #rapidNewsTable_length {
                right: 180px;
            }
            .dataTables_info {
                right: 260px;

            }
        }
    }
    td.publication, td.category, td.file-name, td.dimension { 
        display: none;
    }
    div#softwareTable_info {
        display: none;
    }
   
   .result{
       display: flex;
   }

   .pagination-content #rapidNewsTable tbody tr td.first-td img {
    display: none !important;
    }

    div#rapidNewsTable_info {
        display: none;
    }
    .pagination-content .result span {
    margin-right: 0px; 
    }
    .orderStatusTable{
        width: 100% !important;
    }

    .pagination-content #rapidNewsTable_wrapper #rapidNewsTable_length label {
        display: none;
    }
}

@media (max-width: 576px) {
    .pagination-content{
        margin-top: 15px !important;
    }
    #softwareTable{
        margin-top: 0 !important;
    }
    .pagination-content #rapidNewsTable_wrapper #rapidNewsTable_length label {
        display: block;
    }
    .dataTables_wrapper{
        position: relative;
        padding-top: 40px;
    }
    #rapidNewsTable_wrapper {
        #rapidNewsTable_length{
            top: 0;
            display: block !important;        
            right: 0 !important;   
        }
        .dataTables_info {
            top: 0;
            left: 0 !important;
            display: block !important;
            margin-right: 0 !important;
            right: 188px !important;
        }
    }

    .rapid-news-new{
        display: none;
    }
    
    .pagination-content #softwareTable_wrapper {
        label{
            display: block;
        }

        #softwareTable_length{
            top: 0;
            display: block !important;        
            right: 0 !important;   
        }

        .dataTables_info{
            top: 0;
            left: 0 !important;
            display: block !important;
            margin-right: 0 !important;
            right: 188px !important;
        }
    }

}
@media (max-width: 375px) {
    .dataTables_wrapper{
        padding-top: 0;
    }
}