.listino__ricambi--heading{
    font-size: 25px;
    color: #424242;
    margin-top: 50px;
    margin-bottom: 22px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    .listino__ricambi--heading--span{
        font-family: 'Open Sans', sans-serif;
        font-weight: normal;
    }
}

    .listino__ricambi__paragraph{
    font-size:14px;
    color:#424242;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    margin-top: 0;
    }

    .spare__parts{
          text-transform:uppercase;
          color:#0097E0;
          margin-top: 19px;
          font-family: 'Open Sans', sans-serif;
          font-weight: bold;
          font-size: 14px;
    }

 .aggiungia__button{
   color:#ffffff;
   background-color: #0097e0;
   border: 1px solid #0097e0;
   text-transform: uppercase;
   border-radius: 0px;
   font-family: 'Open Sans', sans-serif;
   font-weight: bold;
   padding: 13px 25px;
 }

 .listino__section--form {
    // height: 51px;
    height: auto;
    border-radius: 0px;
    border: 1px solid #E6E6E6;
    font-family: 'Open Sans', sans-serif;
    padding: 13px 25px;
}

.stampa__button{
    color: #0097e0;
    background-color: #ffffff;
    border: 2px solid #0097e0;
    width: 157px;
    height: 55px;
    border-radius: 0px;
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
}

.stampa__button:hover{
    background-color: #ffffff;
    color:#0097E0;
}

.table th{
    border-top: none;
    border-bottom: none;
    font-size: 14px;
    color:#515151 ;
    }


    .listino__ricambi--info{
        border-bottom:1px solid #E6E6E6 ;
        padding-bottom: 20px;
        padding-top: 20px;
    }

    .table__code{
        font-size: 14px;
        color:#424242;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
    }

    .table__info{
       font-size: 14px; 
       color: #515151;
       font-family: 'Open Sans', sans-serif;
      

       .table__mail{
           color:#0097E0;
           font-family: 'Open Sans', sans-serif;
           font-weight: bold;

       }
    }

    .table__color--1{
        background-color: #F7F8F9;
    }

    .table__color--2{
        background-color: #F3F5F6;
    }

    tr{
        border-top: hidden;
    }

    .annulla{
        font-size: 14px;
        text-transform: uppercase;
        color: #0097e0;
    }


    tr{
        border-top: hidden;
    }
    .page-link {
        border:none;
    }

    .codice_selezionati{
        color: #0097E0;
        border: 1px solid #0097E0;
        border-radius: 20.25px;
        padding-left: 12px;
        padding-right: 5px;
        margin-left: 12px;
        padding-top: 4px;
        padding-bottom: 4px;
        text-align: left;
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        font-size: 14px;
    }


.codice_title{
    font-size: 14px;
    color:#424242;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    margin-top: 4px;
}

.annulla{
        font-size: 14px;
        text-transform: uppercase;
        color: #0097e0;
        margin-left: 12px;
        font-family: 'Open Sans', sans-serif;
        font-weight: bold;
        margin-top: 4px;
}
    

    .listino__section--codici{
        display: flex;
        margin-top:20px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
    }

  .pagination{
    margin-bottom: 97px;
    margin-top: 24px;
  }
    .pagination-link{
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: 0px;
    line-height: 1.25;
    color: #BFC2C5;
    background-color: #fff;

    }

    .pagination-link:hover{
        text-decoration: none;

    }

    .pagination-link-1{
    background-color: #0097E0;
    border-radius: 37px;
    color: #ffffff;
    font-size: 14px;
    }

    .pagination-link-1:hover{
        color:#ffffff;
    }


    .result.spare__parts--result {
        transform: translateY(-128px);
    }

    .spare__parts--result span:nth-child(2){
      font-family: 'Open Sans', sans-serif;
      color:#0097E0;
      font-weight: bold;
      }

      .spare__parts-chevron{
    font-size: 9px;
    font-weight: 600;
      }

      .spare__parts-1 {
        margin-left: 8px;
    }

    .codice-close{
        background-color: #0097E0;
        border-radius: 50%;
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 0px;
        color: #ffffff;
        margin-left: 7px;
        cursor: pointer;
    }

    .stampa__btn{
        text-align: right;
    }

    .spare__parts--code{
        display: flex;
        margin-top: 20px;
    }

    p.listino__ricambi--paragraph {
        text-align: center;
        color: #ffffff;
    }

    .sidebar-1 {
        margin: 0;
        padding: 0;
        width: 347px;
        background-color: #F7F8F9;
        position: absolute;
        height: auto;
        overflow: auto;
        height: 79%;

    }

    .sidebar-1 a {
        display: block;
        color: #1897E0;
        padding: 16px;
        text-decoration: none;
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        margin-left: 172px;
        margin-right: 20px;

    }

    .sidebar-1 h3 {
        margin-top: 30px;
        text-align: left;
        margin-left: 174px;
        font-size: 25px;
        color: #424242;
    }

    

    .sidebar-1 a:hover:not(.active) {
        background-color: #1897E0;
        color: white;
        border-radius: 10px;
    }

    div.content {
        margin-left: 200px;
        padding: 1px 16px;
        height: 1000px;
    }

    .mycourses-title{
text-align: left;
font: normal normal bold 25px/19px Open Sans;
letter-spacing: 0px;
color: #424242;
opacity: 1;
margin-top: 50px;
    margin-bottom: 50px;
    }

    .item-details{
        display: none;
    }
 
@media(max-width: 2560px) {
.mobile-parag{
    display: none;
}

.mobile-banner-line{
    display: none;
}

.lista{
    display: none;
}
    

}



@media(max-width: 1024px) {
    .spare__parts-1 {
        font-size: 11px;
    }
}

 
@media(max-width: 767px) {
    .result.spare__parts--result {
        display: none;
    }

    .stampa__btn{
        text-align:center;
    } 

    .stampa__button {
        margin-bottom: 23px;
    } 

    .listino__ricambi--paragraph{
        display:none;
    }
}

    
@media(max-width: 575px) {
    .dropdown .dropdown-menu.sort_listino-ricambi{
        padding: 0;
        margin: 0;
        min-width: 100px !important;
        padding-top: 0 !important;
        top: 0 !important;
    }
    

    .item-details{
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 63px;
        .item-details__back{
            padding-left: 30px;
            margin-bottom: 20px;
            margin-top: 25px;
            .return-back-form{
                border: none;
                background-color: transparent;
                font-weight: normal;
                color: #515151;
                font-family: 'Open Sans', sans-serif;
                font-size: 14px;
                line-height: 30px;
                .fa-angle-left{
                    font-weight: bold;
                    font-size: 16px;
                    padding-right: 10px;
                }
            }
        }
        .item-details__table{      
            .item-details__title{
                padding-top: 20px;
                color: #0097E0;
                font-size: 14px;
                font-weight: bold;
                font-family: 'Open Sans', sans-serif;
            }      
            .item-details__date{
                font-weight: bold;
                color: #515151;
                font-family: 'Open Sans', sans-serif;
                font-size: 10px;
                line-height: 18px;
                text-align: right;
                padding-top: 20px;
            }
           
            tr.mycourses-table-data-details:first-of-type{
                border-top: none;
            }
            tr.mycourses-table-data-details{
                
                border-top: 1px solid #515151;
               td{
                    display: table-cell;
                    padding: 9px 0 7px;
                    font-weight: 600;
                    color: #515151;
                    font-family: 'Open Sans', sans-serif;
                    font-size: 12px;
                }
                .data-detail{
                    text-align: right;
                    font-weight: normal;
                }
            }
        }
        .item-details-inner{
            padding-left: 30px;
            padding-right: 30px;
            background-color: #F7F8F9;
        }
    }
    .form-inline{
        margin-top: 1.5rem !important;
    }
    
    .result.spare__parts--result {
        display: none;
    }

    .stampa__btn{
        text-align: center;
    }

    .stampa__button {
        margin-top: 20px;
    }
    .table th, .table td{
        border-top: none;
    }
    tr.mycourses-table-first-row {
        background-color: #F7F8F9;
        border-top: 1px solid white;
        .table-course-name_showdetailsButton{
            border: none;
            background-color: transparent;
            color: #0097E0;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            font-weight: bold;
        }
    }
    tr.mycourses-table-second-row {
        background-color:  rgba(243, 245, 246, 0.3);
    }


    tr.odd {
        background-color: #F7F8F9;
    }
    tr.even {
        background-color:  rgba(243, 245, 246, 0.3);
    }

    .table-course-name{
        border-top: none;
        border-bottom: none;
        font-size: 14px;
        color: rgba(0, 151, 224, 1);
    }

    .statoOrdini-download-butoon {
        text-align: center;
    }
    button.btn.statoOrdini-btn {
        width: 100%;
        height: 57px;
        border: 2px solid #0097E0;
        border-radius: 0px;
        max-width: 302px;
        color: #0097E0;
        text-transform: uppercase;
        font-weight: bold;
    }
    .mobile-banner-line{
        width: 113px;
        margin-top: -9px;
        border: 1.5px solid #0097E0;
    }
    .mobile-parag{
        display: block;
    }
    
    .mobile-banner-line{
        display: block;
    }

    img.heart.heart-1 {
        margin-top: 0px;
        padding-left: 11px;
        margin-left: 2px;
        margin-right: 33px;
    }

    .lista{
        display: block;
    }
    
    .spare__parts-1 {
        font-size: 14px !important;
    }

    .move-this{  
            margin-left: 8px;
    }
    .listino__section--img {
        margin-top: 11px;
    }
    .listino__section--text {
        margin-top: 9px;
    }

    .mycourses-title {
        margin-left: 16px;
    }
    
    }

    @media(max-width: 476px) {
      
        table, table td, table tr, table th {
            text-align: left;
            // border-bottom: 1px solid #ffffff;
        }
    
            table th, table td {
                margin: 0;
            }
    
            table td {
                list-style: none;
                display: list-item;
                padding-left: 0;
            }
    
            // table thead {
            //     display: none;
            // }
        }
    
    @media(max-width: 451px) {
      
        .spare__parts--code {
           display: grid;
        }

        .listino__ricambi__paragraph {
            font-size: 12px;
            color: #424242;
            font-family: "Open Sans", sans-serif;
            font-weight: 400;
            line-height: 22px;
        }
        
        
        
    }
    
    
@media(max-width: 476px) {
    .daterangepicker .calendar-table table td {
        display: table-cell;
    }

    .daterangepicker .calendar-table table thead {
        display: table-header-group;
    }
}

@media(max-width: 476px) {
    .daterangepicker .calendar-table table tr {
        height: auto;
    }
}