.header {
    width: 100%;
    height: 84px;
    top: 0px;
    left: 0px;
    border: 1px solid #EDF1F2;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
}

.search-spinner {
    position: absolute;
    top: 15px;
    right: 15px;
    display: none;
    width: 28px;
    height: 28px;
}

.header__logoSection {
    display: flex;
    justify-content: space-between;
}
.header__logoSection-logo {
    align-self: center;
    cursor: pointer;
}
.header__logoSection-corsi {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 58px;
    letter-spacing: 0px;
    color: #8E8E8E;
    text-transform: uppercase;
    opacity: 1;
    cursor: pointer;
    padding-left: 10px;
}
.header__logoSection-risorse {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 58px;
    letter-spacing: 0px;
    color: #8E8E8E;
    text-transform: uppercase;
    opacity: 1;
    cursor: pointer;
    padding-right: 30px;
}

.header__logoSection-risorse.change-link-color {
    a {
        color: #8E8E8E;
        text-decoration: none;
    }
}

.holder__mega-course {
    .single-course {
        h3 {
            text-align: left;
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            font-size: 19px;
            line-height: 30px;
            letter-spacing: 0px;
            color: #515151;
            opacity: 1;
            margin-bottom: 18px;
        }
        a {
            text-align: left;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            line-height: 15px;
            font-weight: 600;
            letter-spacing: 0px;
            color: #0097E0;
            opacity: 1;
            display: block;
            cursor: pointer;
            text-decoration: none;
        }
        div {
            text-align: left;
            font-family: 'Open Sans', sans-serif;
            font-size: 12px;
            line-height: 30px;
            letter-spacing: 0;
            color: #515151;
            opacity: 1;
            padding-bottom: 15px;
            border-bottom: 1px solid #E6E6E6;
            margin-right: 30px;
            margin-bottom: 15px;
        }
        .see-all {
            text-align: left;
            font-family: 'Open Sans', sans-serif;
            font-weight: 700;
            font-size: 14px;
            line-height: 30px;
            letter-spacing: 0px;
            color: #0097E0;
            opacity: 1;
        }
    }
    .training-course {
        h3 {
            text-align: left;
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            font-size: 19px;
            line-height: 30px;
            letter-spacing: 0px;
            color: #515151;
            opacity: 1;
            margin-bottom: 18px;
        }
        a {
            text-align: left;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            line-height: 15px;
            font-weight: 600;
            letter-spacing: 0px;
            color: #0097E0;
            opacity: 1;
            display: block;
            text-decoration: none;
            cursor: pointer;
        }
        div {
            text-align: left;
            font-family: 'Open Sans', sans-serif;
            font-size: 12px;
            line-height: 30px;
            letter-spacing: 0;
            color: #515151;
            opacity: 1;
            padding-bottom: 15px;
            border-bottom: 1px solid #E6E6E6;
            margin-right: 30px;
            margin-bottom: 15px;
        }
        .see-all {
            text-align: left;
            font-size: 14px;
            line-height: 30px;
            font-family: 'Open Sans', sans-serif;
            font-weight: 700;
            letter-spacing: 0px;
            color: #0097E0;
            opacity: 1;
            text-decoration: none;
            cursor: pointer;
        }
    }
    .calendar-course {
        h3 {
            text-align: left;
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            font-size: 19px;
            line-height: 30px;
            letter-spacing: 0px;
            color: #515151;
            opacity: 1;
            margin-bottom: 18px;
        }
        span {
            text-align: left;
            font-size: 14px;
            line-height: 30px;
            font-weight: 700;
            font-family: 'Open Sans', sans-serif;
            letter-spacing: 0px;
            color: #515151;
            opacity: 1;
            margin-bottom: 5px;
            display: block;
        }
        a {
            text-align: left;
            font-weight: 700;
            //line-height: 58px;
            font-size: 14px;
            font-family: 'Open Sans', sans-serif;
            letter-spacing: 0px;
            color: #0097E0;
            text-transform: uppercase;
            opacity: 1;
            margin-top: 9px;
            margin-bottom: 46px;
            display: block;
            text-decoration: none;
            cursor: pointer;
        }
    }
    .sedi {
        h3 {
            text-align: left;
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            font-size: 19px;
            line-height: 30px;
            letter-spacing: 0px;
            color: #515151;
            opacity: 1;
            margin-bottom: 18px;
        }
        span {
            text-align: left;
            font-size: 14px;
            line-height: 30px;
            font-weight: 700;
            font-family: 'Open Sans', sans-serif;
            letter-spacing: 0px;
            color: #515151;
            opacity: 1;
        }
        div {
            text-align: left;
            font-family: 'Open Sans', sans-serif;
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0;
            color: #515151;
            opacity: 1;
        }
        a {
            text-align: left;
            font-weight: 700;
            //line-height: 58px;
            font-size: 14px;
            font-family: 'Open Sans', sans-serif;
            letter-spacing: 0px;
            color: #0097E0;
            text-transform: uppercase;
            opacity: 1;
            margin-top: 9px;
            margin-bottom: 46px;
            display: block;
            text-decoration: none;
            cursor: pointer;
        }
    }
}
.mega-course {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 40px #0000001A;
    opacity: 1;
    //z-index: -1;
    top: -53px!important;
    left: -27px!important;
    width: 1143px;
    min-height: 613px;
    padding: 77px 22px 63px 22px;
    .dropdown-toggle {
        position: absolute;
        top: 12px;
        left: 26px;
    }
}
.mega-course.dropdown-menu.show {
    display: flex!important;
    border: none;
    border-radius: 0;
    z-index: 9999999;
}

.holder__mega-resources {
    .install {
        h3 {
            text-align: left;
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            font-size: 19px;
            line-height: 30px;
            letter-spacing: 0px;
            color: #515151;
            opacity: 1;
            margin-bottom: 18px;
        }
        a {
            text-align: left;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            line-height: 15px;
            font-weight: 600;
            letter-spacing: 0px;
            color: #0097E0;
            opacity: 1;
            display: block;
            text-decoration: none;
            cursor: pointer;
        }
        div {
            text-align: left;
            font-family: 'Open Sans', sans-serif;
            font-size: 12px;
            line-height: 30px;
            letter-spacing: 0;
            color: #515151;
            opacity: 1;
            padding-bottom: 15px;
            border-bottom: 1px solid #E6E6E6;
            margin-right: 30px;
            margin-bottom: 15px;
            &:last-child {
                border: none;
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }
        .see-all {
            text-align: left;
            font-family: 'Open Sans', sans-serif;
            font-weight: 700;
            font-size: 14px;
            line-height: 30px;
            letter-spacing: 0px;
            color: #0097E0;
            opacity: 1;
        }
    }
    .support {
        h3 {
            text-align: left;
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            font-size: 19px;
            line-height: 30px;
            letter-spacing: 0px;
            color: #515151;
            opacity: 1;
            margin-bottom: 18px;
        }
        div {
            text-align: left;
            font-size: 12px;
            line-height: 20px;
            font-weight: 400;
            font-family: 'Open Sans', sans-serif;
            margin-bottom: 18px;
            letter-spacing: 0px;
            color: #515151;
            opacity: 1;
        }
        a {
            text-align: left;
            font-weight: 700;
            font-size: 14px;
            font-family: 'Open Sans', sans-serif;
            letter-spacing: 0px;
            color: #0097E0;
            text-transform: uppercase;
            opacity: 1;
            margin-top: 9px;
            margin-bottom: 46px;
            display: block;
            text-decoration: none;
            cursor: pointer;
        }
        a:not(:last-child){
            margin-bottom: 0;
        }
    }
}
.mega-resources {
    width: 735px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 40px #0000001A;
    opacity: 1;
    top: -53px!important;
    left: -27px!important;
    padding: 77px 22px 63px 12px;
    .dropdown-toggle {
        position: absolute;
        top: 12px;
        left: 26px;
        padding: 0;
    }

}
.mega-resources.dropdown-menu.show {
    display: flex!important;
    border: none;
    border-radius: 0;
    z-index: 9999999;
}



.header__inputSection {
    display: flex;
    justify-content: center;
    .holder-input {
        max-width: 380px;
        width: 100%;
        position: relative;
        margin-left: 90px;
        
        .tt-menu {
            width: 100%;
            padding: 0px 20px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 40px #0000001A;
            border: 1px solid #E6E6E6;
            opacity: 1;
            border-bottom: none;
        }
        
    }
   
    .item {
        text-align: left;
        font-size: 12px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        letter-spacing: 0px;
        color: #515151;
        opacity: 1;
        border-bottom: 1px solid #E6E6E6;
        padding-top: 10px;
        padding-bottom: 10px;
        &:last-child {
            border-bottom: none;
        }
       
    }
    .item-title {
        font-weight: 600;
        margin-bottom: 4px;
    }
    .item-description {
        font-weight: 400;
    }
    .twitter-typeahead {
        width: 100%;
    }
}
.header__inputSection-input {
    height: 57px;
    background: #F7FCFD 0% 0% no-repeat padding-box!important;
    border: 1px solid #E6E6E6;
    opacity: 1;
    width: 100%;
    padding-left: 20px;
    text-align: left;
    letter-spacing: 0px;
    outline: none;
    cursor: pointer;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    line-height: 28px;
    font-weight: 600;
    color: #424242;
    
    &:focus {
        background: #ffffff 0% 0% no-repeat padding-box;
    }
    &::placeholder {
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        font-size: 14px;
        line-height: 28px;
        letter-spacing: 0px;
        color: #8E8E8E;
        opacity: 1;
    }
}
.header__inputSection-icon {
    position: absolute;
    background: url('../assets/img/search.svg') no-repeat padding-box;
    content: 'pp';
    right: 21px;
    top: 19px;
    width: 24px;
    height: 21px;
    opacity: 1;
    cursor: pointer;
}

.header__nameSection {
    display: flex;
    //display: none;
    justify-content: flex-end;
    align-items: center;
}
.header__nameSection-img {
    margin-right: 32px;
    align-self: center;
    position: relative;
    z-index: 999999;
    span {
        width: 13px;
        height: 13px;
        background: #ff81bb;
        border-radius: 50%;
        position: absolute;
        top: -3px;
        right: -7px;
        opacity: 1;
    }
}
.header__nameSection-initial {
    margin-right: 8px;
    background-color: #0097E0;
    border-radius: 50%;
    color: white;
    font-family: 'Open Sans', sans-serif;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 36px;
    height: 38px;
    font-size: 19px;
    z-index: 999999;
}


.holder__mega-nameSection {
    .dropdown-toggle {
        z-index: 999999;
        position: relative;
        text-align: left;
        font-size: 14px;
        line-height: 28px;
        font-family: "Open Sans", sans-serif;
        letter-spacing: 0px;
        opacity: 1;
        cursor: pointer;
    }
}
.mega-nameSection {
    text-align: left;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    line-height: 28px;
    font-weight: bold;
    letter-spacing: 0px;
    color: rgb(0, 151, 224);
    opacity: 1;
    min-width: 222px;
    padding-top: 65px;
    border: none;
    background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255);
    box-shadow: rgba(7, 37, 54, 0.1) 0px 3px 36px;
    h4 {
        text-align: left;
        font-weight: 600;
        font-size: 14px;
        line-height: 30px;
        font-family: 'Open Sans', sans-serif;
        letter-spacing: 0px;
        color: #0097E0;
        text-transform: uppercase;
        opacity: 1;
        margin-top: 13px;
        padding-bottom: 9px;
        border-bottom: 1px solid #E6E6E6;
    }
    .exit {
        text-align: left;
        font-weight: 600;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        line-height: 30px;
        letter-spacing: 0px;
        color: #0097E0;
        opacity: 1;
        margin-top: 17px;
        a {
            margin-right: 10px;
        }
    }
}
.mega-nameSection__item {
    a {
        text-align: left;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0px;
        color: #0097E0;
        opacity: 1;
        display: block;
        margin-top: 10px;
        line-height: 12px;
        text-decoration: none;
        padding-top: 4px;
        padding-bottom: 4px;
    }
    div {
        text-align: left;
        font-family: 'Open Sans', sans-serif;
        font-size: 12px;
        color: #515151;
        opacity: 1;
        padding-bottom: 7px;
        border-bottom: 1px solid #E6E6E6;
        font-weight: 400;
    }
}
.mega-nameSection.dropdown-menu.show {
    min-width: 429px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 40px #0000001A;
    border-radius: 0px 0px 4px 4px;
    opacity: 1;
    top: -51px!important;
    left: 30px!important;
    z-index: 99999;
    padding-top: 70px;
    padding-left: 37px;
    padding-bottom: 27px;
    padding-right: 32px;
}


.header__btnSection {
    //display: flex;
    justify-content: flex-end;
    display: none;
}
.header__btnSection-img {
    margin-right: 32px;
    align-self: center;
    position: relative;
    z-index: 999999;
}

.header__btnSection__buttons {
    display: flex;
    justify-content: flex-end;

    .accendi,
    .regjistrati {
        font-size: 14px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        width: 120px;
        color: #fff;
        background: #0097E0 0% 0% no-repeat padding-box;
        border: 2px solid #0097E0;
        border-radius: 1px;
        text-decoration: none;
    }

    .regjistrati {
        color: #0097E0;
        background: #ffffff 0% 0% no-repeat padding-box;
        margin-left: 12px;
    }
}

.holder__input__header__mobile {
    position: relative;
    max-width: 340px;
    width: 100%;
    .header__inputSection-input {
        width: 100%;
    }
}


@media (max-width: 1600px) {
div#nonloop {
    display: none !important;
}
}
@media (max-width: 1600px) {
    .header__logoSection {
        padding-right: 0;
    }
    .header__logoSection-corsi {
        padding-left: 0;
    }
    .header__logoSection-risorse {
        padding-right: 0;
    }
    
    .header__inputSection div {
        margin-left: 0;
    }
    .header__inputSection {
        .holder-input {
            margin-right: 30px;
            margin-left: 30px;
        }
    }
    .header__nameSection-img {
        margin-right: 12px;
    }

    .header__btnSection-img {
        margin-right: 8px;
    }
    .header__btnSection__buttons {
        .accendi, 
        .regjistrati {
            width: auto;
        }
    }
    
    .mega-course {
        top: -6px !important;
        width: 700px;
    }
    .mega-resources {
        width: 600px;
    }    
}

@media (max-width: 992px) {
    .header {
        background: #f8fafc!important;
        height: auto;
    }

    .header__inputSection-input{
        margin: 10px 10px;
    }

    .header__inputSection-icon {
        right: 12px;
        top: 31px;
    }
    
}


@media (min-width: 1600px) {
    .user-not-logged-in-menu .col-xl-4.header__btnSection {
        flex: 0 0 19%;
        max-width: 19%;
    }

    .user-logged-in-menu .col-xl-4.header__nameSection, .diff-user-logged-in-menu .col-xl-4.header__nameSection {
        flex: 0 0 12%;
        max-width: 12%;
    }

    .user-logged-in-menu .col-xl-4.header__logoSection {
        flex: 0 0 46.6666666%;
        max-width: 46.6666666%;
    }

    .user-not-logged-in-menu .col-xl-4.header__inputSection {
        flex: 0 0 47.666666666%;
        max-width: 47.666666666%;
    }

    .diff-user-logged-in-menu .col-xl-4.header__inputSection {
        flex: 0 0 54.666666666%;
        max-width: 54.666666666%;
    }

    .user-logged-in-menu .col-xl-4.header__inputSection {
        flex: 0 0 41.3333333333%;
        max-width: 41.3333333333%;
    }

    .user-logged-in-menu .header__inputSection .holder-input, .user-not-logged-in-menu .header__inputSection .holder-input, .diff-user-logged-in-menu .header__inputSection .holder-input {
        margin-right: 0;
        margin-left: auto;
    }
}

@media (min-width: 992px) and (max-width: 1599px) {
 
    .user-logged-in-menu .col-xl-4.header__nameSection, .diff-user-logged-in-menu .col-xl-4.header__nameSection {
        flex: 0 0 20%;
        max-width: 20%;
    }

    .header__inputSection .holder-input {
        margin-left: 0;
        margin-right: 0;
    }

    .diff-user-logged-in-menu .col-lg-4.header__inputSection {
        flex: 0 0 38.3333333333%;
        max-width: 38.3333333333%;
    }

    .user-logged-in-menu .col-lg-4.header__inputSection {
        flex: 0 0 27%;
        max-width: 27%;
    }

    .user-logged-in-menu .col-lg-5.header__logoSection {
        flex: 0 0 53%;
        max-width: 53%;
    }
}

@media (min-width: 992px) {
    .header-mobile {
        display: none;
    }
}


.header__nameSection-btn .dropdown-toggle:after,
.header__logoSection-corsi:after, 
.header__logoSection-risorse:after {
    display: none;
    color: #8E8E8E;
    opacity: 0.9;
}

.holder__mega-course.show .fa:before, .holder__mega-resources.show .fa:before, .holder__mega-nameSection.show .fa:before {
        content: "\f106";
        color: #8E8E8E;
        opacity: 0.9;
}

.holder__mega-course .fa:before, .holder__mega-resources .fa:before, .holder__mega-nameSection .fa:before {
    content: "\f107";
    color: #8E8E8E;
    opacity: 0.9;
}

@media (max-width: 991px) and (min-width: 577px) {
    .col-xl-4.header__inputSection {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .header__inputSection .holder-input {
        margin-left: 0;
        margin-right: 0;
    }

    .col-xl-4.header__btnSection, .col-xl-4.header__nameSection {
        flex: 0 0 50%;
        max-width: 50%;
        padding-right: 0;
    }

    .header__inputSection-input {
        margin-left: 0;
    }
}


@media (max-width: 576px) {
    header.header.d-flex.flex-column.flex-md-row.align-items-center.bg-white.border-bottom.shadow-sm {
        display: none !important;
    }
    
}

@media (min-width: 1280px){
    .header-large-screen{
        max-width: 1500px;
        margin: auto;
    }
}