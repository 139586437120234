.training-course {
    .navbar {
        min-height: 46px;
        opacity: 1;

        .nav-item {
            color: $blueDefault;
            font-size: 14px;
            line-height: 22px;
            font-family: 'Open Sans', sans-serif;
            font-weight: 700;
            letter-spacing: 0px;
            cursor: pointer;
            padding: 0;

            &:after {
                content: ' ';
                color: #424242;
                margin-left: 8px;
                margin-right: 8px;
                border-right: 1px solid #666666;
            }

            &:last-child {
                text-align: left;
                color: #666666;
                font-weight: 400;

                &:after {
                    content: none;
                }
            }
        }
    }

    .result {
        margin-top: 51px;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 15px;
        span {
            text-align: left;
            font-size: 14px;
            line-height: 28px;
            font-family: 'Open Sans', sans-serif;
            letter-spacing: 0px;
            color: #424242;
            margin-right: 21px;
            padding-top: 5px;

            b {
                padding-right: 4px;
            }
        }

        .dropdown {
            padding-top: 4px;
            padding-right: 15px;
        }
    }

    .dropdown .dropdown-toggle {
        z-index: 9999;
        position: relative;
        text-align: left;
        font-weight: 700;
        font-size: 14px;
        line-height: 28px;
        font-family: 'Open Sans', sans-serif;
        letter-spacing: 0px;
        color: #0097E0;
        opacity: 1;
        cursor: pointer;
    }


    .dropdown-menu {
        top: -40px!important;
        text-align: left;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        line-height: 28px;
        font-weight: bold;
        letter-spacing: 0px;
        color: #0097E0;
        opacity: 1;
        min-width: 222px;
        padding-top: 65px;
        left: 20px !important;
        border: none;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 36px #0725361A;
        a {
            text-align: left;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            font-weight: 600;
            line-height: 28px;
            letter-spacing: 0;
            opacity: 1;
            color: #424242;
            height: 53px;
            &:hover {
                background: #F7FCFD 0% 0% no-repeat padding-box;
                opacity: 1;
                color: #0097E0;;
            }
        }
    }

    .dropdown-toggle[aria-expanded=true]:after {
        top: -2px;
        transform: rotate(180deg);
    }
}

.page-template-single-percorso-template {
    .training__course-multi {
        position: relative;
    }
    .training__course-multi:before {
        content: '';
        width: 100%;
        height: 100%;
        background: transparent url(../images/split-background.svg) 0 0 no-repeat padding-box;
        background-repeat: repeat;
        position: absolute;
    }
}


.training__course-multi {
    // height: 449px;
    background: url('../images/photo-split.png') no-repeat padding-box;
    background-color: #5b9ab9;
    background-size: cover;
    background-position: center center;

    .corsi__header-background {
        height: 100%;
        background: transparent url('../images/split-background.svg') 0% 0% no-repeat padding-box;
        background-repeat: repeat;
    }

    .corsi__header__content {
        display: flex;
        align-items: center;
        .text {
            margin-top: 80px;
            margin-bottom: 80px;
            width: 100%;
            max-width: 780px;
            z-index: 2;
            h1 {
                font-family: 'Open Sans', sans-serif;
                font-weight: 700;
                line-height: 51px;
                font-size: 42px;
                text-align: left;
                letter-spacing: 0;
                color: #FFFFFF;
                opacity: 1;
            }
            h5 {
                font-family: 'Open Sans', sans-serif;
                font-size: 25px;
                margin-bottom: 20px;
                margin-top: 20px;
                line-height: 35px;
                letter-spacing: 0;
                color: #FFFFFF;
            }
            span, 
            p {
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 20px;
                display: block;
                color: #FFFFFF;
                max-width: 758px;
            }
            .btn-outline-secondary {
                width: 209px;
                height: 55px;
                border: 2px solid #FFFFFF;
                text-align: center;
                color: #FFFFFF;
                font-size: 14px;
                line-height: 24px;
                font-family: 'Open Sans', sans-serif;
                font-weight: 700;
                margin-left: 15px;
                background: transparent;
                outline: darkgrey;
                img {
                    padding-left: 15px;
                }
            }
        }
    }
}

.training__course__multi__content__title {
    background: #F7F8F9 0% 0% no-repeat padding-box;
    border: 1px solid #F1F1F1;

    .holder-content {

        margin-top: 95px;
        margin-bottom: 95px;

        .holder-description {
            .title {
                font-size: 32px;
                line-height: 35px;
                font-family: 'Open Sans', sans-serif;
                color: #515151;
                display: block;
                margin-bottom: 20px;
            }
            div, 
            p  {
                font-size: 20px;
                line-height: 33px;
                font-family: 'Open Sans', sans-serif;
                margin-top: 0;
                font-weight: 400;
                color: #666666;
            }
        }
      
    }
}
.panel__content {
    position: relative;
    margin-top: 60px;
    height: auto;
    &:before {
        content: " ";
        width: 6px;
        top: 0;
        bottom: 0;
        left: 28px;
        background-color: $blueDefault;
        display: block;
        position: absolute;
    }
    .panel__content__section {
        position: relative;
        margin-left: 105px;
        margin-bottom: 45px;
        margin-top: 20px;
    }
    
    .panel__content__section.section-1 {
        .section-title {
            &:before {
                content: " 1 ";
                width: 60px;
                height: 60px;
                border-radius: 50%;
                position: absolute;
                left: -105px;
                top: -15px;
                color: white;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $blueDefault;
                font-size: 26px;
            }
            color: #0097E0;
            font-family: 'Open Sans', sans-serif;
            font-size: 25px;
            line-height: 30px;
            letter-spacing: 0;
            opacity: 1;
            margin-bottom: 18px;
            text-transform: uppercase;
        }
        .section-subTitle {
            text-align: left;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            line-height: 22px;
            font-weight: 400;
            margin-top: 0;
            letter-spacing: 0px;
            color: #515151;
            opacity: 1;
        }
        .panel__content__section-box {
            .corsi__item {
                height: auto;
            }
            .corsi__item-gradient button {
                width: 113px;
                height: 30px;
                background: #79BC74 0% 0% no-repeat padding-box;
                border-radius: 60px;
                font-size: 9px;
                font-family: 'Open Sans', sans-serif;
                font-weight: 700;
                letter-spacing: 0px;
                color: #FFFFFF;
                text-transform: uppercase;
                right: 19px;
                top: 16px;
                position: absolute;
            }
            .title {
                color: #8e8e8e;
                text-align: left;
                font-size: 14px;
                margin-top: 15px;
                margin-bottom: 15px;
                font-family: 'Open Sans', sans-serif;
                font-weight: 700;
                letter-spacing: 0;
                text-transform: uppercase;
                opacity: 1;
                display: block;
            }
        }
    }

    .panel__content__section.section-2 {
        margin-top: 80px;
        .section-title-2 {
            &:before {
                content: " 2 ";
                width: 60px;
                height: 60px;
                border-radius: 50%;
                position: absolute;
                left: -105px;
                top: -15px;
                color: white;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $blueDefault;
                font-size: 26px;
            }
            text-transform: uppercase;
            color: #0097E0;
            font-family: 'Open Sans', sans-serif;
            font-size: 25px;
            line-height: 30px;
            letter-spacing: 0;
            opacity: 1;
            margin-bottom: 18px;
        }
        .section-subTitle-2 {
            text-align: left;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0px;
            color: #515151;
            opacity: 1;
        }
        .panel__content__section-box {
            align-items: flex-end;
            .corsi__item {
                height: auto;
            }
            .first-item {
                .corsi__item-gradient, .corsi__item-grey {
                    opacity: 0.5;
                }   
            }
            .corsi__item-gradient button {
                width: 113px;
                height: 30px;
                background: #79BC74 0% 0% no-repeat padding-box;
                border-radius: 60px;
                font-size: 9px;
                font-family: 'Open Sans', sans-serif;
                font-weight: 700;
                letter-spacing: 0px;
                color: #FFFFFF;
                text-transform: uppercase;
                right: 19px;
                top: 16px;
                position: absolute;
            }
            .title {
                color: #8e8e8e;
                text-align: left;
                font-size: 14px;
                margin-top: 15px;
                margin-bottom: 15px;
                font-family: 'Open Sans', sans-serif;
                font-weight: 700;
                letter-spacing: 0;
                text-transform: uppercase;
                opacity: 1;
                display: block;
            }
            .icon {
                width: 60px;
                height: 60px;
                text-align: left;
                font-size: 12px;
                font-weight: 700;
                font-family: 'Open Sans', sans-serif;
                letter-spacing: 0px;
                color: #8E8E8E;
                text-transform: uppercase;
                opacity: 1;
                border-radius: 50%;
                border: 1px solid darkgrey;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 35px;
                margin-right: 35px;
                margin-top: auto;
                margin-bottom: auto;
            }
        }
    }

    .panel__content__section.section-3 {
        margin-top: 80px;
        .section-title-3 {
            &:before {
                content: " 3 ";
                width: 60px;
                height: 60px;
                border-radius: 50%;
                position: absolute;
                left: -105px;
                top: -15px;
                color: white;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $blueDefault;
                font-size: 26px;
            }
            color: #0097E0;
            font-family: 'Open Sans', sans-serif;
            font-size: 25px;
            line-height: 30px;
            letter-spacing: 0;
            opacity: 1;
            text-transform: uppercase;
        }
        .panel__content__section-box {
            .corsi__item {
                height: auto;
            }
            .corsi__item-gradient button {
                width: 113px;
                height: 30px;
                background: #79BC74 0% 0% no-repeat padding-box;
                border-radius: 60px;
                font-size: 9px;
                font-family: 'Open Sans', sans-serif;
                font-weight: 700;
                letter-spacing: 0px;
                color: #FFFFFF;
                text-transform: uppercase;
                right: 19px;
                top: 16px;
                position: absolute;
            }
            .description {
                text-align: left;
                letter-spacing: 0px;
                color: #515151;
                opacity: 1;
                font-family: 'Open Sans', sans-serif;
                font-size: 14px;
                line-height: 26px;
                height: auto;
            }
            .title {
                color: #8e8e8e;
                text-align: left;
                font-size: 14px;
                margin-top: 15px;
                margin-bottom: 15px;
                font-family: 'Open Sans', sans-serif;
                font-weight: 700;
                letter-spacing: 0;
                text-transform: uppercase;
                opacity: 1;
                display: block;
            }
            .icon-plus {
                width: 60px;
                height: 60px;
                font-weight: 700;
                font-family: 'Open Sans', sans-serif;
                color: #8E8E8E;
                text-transform: uppercase;
                border-radius: 50%;
                border: 1px solid darkgrey;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 35px;
                margin-right: 35px;
                margin-top: 275px;
                font-size: 35px;
            }
        }
    }
    
    .panel__content__section.section-4 {
        margin-top: 80px;
        .section-title-4 {
            &:before {
                content: " ";
                background: url("../images/flag-white.svg");
                width: 60px;
                height: 60px;
                border-radius: 50%;
                position: absolute;
                left: -105px;
                top: -15px;
                color: white;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $blueDefault;
                font-size: 26px;
                background-repeat: no-repeat;
                background-position: center;
            }
            font-family: 'Open Sans', sans-serif;
            font-size: 32px;
            line-height: 32px;
            letter-spacing: 0;
            opacity: 1;
            text-transform: uppercase;
            text-align: left;
            color: #424242;
        }
        .section-subTitle-4 {
            position: absolute;
            text-align: left;
            letter-spacing: 0px;
            color: #0097E0;
            opacity: 1;
            font-size: 14px;
            line-height: 30px;
            font-weight: 700;
            margin-top: 0;
            font-family: 'Open Sans', sans-serif;
        }
    }
    
    .complete {
        .panel__content__section-title-4 {
            margin-left: 255px;
        }
        .panel__content__section-subTitle {
            position: absolute;
            left: 255px;
        }
        figure {
            position: absolute;
            top: 0;
            z-index: 9999999;
            left: -20px;
        }
        figcaption {
            position: absolute;
            top: 0;
            bottom: -50px;
            left: 0;
            right: 0;
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            color: #bdbdbd;
        }
    }
}
.training__course__multi-description-footer {
    margin-left: 108px;
    margin-bottom: 95px;
    margin-top: 100px;
    .title {
        font-size: 32px;
        line-height: 35px;
        font-family: 'Open Sans', sans-serif;
        letter-spacing: 0;
        color: #515151;
        display: block;
        margin-bottom: 20px;
    }
    .description {
        font-size: 20px;
        line-height: 33px;
        font-family: 'Open Sans', sans-serif;
        letter-spacing: 0;
        color: #666666;
        opacity: 1;
    }
}

.hide-this-title{
    display: none;
}

.training-course-mobile{
    display: none;
}

@media (max-width: 1600px) {
    .panel__content__section.section-2 {
        .panel__content__section-box {
            .title {
                min-height: 40px;
            }
        }
    }
}

@media (max-width: 992px) {
    .training__course-multi {
        .corsi__header__content {
            flex-flow: column;
            .text {
                margin-top: 25px;
                margin-bottom: 40px;
                h1 {
                    font-size: 29px;
                    line-height: 34px;
                    text-align: center;
                }
                h5 {
                    font-size: 19px;
                    line-height: 27px;
                    margin-top: 14px;
                    text-align: center;
                }
                p {
                    text-align: center;
                }

                button {
                    margin: auto!important;
                    display: block;
                }
            }
        }
    }
    .training__course__multi__content__title {
        min-height: auto;
        .holder-content {
            margin-top: 25px;
            margin-bottom: 45px;
            img {
                display: none;
            }
        }
    }
    .panel__content {
        &:before {
            content: unset;
        }
        .panel__content__section.section-1 {
            margin-top: 80px;
            margin-left: 0;
            .section-title {
                font-size: 19px;
                &:before {
                    width: 40px;
                    height: 40px;
                    left: 0;
                    top: -50px;
                }
            }
        }
        .panel__content__section.section-2 {
            margin-top: 100px;
            margin-left: 0;
            .section-title-2 {
                font-size: 19px;
                &:before {
                    width: 40px;
                    height: 40px;
                    left: 0;
                    top: -50px;
                }
            }
            .panel__content__section-box {
                flex-flow: column;
                flex-direction: column;
                align-items: flex-start;
                .icon {
                    display: block;
                    height: auto;
                    margin-top: 28px;
                    margin-bottom: 0px;
                    border: 0;
                    width: 345px;
                    margin-left: 0;
                    margin-right: 0;
                    text-align: center;
                }
                .title {
                    min-height: unset;
                }
            }
        }
        .panel__content__section.section-3 {
            margin-top: 100px;
            margin-left: 0;
            .section-title-3 {
                font-size: 19px;
                &:before {
                    width: 40px;
                    height: 40px;
                    left: 0;
                    top: -50px;
                }
            }
            .panel__content__section-box {
                flex-flow: column;
                margin: unset;
                .description {
                    height: auto;
                    margin-bottom: 30px;
                }
                .icon-plus {
                    margin-top: 30px;
                    margin-bottom: 12px;
                    border: 0;
                    margin-left: 145px;
                }
            }
        }
        .panel__content__section.section-4 {
            margin-top: 100px;
            margin-left: 0;
            .section-title-4 {
                font-size: 19px;
                &:before {
                    width: 40px;
                    height: 40px;
                    left: 0;
                    top: -50px;
                }
            }
        }
    }
    .training__course__multi-description-footer {
        margin-left: 0;
    }
}

@media (max-width: 767px) {
    .panel__content {
        .corsi__item {
            margin-left: unset;
            margin-right: unset;
        }
        .panel__content__section.section-2 {
            .panel__content__section-box {
                .icon {
                    width: 389px;
                }
            }
        }
        .panel__content__section.section-3 {
            .panel__content__section-box {
                .icon-plus {
                    width: 389px;
                    margin-left: 0;
                    margin-right: 0;
                    text-align: center;
                }
            }
        }
    }

}
@media (max-width: 575px) {
    .training__course-multi {
        .corsi__header__content {
            flex-flow: column;
            .text {
                h1 {
                    font-size: 22px;
                    line-height: 34px;
                    text-align: center;
                }
                p{
                    font-size: 14px;
                    font-weight: normal;
                }
            }
        }
    }
    .panel__content {
        .panel__content__section.section-1 {
            .section-title {
                padding-bottom: 0;
                margin-bottom: 0;
                font-weight: normal;
            }
            .section-title:before {
                font-size: 19px;
            }
            .section-subTitle{
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
    }
    .panel__content .panel__content__section.section-3 .panel__content__section-box .title {
       margin-top: 5px;
    }

    .training__course__multi-description-footer {
        display: none;
    }

    .training__course__multi__content__title .holder-content .holder-description div,
    .training__course__multi__content__title .holder-content .holder-description p {
        font-size: 14px;
    }

    .training__course__multi__content__title .holder-content .holder-description .title {
        font-size: 20px;
        margin-bottom: 5px;
    }

    // .panel__content .panel__content__section.section-1 .section-subTitle {
    //     margin-bottom: 50px;
    // }
}
