.home {
    width: 100%;
    background: white;
}

.home__baner {
    position: relative;
    height: 595px;
    background-image:  url("../assets/img/banner-new-2.png");
    opacity: 1;
    background-size: auto;
    background-position: 100%;
    background-repeat: no-repeat;
    h1 {
        text-align: left;
        font-size: 32px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        line-height: 51px;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        margin-bottom: 26px;
    }
    .program {
        max-width: 600px;
        justify-content: center;
        flex-flow: column;
        display: flex;
        height: 100%;
        ul {
            list-style: none;
            text-decoration: none;
            padding-left: 0;
            margin-bottom: 30px;
            li {
                text-align: left;
                line-height: 51px;
                font-family: 'Open Sans', sans-serif;
                font-weight: 600;
                font-size: 32px;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
                margin-bottom: 10px;
                &:before {
                    content: url("../assets/img/done-2.svg");
                    margin-right: 26px;
                }
            }
        }
        span {
            height: 59px;
            text-align: left;
            font-size: 18px;
            line-height: 35px;
            font-family: 'Open Sans', sans-serif;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
            margin-bottom: 11px;
        }
        .btn-primary {
            width: 282px;
            height: 57px;
            background: #0097E0 0% 0% no-repeat padding-box;
            opacity: 1;
            text-align: center;
            font-family: 'Open Sans', sans-serif;
            font-weight: 700;
            font-size: 14px;
            line-height: 28px;
            letter-spacing: 0px;
            color: #FFFFFF;
            border-radius: 0;
            padding-top: 15px;
            border: none;
            margin-top: 40px;
        }
    }
    .image {
        //background-image: url("../assets/img/home-baner.svg");
        background-image: url(".../public/assets/img/banner-2.png");
        max-width: 58%;
        opacity: 1;
        z-index: 9999999999999;
        float: right;
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-position: 0 33%;
        img {
            width: 100%;
            top: 0;
            height: 65vh;
            object-fit: cover;
            display: none;
        }
    }
}


@media screen and (max-width: 1600px) {
  .home__baner {
      .program {
          max-width: 50%;
      }
  }
}

.home__baner__content {
    width: 100%;
    height: 100%;
}

.home__notification {
    background: #F8F9FA 0% 0% no-repeat padding-box;
    opacity: 1;
    min-height: 257px;
    .container {
        margin: auto;
        padding: 0;
        height: 100%;
    }
}

.home__notification__text {
    button {
        width: 84px;
        height: 34px;
        font-size: 12px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        background: #4CD161 0% 0% no-repeat padding-box;
        border-radius: 19px;
        align-items: center;
        display: flex;
        justify-content: center;
        outline: none;
        border: none;
        margin-top: 25px;
        margin-bottom: 12px;
        text-transform: uppercase;
        cursor: auto !important;
    }
    h2 {
        text-align: left;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0px;
        color: #424242;
        //text-transform: lowercase;
    }
    span {
        text-align: left;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        letter-spacing: 0px;
        color: #666666;
        opacity: 1;
        display: block;
    }
    a {
        text-align: left;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        line-height: 28px;
        font-size: 14px;
        letter-spacing: 0px;
        color: $blueDefault;
        opacity: 1;
        display: block;
        margin-top: 18px;
        text-decoration: none;
        i {
            border: 0.4000000059604645px solid #FFFFFF;
            opacity: 1;
            padding-left: 3px;
        }
    }
}

.home__notification__box {
    align-items: center!important;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    margin-top: 25px;
}

.home__notification__box__item {
    width: 255px;
    height: 178px;
    box-shadow: 0px 0px 20px #00000014;
    border-radius: 8px;
    opacity: 1;
    padding: 20px;
    position: relative;
    margin: 13px;
    background: white;
}

.home__notification__box__item-img {
    width: 31px;
    height: 28px;
    opacity: 1;
    padding-right: 9px;
}

.home__notification__box__item--title {
    text-align: left;
    font-size: 12px;
    line-height: 22px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    letter-spacing: 0px;
    color: #424242;
    opacity: 1;
}

.home__notification__box__item-description {
    text-align: left;
    font-size: 13px;
    line-height: 22px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    letter-spacing: 0px;
    color: $blueDefault;
    opacity: 1;
    margin-top: 13px;
}

.home__notification__box__item-link {
    text-align: left;
    font-size: 12px;
    line-height: 26px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    letter-spacing: 0px;
    color: #666666;
    opacity: 1;
    position: absolute;
    bottom: 17px;
}

.home__quality {
    h1 {
        margin-top: 75px;
        margin-bottom: 65px;
        text-align: center;
        font-size: 32px;
        line-height: 45px;
        font-family: 'Open Sans', sans-serif;
        letter-spacing: 0px;
        color: #424242;
        opacity: 1;
    }
}

.home__quality__item {
    text-align: center;
    margin-bottom: 25px;
}

.home__quality__item-img {
    height: 210px;
    margin-bottom: 32px;
    position: relative;
    img {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
    .group-img {
        height: 100%;
    }
}

.home__quality__item-title {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0px;
    color: #424242;
    opacity: 1;
    margin-bottom: 13px;
    max-width: 280px;
    margin-left: auto;
    margin-right: auto;
}

.home__quality__item-description {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0px;
    color: #666666;
    max-width: 280px;
    margin-left: auto;
    margin-right: auto;
}

.home__training {
    .link-btn {
        background: $blueDefault 0% 0% no-repeat padding-box;
        color: #FFFFFF;
        text-transform: uppercase;
        margin-top: 60px;
        margin-bottom: 100px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        font-size: 14px;
        line-height: 26px;
        border-radius: 0;
        border: none;
        padding: 15px 30px;
    }
}

.home__training-title {
    margin-top: 70px;
    margin-bottom: 15px;
    text-align: center;
    line-height: 35px;
    font-size: 32px;
    font-family: 'Open Sans', sans-serif;
    color: #424242;
}

.home__training-subtitle {
    text-align: center;
    font-size: 20px;
    line-height: 33px;
    font-family: 'Open Sans', sans-serif;
    color: #666666;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 41px;
}

.home__training__container {
    display: flex;
    justify-content: center;
    margin-bottom: 26px;
}

.home__training__container-item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 20px;
    height: 121px;
    box-shadow: 0px 0px 20px #0F44651F;
    border-radius: 8px;
    justify-content: space-between;
    max-width: 499px;
    margin: 7px;
}

.home__training__item-img {
    position: absolute; 
    top: 50%;
    left: 20px;
    bottom: 20px;
    width: 80px;
    margin-top: -40px;
    border-radius: 100%;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;

    }
}

.home__training__item__text {
    width: 100%;
    margin-left: 95px;
    h2 {
        text-align: left;
        font-size: 24px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        line-height: 30px;
        color: #424242;
        text-transform: capitalize;
        margin-top: 0;
        margin-bottom: 3px;
    }
    p {
        font-family: 'Open Sans', sans-serif;
        text-align: left;
        line-height: 22px;
        font-size: 13px;
        color: #666666;
        display: block;
        margin-top: 0;
        margin-bottom: 6px;
    }
    a {
        text-align: left;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        font-size: 14px;
        color: $blueDefault;
        display: block;
        text-decoration: none;
        i {
            border: 0.4000000059604645px solid #FFFFFF;
            opacity: 1;
            padding-left: 3px;
        }
    }
}

.home__insertImage {
    display: flex;
    justify-content: space-between;
    margin-top: 220px;
}

.bold-number{
    font-size: 14px;
    font-weight: bold;
}

.home__insertImage__text {
    max-width: 470px;
    h1 {
        margin-bottom: 20px;
        text-align: left;
        font-size: 32px;
        line-height: 35px;
        font-family: 'Open Sans', sans-serif;
        letter-spacing: 0px;
        color: #515151;
        opacity: 1;
    }
    div {
        text-align: left;
        font-family: 'Open Sans', sans-serif;
        font-size: 20px;
        line-height: 33px;
        letter-spacing: 0px;
        color: #666666;
        opacity: 1;
    }
    a {
        text-align: left;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        line-height: 58px;
        font-size: 14px;
        letter-spacing: 0px;
        color: $blueDefault;
        opacity: 1;
        display: block;
        margin-top: 18px;
        text-decoration: none;
        i {
            border: 0.4000000059604645px solid #FFFFFF;
            opacity: 1;
            padding-left: 3px;
        }
    }
}

.home__insertImage__img {
    max-width: 900px;
    position: relative;
    padding-right: 68px;
}

.home__insertImage__img-image {
    position: absolute;
    right: 0;
    top: -70px;
    z-index: -99999999999;
    display: none;
}

.home__insertImage__img-shadow {
    width: 716px;
    height: 486px;
    background: #edf1f7 0% 0% no-repeat padding-box;
    mix-blend-mode: multiply;
    padding-left: 16px;
    border-radius: 20px;
}

.home__insertImage__img__content {
    width: 716px;
    height: 481px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    position: absolute;
    top: -12px;
    display: flex;
    align-items: center;
    border-radius: 20px;
    p {
        text-align: center;
        font-size: 34px;
        font-family: 'Montserrat', sans-serif;
        line-height: 40px;
        letter-spacing: 0px;
        color: #A3A3A3;
        opacity: 1;
        width: 100%;
        max-width: 364px;
        margin: auto;
    }
    img {
        position: absolute;
        right: -53px;
        z-index: -999999999;
        top: -70px;
    }
}

.home__insertImage__img__content__header {
    width: 715px;
    height: 35px;
    background: #44C8F5 0% 0% no-repeat padding-box;
    opacity: 1;
    position: absolute;
    top: 0;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    div {
        width: 55px;
        position: absolute;
        left: 22px;
        bottom: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: auto;
        span {
            width: 12px;
            height: 12px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            opacity: 1;
            border-radius: 50%;
        }
    }
}

.home__slider {
    margin-top: 250px;
    margin-bottom: 70px;
    .btn-calendar {
        background: #0097E0 0% 0% no-repeat padding-box;
        border: 2px solid #0097E0;
        text-align: center;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        font-size: 14px;
        line-height: 26px;
        color: #FFFFFF;
        text-transform: uppercase;
        margin-top: 60px;
        margin-bottom: 100px;
        border-radius: 0;
        padding: 15px 30px;
        img {
            padding-left: 19px;
            padding-bottom: 2px;
        }
    }
    .slider__content {
        margin-bottom: 70px;
    }
    .corsi__slider-title {
        display: none;
    }
}

.home__slider-title {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 32px;
    line-height: 35px;
    letter-spacing: 0px;
    color: #424242;
    opacity: 1;
    max-width: 658px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 22px;
}

.home__slider-description {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    line-height: 33px;
    letter-spacing: 0px;
    color: #666666;
    opacity: 1;
    max-width: 626px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 43px;
}

.home__slider-subtitle {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 33px;
    letter-spacing: 0px;
    color: #666666;
    opacity: 1;
    max-width: 518px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}

.home__slider__holderBox {
    max-width: 1198px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
}

.home__slider__holderBox__box {
    max-width: 586px;
    width: 100%;
    height: 180px;
    display: flex;
    opacity: 1;
    padding: 22px 22px;
    box-shadow: 0px 0px 20px #0F44651F;
    border-radius: 8px;
    justify-content: space-between;
    margin-bottom: 25px;
}

.home__slider__holder__box-img {
    width: 52px;
    height: 42px;
    position: relative;

    .full-img {
        width: 100%;
        height: 100%;
    }

    .tools-img {
        position: absolute;
        bottom: 5px;
        right: 0px;
        background: white;
        padding-left: 4px;
        padding-top: 4px;
        width: 24px;
        height: 24px;
    }
}

.home__slider__holder__box__text {
    width: 100%;
    margin-left: 23px;
    h2 {
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        text-align: left;
        letter-spacing: 0px;
        color: #424242;
        opacity: 1;
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 12px;
        margin-top: 5px;
    }
    span {
        display: block;
        text-align: left;
        font-size: 14px;
        font-family: 'Open Sans', sans-serif;
        line-height: 22px;
        letter-spacing: 0px;
        color: #666666;
        opacity: 1;
        min-height: 65px;
    }
    a {
        text-align: left;
        letter-spacing: 0px;
        color: #0097E0;
        text-transform: uppercase;
        opacity: 1;
        font-size: 14px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        display: block;
        text-decoration: none;
        margin-top: 12px;
        i {
            border: 0.4000000059604645px solid #FFFFFF;
            opacity: 1;
            padding-left: 6px;
        }
    }
}

.home__video__container {
    display: flex;
    justify-content: space-between;
}

.home__video__container__text {
    max-width: 340px;
    h1 {
        margin-bottom: 20px;
        text-align: left;
        font-size: 32px;
        line-height: 35px;
        font-family: 'Open Sans', sans-serif;
        letter-spacing: 0px;
        color: #515151;
        opacity: 1;
    }
    div {
        text-align: left;
        font-family: 'Open Sans', sans-serif;
        font-size: 20px;
        line-height: 33px;
        letter-spacing: 0px;
        color: #666666;
        opacity: 1;
    }
    a {
        text-align: left;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        line-height: 58px;
        font-size: 14px;
        letter-spacing: 0px;
        color: $blueDefault;
        opacity: 1;
        display: block;
        margin-top: 18px;
        text-decoration: none;
        text-transform: uppercase;
        i {
            border: 0.4000000059604645px solid #FFFFFF;
            opacity: 1;
            padding-left: 3px;
        }
    }
}

.home__video__container__video {
    position: relative;
    max-width: 1077px;
    max-height: 606px;
    width: 100%;
    height: 100%;
    div {
        width: 100%;
        height: 100%;
        opacity: 1;
        video {
            background: black;
            width: 100%;
            height: 100%;
            outline: 0;
        }
    }
    .daikin-img {
        display: block;
        background: url("../assets/home-page-img/daikin.svg");
        height: 70px;
        margin-left: 200px;
        opacity: 1;
        width: 100%;
        max-width: 575px;
    }
    .grey-background {
        position: absolute;
        width: 675px;
        height: 571px;
        transform: matrix(0.96, 0.29, -0.29, 0.96, 0, 0);
        background: #0E83BC29 0% 0% no-repeat padding-box;
        opacity: 0.51;
        z-index: -999999999999999;
        bottom: 0;
        display: none;
    }
}

.home__opinion {
    margin-top: 179px;
    padding-bottom: 170px;
}

.home__opinion-title {
    margin-bottom: 41px;
    text-align: center;
    font-size: 32px;
    line-height: 35px;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0px;
    color: #424242;
    opacity: 1;
}

.home__opinion__container {
    display: flex;
    justify-content: space-between;
}

.home__opinion__item {
    max-width: 389px;
    margin-bottom: 35px;
}

.home__opinion__item__text {
    text-align: left;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    line-height: 19px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D8DCE1;
    transform: unset!important;
    right: 0;
    margin: auto;
    min-width: 389px;
    padding-right: 60px;
    padding-top: 13px;
    padding-left: 15px;
    padding-bottom: 13px;
    .arrow {
        left: 178px!important;
    }
}

.home__opinion__item__image {
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: 100px;
    p {
        position: absolute;
        top: 40px;
        padding-left: 5px;
        width: 98px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 0.67;
    }
}

.home__opinion__item-name {
    text-align: center;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    line-height: 22px;
    letter-spacing: 0px;
    color: #515151;
    margin-top: 18px;
}

.home__opinion__item-profesion {
    text-align: center;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    color: #0097E0;
}

@media (max-width: 2560px) {
.prove{
display:  none !important;
}

div#nonloop {
    display: none !important;
}

.mobile-header {
    display: none;
}
.home__insertImage__img-mobile {
    display: none !important;
}

div#nonloop1 {
    display: none !important;
}

.home__baner img{
 display: none;
}

div#nonloop {
    display: none !important;
}

.home__insertImage__img-mobile {
    display: none;
}

div#nonloop1 {
    display: none !important;
}

img.mobile-img {
    display: none;
}

.sidenav {
    display: none !important;
}

.mobile-header {
    display: none;
}

a.closebtn-1 {
    display: none;
}

.header__inputSection-input-1 {
    display: none;
}

}


@media (max-width: 1600px) {
    .home__baner {
        .image {
            max-width: 50%;
        }
    }

    .home__insertImage__img__content__header {
        width: 100%;
    }
    .home__slider__holderBox__box {
        max-width: 454px;
        height: 100%;
    }
    .home__video__container__video {
        max-width: 550px;
        .daikin-img {
            max-width: 277px;
            margin-left: 100px;
        }
    }
    //.home__opinion__item {
    //    max-width: 320px;
    //}
}


@media (max-width: 1599px) {
    .home__baner {
        .image {
            max-width: 50%;
        }
    }
    .home__insertImage__text {
        max-width: 360px;
    }
    .home__insertImage__img {
        max-width: 450px;
        margin-right: 40px;
        padding-right: 0;
    }
    .home__insertImage__img-shadow {
        width: 450px;
        height: 380px;
    }
    .home__insertImage__img__content {
        width: 100%;
        height: 380px;
        img {
            height: 470px;
            top: -53px;
            right: -40px;
        }
        p {
            font-size: 30px;
            line-height: 35px;
        }
    }
    .home__insertImage__img__content__header {
        width: 100%;
    }
    .home__slider__holderBox__box {
        max-width: 454px;
        height: 100%;
    }
    .home__video__container__video {
        max-width: 550px;
        .daikin-img {
            max-width: 277px;
            margin-left: 100px;
        }
    }
    //.home__opinion__item {
    //    max-width: 320px;
    //}
}

@media (max-width: 992px) {
    .home__baner {
        h1 {
            width: unset;
            height: unset;
        }
        height: 990px;
        .image {
            max-width: 100%;
            height: 480px;
            background-position: -90px 34%;
        }
        .program {
            max-width: unset;
        }
    }
    .home__baner__content {
        height: unset;
        position: absolute;
        bottom: 45px;
        left: 0;
        right: 0;
    }
    .home__training__container-item {
        height: 120px;
    }
    .home__insertImage {
        flex-flow: column;
        margin-top: 0;
    }
    .home__insertImage__text {
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        margin-bottom: 85px;
    }
    .home__insertImage__img {
        margin: auto;
    }
    .home__slider__holderBox {
        justify-content: center;
    }
    .corsi__slider__content {
        .nextArrow {
            width: 330px;
            height: 67px;
        }
        .prevArrow {
            width: 330px;
            height: 67px;
        }
    }
    .home__video__container {
        flex-flow: column;
    }
    .home__video__container__text {
        margin: auto;
        max-width: 460px;
    }
    .home__video__container__video {
        margin: auto;
        max-width: 570px;
    }
    .home__opinion__item {
        max-width: 390px;
    }
    .home__opinion__container {
        justify-content: center;
    }
}

.datepicker.datepicker-dropdown.dropdown-menu.datepicker-orient-left.datepicker-orient-top {
    padding-right: 19px;
    padding-left: 19px;
    cursor: pointer;
}

@media (max-width: 767px) {
    .home__baner {
        height: 1035px;
    }
    .home__slider {
        .slider__content {
            margin-bottom: 150px;
        }
    }

   
}

@media (max-width: 576px) {

    .home__slider{
        .text-center.calendar-button{
            text-align: left !important;
            margin-left: 14px;
            .btn-calendar{
                margin-top: 43px;
                // margin-bottom:1px ;
            }
        }
    }

    .home__training {
        .link-btn {
            margin-top: 20px;
            margin-bottom: 45px;
        }
    }

    .home__baner {
        height: auto;
        position: relative;

        // height: 669px;
        background-image: none;
        background-color: #E4F2F7 ;
        .program {
            p{
                margin-top: 0;
            }
            h1 {
                font-weight: 600;
                font-size: 23px;
                line-height: 35px;
            }
            ul{
                margin-bottom: 0;
            }
            ul li {
                font-size: 14px;
                line-height: 35px;
                &:before {
                    margin-right: 10px;
                }
            }
            span {
                margin-bottom: 15px;
                font-size: 12px;
            }
            .btn-primary {
                margin: auto;
                // max-width: 302px;
                margin-top: 10px;
                width: 100%;
            }
        }
        .image {
            height: 370px;
        }
    }

    .home__baner img {
        display: block;
        width: 100%;
        height: auto;
    }
    .home__baner__content {
        // bottom: 33px;

        bottom: 0;
        padding: 15px 24px 30px !important;
        position: relative;
    }
    .home__insertImage__img {
        max-width: 220px;
    }
    .home__insertImage__img-shadow {
        width: 220px;
        height: 270px;
    }
    .home__insertImage__img__content {
        height: 270px;
        img {
            height: 336px;
            top: -38px;
            right: -30px;
        }
    }
    //.home__slider {
    //    .slider__content {
    //        margin-bottom: 150px;
    //    }
    //}
    .home__video__container__video .daikin-img {
        max-width: 129px;
        margin-left: 65px;
    }
    .home__opinion__item__text {
        text-align: left;
        font-size: 12px;
        font-family: 'Open Sans', sans-serif;
        line-height: 19px;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #D8DCE1;
        transform: unset!important;
        right: 0;
        margin: auto;
        min-width: 310px;
        padding-right: 15px;
        .arrow {
            left: 141px!important;
        }
    }
    .home__opinion__item__image {
        margin-top: 118px;
    }
    .prove{
        display:  flex !important;
        }
        .hide-this{
            display: none;
        }

        .home__quality.container {
            display: none;
        }
        .home__training-title {
            font-size: 22px !important;
            
        }
        .home__training__item__text span {
            display: none;
        }
        // .home__training__item-img img{
        //     width: 59px !important;
        // }
     
        .home__insertImage__text h1 {
            margin-bottom: 10px;
            font-size: 22px;
        }
        .home__insertImage__text a {
            margin-top: 0;
        }
        .home__insertImage__text div {
            font-size: 12px;
        }
        .home__slider-description { 
            display: none;
        }
        .home__slider__holder__box__text h2 {
            font-size: 18px;
        }
        .home__opinion.container {
            display: none;
        }

        .home__video__container__video .daikin-img {
            display: none;
        }
        .home__slider-subtitle {
            font-size: 10px !important;
        }
        .home__slider .slider__content {
            display: none !important;
        }
        .home__insertImage__img {
            display: none;
        }
        .home__slider__holder__box-img img{
            width: 20px !important;
            height: 27px !important;
        }
        .home__slider__holder__box-img .tools-img {
            bottom: 6px;
            right: -10px;
            width: 13px !important;
}
.home__slider__holder__box-img {
    width: 16px;
    height: 42px;
    
}
.home__slider__holderBox.row{
    margin-left: 14px;
    margin-right: 14px;
   
    .footer-line {
        width: 100% !important;
        margin-top: -14px;
    }

    .home__insertImage__img-mobile {
        display: block;
    }
}

.footer-line{
    max-width: 513px !important;
    width: 100%;
    margin-top: -3px !important;

}

div#nonloop {
    display: block !important;
}

.home__insertImage__img-mobile {
    display: block !important;
}

div#nonloop1 {
    display: block !important;
}

.owl-pagination {
    margin-bottom: 10px;
    text-align: center;

}

.log-out-menu {
display: flex;
}

.log-out-menu a {
margin-right: 191px;
}

.log-out-menu img {
width: 16px;
height: 16px;
margin-top: 14px;
}

.risorse-menu-item {
display: flex;
justify-content: space-between;
}

.risorse-menu-item img {
padding: 20px 43px 8px 32px;
}

.menu-collapse-small {
font-size: 14px !important;
font-family: "Open Sans", sans-serif;
color: #515151;
}


.sidenav {
    display: block !important;
    z-index: 999999;
}

.mobile-header {
    display: block;
}

.sidenav a:hover {
    color: #1897E0;
}

.search {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #ffffff;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
    box-shadow: -3px 0 5px 0 #555;
}

.header__inputSection-input-1 {
    margin-top: -50px;
}

a.closebtn-1 {
    position: absolute;
    left: 299px;
    color: black;
    font-size: 25px;
    bottom: 756px;
    z-index: 999999;
}

.search-form i {
    font-size: 20px;
}

a.closebtn-1 {
    display: none;
}

.header__inputSection-input-1 {
    display: none;
}

a.closebtn-1 {
    display: block;
}

.header__inputSection-input-1 {
    display: block;
}

.mobile-section {
    margin-left: 20px;
}

}


@media (min-width: 992px) {
    .home__notification__box {
        justify-content: flex-end;
    }
}


@media (min-width: 576px) {
    .owl-theme .owl-controls .owl-page span {
        background: #6FCDE4 !important;
    }

    .corsi__item {
        width: 340px;
        height: 352px;
    }

    .owl-theme .owl-controls .owl-page span {
        width: 9px !important;
        height: 9px !important;
        background-color: #6FCDE4 !important;
    }
}

@media (max-width: 411px) {
    .home__baner {
        background-image: none;
        background-color: #E4F2F7 ;
    }

    .home__notification__box__item {
        width: 338px !important;
        height: 236px !important;
    }

    .home__training__container-item {
        height: 90px;
        width: 302px !important;
    }
}


@media (max-width: 375px) {
    .footer-line {
        width: 100%;
        margin-top: -10px !important;
        max-width: 302px !important;
    }

    .footer .footer__logo {
        margin-left: 18px;
    }

    .copyright-content {
        padding-top: 12px;
        padding-left: 26px;
    }

    .copyright-background {
        height: 88px !important;
    }

    .home__baner {
        // height: 547px !important;
        background-image: none;
        background-color: #E4F2F7;
    }
    .home__baner .program ul li {
        font-size: 14px;
        line-height: 22px !important;
    }
    .home__baner .program .btn-primary {
        margin-top: 14px;
    }
    .home__notification__text button {
        width: 65px;
        height: 30px;
        font-size: 10px;
        margin-top: 3px !important;
    }

    .home__notification__text span {
        font-size: 12px;
    }

    .home__notification__box__item {
        width: 255px !important;
        height: 178px !important;
    }

    .home__notification__box {
    margin-top: 0px;
    }

    .home__training-title {
        margin-top: 21px;
    }
    .home__training-subtitle {
        font-size: 12px !important ;
    }
    .home__slider__holder__box-img img {
        width: 32px !important;
        height: 25px !important;
    }
    .home__slider__holder__box__text span {
        margin-left: -36px;
    }
    .home__slider__holder__box__text a {
        margin-left: -36px;
    }
    .home__training button {
        margin-top: 28px;
        margin-bottom: 28px;
    }
    .home__insertImage__text div {

        line-height: 22px;
    }
    img.home__insertImage__img-image-mobile {
        max-width: 375px;
        height: 266px;
        width: 100% !important ;
        margin-top: -60px;
    }
    .home__training button {
        max-width: 302px;
    }
}

@media (min-width: 1280px) {
    .program{
        max-width: 1500px !important;
        margin: auto;
    }
}
/*Home mobile */
