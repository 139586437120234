.catalogo-linea-section {
    margin-top: 40px;
    margin-bottom: 90px;
}


.catalogo-linea-img img{
    width: 247px;
    height: 247px;
    z-index: 99999;
    position: absolute;
    left: 34px;
    object-fit: cover;
    border-radius: 100%;
    top: 50%;
    transform: translate(0, -50%);
}


.catalogo-linea-dsc {
    background-color: #F8F9FA;
    margin-bottom: 10px;
    margin-top: 10px;
    padding-top: 70px;
    padding-bottom: 70px;
    padding-right: 107px;
    padding-left: 237px;
}

.catalogo-linea-title{
    font-size: 22px;
    color: #424242;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    
}

.catalogo-linea-para{
    color: #666666;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;

}

.catalogo-linea-link{
    font-size: 14px;
    text-decoration: none;
    color: #0097E0 ;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;
}

.corsi-sedi {
    display: flex;
    justify-content: space-between;
}

.corsi-sedi p{
    color: #8E8E8E;
    font-size: 14px;
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;
}

p.corsi-sedi-p {
    margin-right: 62px;
}
@media (max-width: 1599px) {
    .catalogo-linea-img img {
        width: 150px;
        height: 150px;
        z-index: 99999;
        position: absolute;
        // top: 91px;
        left: 34px;
    }
    .catalogo-linea-dsc {
        background-color: #F8F9FA;
        margin-bottom: 20px;
        padding-top: 70px;
        padding-bottom: 70px;
        padding-right: 9px;
        padding-left: 126px;
    }

}

@media (max-width: 991px) {

    .catalogo-linea-img img {
        position: relative !important;
        top: 52px;
        left: 0px;
        transform: translate(0, 0);
    }
    .catalogo-linea-img  {
    text-align: center;
    
    }

    .catalogo-linea-dsc {
        padding-top: 70px;
        padding-bottom: 70px;
        padding-right: 70px;
        padding-left: 70px;
    }
    .catalogo-linea-title {
        font-size: 22px;
        color: #424242;
        font-weight: 600;
        font-family: "Open Sans", sans-serif;
        text-align: center;
    }
    .catalogo-linea-title {
        text-align: center;
    }
    .catalogo-linea-para {
        text-align: center;
    }
    p.corsi-sedi-p {
     margin-right: 0px;
    }

    .scopri {
        text-align: center;
    }
}

@media (max-width:576px){
    .sidenav {
        display: block !important;
        z-index: 99999999;
    }
    .catalogo-linea-title {
        margin-top: 22px;
    }

    .course-details-title{
        line-height: 27px !important;
    }

    .catalogo-linea-para {
        line-height: 22px;
        color: #666666;
        font-size: 14px;
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
    }
}

@media (max-width:575px){
    .col-lg-1{
        margin-bottom: -50px;
        img{
            top: 0;
        }
    }

    .catalogo-linea-dsc {
        padding: 50px;
        margin-left: 40px;
        margin-right: 40px;
        margin-bottom: 25px;
    }

    .corsi__nav .corsi__nav-corsiSingoli {
        font-size: 12px;
    }

    .corsi__nav .corsi__nav-home {
        font-size: 12px;
    }
}




