.course-details {
    .navbar {
        min-height: 46px;
        opacity: 1;
        .nav-item {
            color: $blueDefault;
            font-size: 14px;
            line-height: 22px;
            font-family: 'Open Sans', sans-serif;
            font-weight: 700;
            letter-spacing: 0px;
            cursor: pointer;
            padding: 0;
            &:after {
                content: ' ';
                color: #424242;
                margin-left: 8px;
                margin-right: 8px;
                border-right: 1px solid #666666;
            }
            &:last-child {
                text-align: left;
                color: #666666;     
                font-weight: 400;
                &:after {
                    content: none;
                }
            }
        }
    }
}

.section-title {
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    line-height: 30px;
    color: #006393;
    text-transform: uppercase;
    padding-bottom: 10px;
}

.column {
    position: absolute;
    height: 100%;
    top: 0;

    &.left-side {
        left: 0;
        background-color: #fff;
    }

    &.right-side {
        right: 0;
        background-color: #F7F8F9;
    }
}

.course-details__header {
    height: 298px;
    // background: url('../assets/details-course-img/MCE-Catalogo-Corsi-image.png') no-repeat padding-box;
    background-position: top center;
    background-repeat: no-repeat;
}

.course-details__header__content {
    height: 100%;
    background: transparent url('../assets/details-course-img/MCE-Catalogo-Corsi-background.svg') 0% 0% no-repeat padding-box;
    background-repeat: repeat;
    .container {
        display: flex;
        justify-content: space-between;
        height: 100%;
        align-items: center;
    }
    .title {
        text-align: left;
        font-weight: 700;
        font-family: 'Open Sans', sans-serif;
        font-size: 42px;
        line-height: 35px;
        color: #FFFFFF;
    }
    .box {
        img {
            margin-left: 15px;
        }
    }
}

.mobile {
    display: none;
    width: 120px;
    justify-content: space-between;
    .mobile-box {
        width: 55px;
        height: 55px;
        border: 2px solid #FFFFFF;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.course-details-content {
    position: relative;
    overflow-x: hidden;
}

.course-details__part1-title {
    padding-top: 40px;
    .holder-img {
        position: relative;
        .teacher-img {
            width: 35px;
            height: 35px;
            object-fit: contain;
        }
        .tools-img {
            width: 17px;
            position: absolute;
            bottom: 19px;
            right: 15px;
            background: white;
            padding-left: 4px;
            padding-top: 4px;
        }
        span {
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            font-size: 14px;
            line-height: 30px;
            color: #424242;
            padding-left: 15px;
        }
    }
    .description {
        text-align: left;
        font-size: 28px;
        line-height: 38px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        letter-spacing: 0px;
        color: #424242;
        opacity: 1;
        margin-top: 20px;
    }
}

.course-details__table {
    margin-top: 30px;
    .table thead th {
        border: none;
        text-align: left;
        font-weight: 600;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        line-height: 30px;
        color: #006393;
        text-transform: uppercase;
    }       

    .table tbody {
        tr {
            display: flex;
            justify-content: flex-end;
            border-top: 1px solid #E6E6E6;
            opacity: 1;
            align-items: flex-start;
            // align-items: start;
            td {
                border: none;
                text-align: left;
                font-size: 14px;
                line-height: 23px;
                font-family: 'Open Sans', sans-serif;
                color: #8E8E8E;
                &:first-child {
                    img {
                        margin-right: 15px;
                        width: 12px;
                    }
                    color: #8E8E8E;
                    position: relative;
                    display: flex;
                    align-items: center;
                }
                &:last-child {
                    font-weight: 600;
                    color: #424242;
                    text-transform: capitalize;
                    margin-right: 0;
                    margin-left: auto;
                }

                .holder-img {
                    position: relative;
                    .teach {
                        margin-right: 11px;
                        width: 18px;
                    }
                    .busines {
                        left: 1px;
                        bottom: 0px;
                        position: absolute;
                        width: 8px;
                    }
                }
                .identification {
                    width: 16px!important;
                    height: 16px!important;
                }
                .test-img {
                    width: 16px!important;
                    margin-right: 18px!important;
                }
                .passing-exams {
                    margin-left: 3px!important;
                    margin-right: 18px!important;
                }
            }
        }
    }
}

.common-table {
    border-collapse: separate;
    tbody {
        tr {
            td {
                width: 50%;
            }
        }
    }
}

.general__informations-title {
    text-align: left;
    font-weight: 600;
    font-size: 28px;
    line-height: 44px;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0px;
    color: #424242;
    opacity: 1;
    margin-top: 57px;
    margin-bottom: 25px;
}


.first__section {
    border-top: 1px solid #E6E6E6;
    padding-bottom: 44px;
    padding-top: 44px;
    border-bottom: 1px solid #E6E6E6;
    margin-bottom: 8px;
    .first__section-title {
        text-align: left;
        font-size: 20px;
        line-height: 30px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        letter-spacing: 0px;
        color: #424242;
        opacity: 1;
    }
    .list__section-description {
        text-align: left;
        font-size: 14px;
        line-height: 22px;
        font-family: 'Open Sans', sans-serif;
        letter-spacing: 0px;
        color: #424242;
        opacity: 1;
        margin-top: 18px;
        margin-bottom: 18px;
    }
    .list__section {
        .list__section-item {
            max-width: 323px;
            min-height: 44px;
            display: flex;
            align-items: center;
            padding-bottom: 9px;
            border-bottom: 1px solid #F1F1F1;
            &:last-child {
                border: none;
                padding-bottom: 0;
                margin-top: 9px;
            }
        }
        .list__section__item-img {
            width: 44px;
            height: 44px;
            opacity: 1;
            margin-right: 14px;
            border-radius: 100%;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
        .list__section__item__text {
            h2 {
                margin: 0;
                text-align: left;
                font-family: 'Open Sans', sans-serif;
                font-size: 14px;
                line-height: 27px;
                font-weight: 700;
                letter-spacing: 0px;
                color: #1AA1E4;
                text-transform: capitalize;
                opacity: 1;
            }
            span {
                text-align: left;
                font-family: 'Open Sans', sans-serif;
                font-size: 12px;
                line-height: 19px;
                letter-spacing: 0px;
                color: #515151;
                opacity: 1;
            }
        }
    }
    .img-section {
        margin-top: 24px;
        .holder-text {
            position: absolute;
            right: -58px;
        }
        span {
            text-align: left;
            font-weight: 700;
            font-size: 14px;
            font-family: 'Open Sans', sans-serif;
            line-height: 50px;
            letter-spacing: 0px;
            color: #0097E0;
            text-transform: uppercase;
            opacity: 1;
            margin-right: 7px;
        }
        img {
            cursor: pointer;
        }
        .modal-content {
            background: white;
            padding: 55px;
            position: initial;
        }
        .close {
            position: absolute;
            top: 0;
            right: -50px;
            font-size: 20px;
            span {
                color: white;
            }
            &:focus {
                outline: 0;
            }
        }
    }
}


// addressee
.second-section {
    border-bottom: 1px solid #E6E6E6;
    margin-bottom: 20px;
    padding-bottom: 30px;
}

.second-section__text-title {
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    color: #424242;
}

.second-section__text-description {
    color: #424242;
    font-size: 14px;
    line-height: 22px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
}


.third-section {
    margin-top: 45px;
}
.third-section-title {
    text-align: left;
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    color: #424242;
    margin-bottom: 20px;
}

.third-section__first-column, 
.third-section__second-column {
    span {
        text-align: left;
        font-size: 14px;
        line-height: 30px;
        font-weight: 600;
        font-family: 'Open Sans', sans-serif;
        letter-spacing: 0px;
        color: #006393;
        text-transform: uppercase;
        opacity: 1;
        margin-bottom: 14px;
        border-bottom: 1px solid #E6E6E6;
        display: block;
        padding-bottom: 12px;
    }
    div {
        text-align: left;
        font-size: 14px;
        line-height: 23px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        letter-spacing: 0px;
        color: #424242;
        opacity: 1;
        margin-bottom: 12px;
        &:first-child {
            margin-top: 14px;
        }
    }
}
.third-section__first-column {
    span {
        margin-right: 15px;   
    }
}
.third-section-program {
    text-align: left;
    font-size: 14px;
    line-height: 30px;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    color: #006393;
    text-transform: uppercase;
    margin-top: 30px;
    margin-bottom: 15px;
}

.third-section__table {
    background: #DEEEF5 0% 0% no-repeat padding-box;
    padding: 20px 30px;
    margin-bottom: 90px;
}

.third-section__table-title {
    text-align: left;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    color: #424242;
    text-transform: capitalize;
    margin-bottom: 15px;
}

.third-section__table-list1 {
    padding-left: 16px;
    li {
        padding-left: 10px;
    }
}

.third-section__table-list2 {
    list-style: disc;
    padding-left: 46px;
    li {
        padding-left: 0;
    }
}

.fourth-section {
    padding-bottom: 62px;
    border-bottom: 1px solid #E6E6E6;   
    h3 {
        text-align: left;
        font-size: 24px;
        line-height: 30px;
        font-family: 'Open Sans', sans-serif;
        letter-spacing: 0px;
        color: #424242;
        opacity: 1;
        margin-bottom: 27px;
    }
}
.fourth-section__text {
    .description-1 {
        text-align: left;
        font-weight: 400;
        font-size: 14px;
        font-family: 'Open Sans', sans-serif;
        line-height: 23px;
        letter-spacing: 0px;
        color: #424242;
        padding-bottom: 25px;
        border-bottom: 1px solid #E6E6E6;
        span {
            font-weight: 600;
        }
    }
    .description-2 {
        display: flex;
        padding-top: 25px;
        .holder-img {
            margin-right: 16px;
            margin-top: 6px;
        }
        .holder-text {
            text-align: left;
            font-weight: 400;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            line-height: 23px;
            letter-spacing: 0px;
            color: #424242;
            max-width: 400px;
            span {
                font-weight: 700;
            }
        }
    }
}

.fifth-section {
    padding-bottom: 60px;
    h1 {
        text-align: left;
        font-size: 32px;
        line-height: 44px;
        font-family: "Open Sans", sans-serif;
        margin-bottom: 40px;
        font-weight: 600;
        letter-spacing: 0px;
        color: #424242;
        opacity: 1;
        margin-top: 43px;
    }
    h2 {
        text-align: left;
        font-weight: 400;
        font-size: 32px;
        line-height: 30px;
        font-family: 'Open Sans', sans-serif;
        letter-spacing: 0px;
        color: #424242;
        opacity: 1;
        margin-top: 80px;
        margin-bottom: 0;
    }
    p {
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        color: #515151;
        margin-top: 0;
        margin-bottom: 5px;
    }
}

.fifth-section__boxes {
    .box {
        background: #DEEEF5 0% 0% no-repeat padding-box;
        padding: 15px 10px;
        align-items: center;
        display: flex;
        margin-bottom: 10px;
    }
    .box-nr {
        width: 55px;
        height: 55px;
        margin-left: 15px;
        margin-right: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: white;
        font-family: 'Open Sans', sans-serif;
        font-size: 22px;
        color: #515151;
    }
    .box-text {
        text-align: left;
        font-size: 14px;
        line-height: 140%;
        font-family: 'Open Sans', sans-serif;
        color: #515151;
        font-weight: 400;
        span {
            font-weight: 700;
            color: #0097E0;
        }
    }
}
.fifth-section__text {
    .first {
        font-family: 'Open Sans', sans-serif;
        font-size: 18px;
        line-height: 28px;
        font-weight: 400;
        letter-spacing: 0px;
        color: #515151;
        opacity: 1;
        margin-bottom: 20px;
    }   
    .second {
        text-align: left;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0px;
        color: #515151;
        opacity: 1;
        margin-bottom: 20px;
        a {
            text-decoration: none;
            color: #515151;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .third {
        text-align: left;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        letter-spacing: 0px;
        color: #515151;
        span {
            font-weight: 700;            
        }
        p {
            font-weight: 700;
            color: #0097E0;
            margin: 0;
            padding: 0;
            display: inline;
        }
    }
}

.course-details__part2 {
    position: relative;
    background: #F7F8F9 0% 0% no-repeat padding-box;
    border-left: 1px solid #F1F1F1;

    // right: 0;
    // top: 0;
    // width: 100%;
    // opacity: 1;
    // height: 100%;
    // padding-left: 47px;
    // max-width: 36%;
    //max-width: 675px;


    .background-overlay {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 90000px;
        overflow: hidden;
        background: #F7F8F9 0% 0% no-repeat padding-box;
        z-index: -1;
    }


    .other {
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        font-size: 12px;
        color: #0097E0;
        text-decoration: none;
    }

    .follow-us {
        display: flex;
        justify-content: center;
        align-items: center;
        .social {
            width: 25px;
            height: 25px;
            object-fit: contain;
            margin: 10px;
        }
    }

    .continua {
        padding: 13px 15px;
        background: #0097E0 0% 0% no-repeat padding-box;
        border-radius: 0;
        width: 100%;
        border: none;
        outline: none;
        margin-top: 20px;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        line-height: 24px;
        font-weight: 700;
        color: #FFFFFF;
    }
}
.course-details__part2-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    line-height: 19px;
    font-weight: 600;
    color: #424242;
    margin-top: 50px;
    margin-bottom: 15px;
}
.course-details__part2-description {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    line-height: 22px;
    color: #515151;
    margin-bottom: 32px;
    font-weight: 700;
    text-align: center;
    span {
        font-weight: 400;
    }
}
.course-details__part2__box {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E6E6E6;
    padding: 10px 15px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
     input[type='radio'] {
        display: none;
      }
      
       label {
        color: #666;
        font-weight: normal;
      }
      
       label:before {
        content: " ";
        display: inline-block;
        position: relative;
        top: 5px;
        // margin: 0 5px 0 0;
        width: 24px;
        height: 24px;
        right: 15px;
        border-radius: 50px;
        border: 1px solid #8E8E8E;
        background-color: transparent;
      }
      
       input[type=radio]:checked + label:after{
        border-radius: 50px;
        width: 14px;
        height: 14px;
        position: absolute;
        top: 10px;
        left: 10px;
        content: " ";
        display: block;
        background: #0097E0;
       
      }
      input[type=radio]:checked + label:before{
        border: 1px solid #0097E0;
      }

    // input {
    //     width: 25px;
    //     height: 25px;
    //     background: #FFFFFF 0% 0% no-repeat padding-box;
    //     border: 1px solid #0097E0;
    //     margin-top: 0px;
    // }
    // input[type="checkbox"] {
    //     background: #0097E0;
    //     color: #0097E0;;
    // }
    label {
        text-align: left;
        font-size: 16px;line-height: 21px;
        font-weight: 600;
        font-family: 'Open Sans', sans-serif;
        letter-spacing: 0px;
        color: #515151;
        // margin-left: 20px;
        cursor: pointer;
        img {
            opacity: 1;
            width: 9px;
            height: 11px;
            margin-right: 5px;
            margin-left: 25px;
        }
        span {
            text-align: left;
            font-size: 12px;
        }
    } 
    div {
        text-align: right;
        font-size: 18px;
        line-height: 21px;
        font-weight: 600;
        font-family: 'Open Sans', sans-serif;
        letter-spacing: 0px;
        color: #515151;
        cursor: pointer;
    }
    .iva {
        text-align: right;
        font-size: 12px;
        font-weight: 400;
    }
    span {
        text-align: right;
        font-size: 12px;
        line-height: 21px;
        font-weight: 400;
        font-family: 'Open Sans', sans-serif;
        color: #8E8E8E;
    }
}

// css for courseDetails-choiceDate 
.choice-date {
    .request {
        margin-top: 12px;
        a {
            text-decoration: none;
        }
    }
}
.choice-date__box {
    position: relative;
    background: #F7FCFD 0% 0% no-repeat padding-box;
    border: 1px solid #0097E0;
    padding: 15px;
    margin-bottom: 15px;
    .date {
        div {
            font-weight: 600;
            font-size: 22px;
            line-height: 21px;
            font-family: 'Open Sans', sans-serif;
            color: #515151;
        }
        span {
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            font-weight: 600;
            line-height: 13px;
            color: #515151;
            text-transform: capitalize;
        }
    }
    .available {
        background: #4FC1DD 0% 0% no-repeat padding-box;
        border-radius: 20px;
        color: #FFFFFF;
        font-weight: 600 !important;
        font-size: 12px;
        line-height: 23px;
        font-family: 'Open Sans', sans-serif;
        margin-bottom: 25px;
        margin-top: 15px;
        padding: 5px 15px;
        display: inline-block;

        img {
            padding-right: 7px;
            padding-bottom: 3px;
        }
        span {
            font-weight: 700;
        }
    }
    .costo {
        position: absolute;
        top: 15px;
        right: 20px;
        text-align: right;
        font-family: 'Open Sans', sans-serif;
        font-size: 24px;
        line-height: 15px;
        font-weight: 700;
        color: #424242;
        .iva {
            font-size: 14px;
            color: #424242;
        }
        span {
            text-align: right;
            font-size: 14px;
            line-height: 13px;
            font-weight: 600;
            font-family: 'Open Sans', sans-serif;
            color: #8E8E8E;
            text-transform: lowercase;
        }
    }
    .location {
        text-align: left;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        font-family: 'Open Sans', sans-serif;
        letter-spacing: 0px;
        color: #515151;
        text-transform: capitalize;
        opacity: 1;
        margin-bottom: 9px;
        img {
            margin-right: 13px;
        }
        span {
            font-weight: 400;
            margin-left: 29px;
        }
    }
    .time {
        font-family: 'Open Sans', sans-serif;
        font-size: 12px;
        line-height: 23px;
        font-weight: 400;
        letter-spacing: 0px;
        color: #515151;
        opacity: 1;
        margin-bottom: 9px;
        display: flex;
        align-items: center;
        span {
            font-weight: 600;
            padding-left: 9px;
        }
        img {
            margin-right: 12px;
        }
    }
    .lunch {
        font-family: 'Open Sans', sans-serif;
        font-size: 12px;
        line-height: 23px;
        font-weight: 400;
        letter-spacing: 0px;
        color: #515151;
        opacity: 1;
        margin-bottom: 60px;
        span {
            font-weight: 600;
            padding-left: 9px;
        }
        img {
            margin-right: 12px;
        }
    }
    .btn-primary {
        height: 55px;
        background: #0097E0 0% 0% no-repeat padding-box;
        position: absolute;
        bottom: 0;
        margin: initial;
        left: 0;
        border-radius: 0;
        width: 100%;
        border: none;
        color: white;
        font-weight: 700;
    }
}
.choice-date-back {
    // display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    i {
        margin-right: 10px;
        color: #3490dc;
    }
    a {
        text-align: left;
        font-weight: 700;
        font-size: 12px;
        font-family: 'Open Sans', sans-serif;
        color: #0097E0;
        text-transform: capitalize;
        text-decoration: none;
    }
}

// css for courseDetails-choiceDate-access
.choice-date-access__box {
    position: relative;
    border: 1px solid #e64945;
    background: #fff5f4;
    padding: 30px;
    margin-bottom: 15px;
    h5 {
        font-size: 18px;
        margin-top: 25px;
        text-align: center;
        line-height: 26px;
    }
    .accendi, 
    .regjistrati {
        width: 150px;
        margin: auto;
        border-radius: 0;
        padding: 7px 10px;
        font-size: 15px;
        text-transform: uppercase;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        margin-bottom: 15px;
    }

    .accendi {
        color: white;
    }

    .regjistrati {
        border: none;
    }

    .regjistrati:hover{
        color: #ffffff;
    }
}

// css for courseDetails-booked
.booked {
    .btn {
        text-align: center;
        font-size: 14px;
        line-height: 24px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        background: #79BC74 0% 0% no-repeat padding-box;
        img {
            margin-right: 12px;
        }
    }
}

// css for courseDetails-reservation
.reservation {
    .request {
        margin-top: 12px;
    }
}
.field {
  position: relative;
  margin-bottom: 10px;
}

.field__label {
  color: #8E8E8E;
  box-sizing: border-box;
  display: block;
  font-size: 10px;
  line-height: 1;
  opacity: 0;
  overflow: hidden;
  padding: .5em 1em;
  pointer-events: none;
  position: absolute;
  text-overflow: ellipsis;
  top: 0;
  text-align: left;
  transform: translateY(3px);
  transition: all .2s ease-out;
  user-select: none;
  white-space: nowrap;
  width: 100%;
  z-index: 1;
  font: normal normal normal 10px/28px "Open Sans";
  .field--not-empty & {
    opacity: 1;
    transform: none;
  }
}

.field__input {
  background-color: #FFFFFF;
  border: 1px solid #E6E6E6;
  padding: .4761904762em;
  transition: all .2s ease-out;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  color: #000000;
  border-radius: 0;

  &:hover, 
  &:focus {
    /* inset | offset-x | offset-y | blur-radius | spread-radius | color */
    box-shadow: inset 0 0 10px 0 rgba(85, 85, 85, .1);
    outline: 0;
  }
  .field--not-empty & {
    padding-bottom: 2px;
    padding-top: 18px;
  }
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #919293;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #919293;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #919293;
}
:-moz-placeholder { /* Firefox 18- */
  color: #919293;
}

.new-btn {
    display: block;
    border: 2px solid #0097E0;
    color: #0097E0;
    font: normal normal bold 14px/24px "Open Sans";
    border-radius: 0;
    padding: 10px 15px;
}

.reservation__box {
    position: relative;
    background: #F7FCFD 0% 0% no-repeat padding-box;
    border: 1px solid #0097E0;
    padding: 20px;
    margin-bottom: 15px;

    .form-control {
        height: 57px;
    }

    span {
        text-align: left;
        font-weight: 400;
        font-size: 10px;
        font-family: 'Open Sans', sans-serif;
        line-height: 28px;
        letter-spacing: 0px;
        color: #8E8E8E;
        opacity: 1;
        display: block;
        margin-bottom: 80px;
    }

    .btn-primary {
        z-index: 5;
        height: 55px;
        background: #0097E0 0% 0% no-repeat padding-box;
        opacity: 1;
        position: absolute;
        bottom: 0;
        margin: initial;
        left: 0;
        border-radius: 0;
        width: 100%;
        border: none;
        a {
            color: white;
            text-decoration: none;
        }
    }
}

.reservation__box-title {
    text-align: left;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 21px;
    font-weight: 700;
    color: #515151;
    margin-bottom: 20px;
}

.reservation-back {
    margin-bottom: 16px;
    margin-top: 48px;
    i {
        margin-right: 10px;
        color: #3490dc;
    }
    a {
        text-align: left;
        font-weight: 700;
        font-size: 12px;
        font-family: 'Open Sans', sans-serif;
        letter-spacing: 0px;
        color: #0097E0;
        text-transform: capitalize;
        opacity: 1;
        text-decoration: none;
    }
}

// css for courseDetails-additionalInformation
.additional-information {
    .request {
        margin-top: 12px;
        text-decoration: none;
    }
}
.additional-information__box {
    position: relative;
    background: #F7FCFD 0% 0% no-repeat padding-box;
    border: 1px solid #0097E0;
    opacity: 1;
    max-width: 417px;
    padding: 20px;
    margin-bottom: 14px;
    .form-control {
        width: 382px;
        height: 57px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #E6E6E6;
        opacity: 1;
        margin-bottom: 10px;
        padding-top: 14px;
        font-family: 'Open Sans', sans-serif;
        text-align: left;
        font-weight: 600;
        font-size: 14px;
        line-height: 28px;
        letter-spacing: 0;
        color: #000000;
        &::placeholder {
            text-align: left;
            font-size: 14px;
            line-height: 28px;
            font-weight: 400;
            font-family: 'Open Sans', sans-serif;
            letter-spacing: 0;
            color: #8E8E8E;
            opacity: 1;
        }
    }
    span {
        text-align: left;
        font-weight: 400;
        font-size: 10px;
        font-family: 'Open Sans', sans-serif;
        line-height: 28px;
        letter-spacing: 0px;
        color: #8E8E8E;
        opacity: 1;
        display: block;
        margin-bottom: 80px;
    }
    .btn-primary {
        height: 55px;
        background: #0097E0 0% 0% no-repeat padding-box;
        opacity: 1;
        position: absolute;
        bottom: 0;
        margin: initial;
        left: 0;
        border-radius: 0;
        width: 100%;
        border: none;
        a {
            color: white;
            text-decoration: none;
        }
    }
}
.additional-information__box-title {
    text-align: left;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 21px;
    font-weight: 700;
    letter-spacing: 0px;
    color: #515151;
    opacity: 1;
    margin-bottom: 21px;
}
.additional-information-back {
    margin-bottom: 16px;
    margin-top: 48px;
    max-width: 417px;
    text-align: center;
    i {
        margin-right: 10px;
        color: #3490dc;
    }
    a {
        text-align: left;
        font-weight: 700;
        font-size: 12px;
        font-family: 'Open Sans', sans-serif;
        letter-spacing: 0px;
        color: #0097E0;
        text-transform: capitalize;
        opacity: 1;
        text-decoration: none;
    }
}

// css for courseDetails-noDatesAvailable
.request {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 14px;
    color: #0097E0;
    text-transform: uppercase;
    text-decoration: none;
    margin-top: 40px;
    padding-bottom: 25px;
    border-bottom: 1px solid #E6E6E6;
    a {
        text-decoration: none;
    }
} 

.no-dates-available-title {
    text-align: center;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    line-height: 19px;
    font-size: 18px;
    color: #424242;
    margin-top: 50px;
}

.no-dates-available-subtitle {
    text-align: center;
    font-size: 18px;
    line-height: 29px;
    font-family: 'Open Sans', sans-serif;
    color: #515151;
}

// css for courseDetails-noDatesAvailable-input
.no-dates-available-input {
    form {
        max-width: 417px;
    }
    .form-group {
        position: relative;
        img {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            left: 12px;
        }
        .form-control {
            padding-left: 41px;
        }
    }
    .form-check {
        margin-bottom: 12px;
        .form-check-label {
            font-family: 'Open Sans', sans-serif;
            color: #515151;
            font-size: 12px;
            span {
                font-weight: 700;
                color: #0097E0;
            }
        }
    }
    .avvisami {
        height: 55px;
        background: #0097E0 0% 0% no-repeat padding-box;
        opacity: 1;
        bottom: 0;
        margin: initial;
        left: 0;
        border-radius: 0;
        width: 100%;
        border: none;
        max-width: 417px;
        font-weight: 700;
        font-family: 'Open Sans', sans-serif;
    }
    h5 {
        text-align: center;
        font-size: 17px;
        line-height: 29px;
        font-family: 'Open Sans', sans-serif;
        letter-spacing: 0;
        color: #515151;
        opacity: 1;
        max-width: 417px;
    }
    .request {
        margin-top: 50px;
        text-decoration: none;
    }
}

.aggiunativi-button{
    width: 209px;
    height: 55px;
    border: 2px solid #FFFFFF;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 0;
}

.aggiunativi-button:hover{
    color: #ffffff;
}

.bottom-button {
    display: none;
}

.second-section__text-title-1{
    display: none;
}

.mobile-img{
    display: none;
}

@media (max-width: 1600px) {
    .first__section .img-section {
        display: flex;
        justify-content: center;
        flex-flow: column;
        margin-top: 36px;
        .holder-text {
            display: flex;
            justify-content: flex-end;
            margin-top: 27px;
            position: unset;
        }
        span {
            margin-right: 11px;
        }
        a {
            cursor: pointer;
        }
    }
    .course-details__part1-title .description {
        margin-right: 10px;
    }
    .course-details__part1 {
        max-width: 54%;
    }

    .course-details__part2__box, 
    .course-details__part2 .request, 
    .course-details__part2 .follow-us {
        max-width: 380px;
    }

 
    .second-section {
        .second-section__image {
            max-width: 300px;
            margin: auto;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .fourth-section-image {
        max-width: 300px;
        margin: auto;
        img {
            width: 100%;
            height: 100%;
        }
    }
}

@media (min-width: 1200px) {
    .course-details-content > .container {
        max-width: 1170px;
    }
}

@media (min-width: 1600px) {
    .course-details-content > .container {
        max-width: 1540px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .choice-date__box .costo {
        position: relative;
        margin-bottom: 15px;
        text-align: left;
        right: 0;
    }
}

@media (max-width: 991px) {
    .course-details__header__content {
        .container {
            flex-flow: column;
            justify-content: center;
        }
        .title {
            margin-bottom: 25px;
            text-align: center;
            line-height: 42px;
        }
    }

    .course-details__header {
        background-size: auto;
    }

    .common-table {
        tbody {
            tr {
                td {
                    width: auto;
                }
            }
        }
    }

    .course-details__table {
        background-color: #F8F9FA;
    }

    .course-details__part1 {
        max-width: 100%;
    }

    .course-details__part2 {
        display: none;
    }

    .course-details__part2__mobile {
        .course-details__part2 {
            position: fixed;
            top: 0;
            bottom: 0;
            z-index: 9;
            height: auto;
            display: block;
            max-width: 100%;
            padding: 0;
            .btn-collaps {
                width: 100%;
                height: 57px;
                background: #C4C4C4;
                color: white;
                font-size: 14px;
                font-weight: 700;
                font-family: 'Open Sans', sans-serif;     
                border-radius: 0;
                margin: 0;
                padding-left: 0;
                padding-right: 0;
                box-shadow: none;
                position: absolute;
                bottom: 0;
            }
            .request {
                margin-top: 0;
                margin-bottom: 28px;
                border: none;
            }

            .other {
                margin-top: 15px;
                margin-bottom: 45px;
            }
        }
        .btn-static {
            z-index: 99999;
            border-radius: 0;
            margin: 0;
            padding-left: 0;
            padding-right: 0;
            background: #1AA1E4;
            position: fixed;
            bottom: 0;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            font-weight: 700;
            height: 57px;
            box-shadow: none;
            color: white;
        }
    }
    
    .course-details__part1-title .description {
        margin-right: 0;
        font-size: 21px;
        line-height: 36px;
    }
}

@media (max-width: 575px) {
    .viewMore{
        display: none;
        justify-content: center;
        align-items: center;
        margin: auto;
        margin-top: 17px;
        color:#0097E0;
        border: none;
        background-color: transparent;
        font-family: 'Open Sans', sans-serif;
        font-size: 12px;
        font-weight: 700;
    }
     
    .course-details__part2-title {
        margin-bottom: 15px;
    }
    .course-details__part1-title {
        .holder-img {
            span{
                font-size: 12px;
            }
        }
    }
    .course-details__table {
        .table thead th {
            line-height: 25px;
        }
        .table tbody {
            tr {
                border-top: none;
                border-bottom: 1px solid #E6E6E6;
                td{
                    font-size: 12px;
                }
            }
        }
    }
    .course-details__header__content {
        .title {
            line-height: 42px;
            font-size: 30px;
        }

        .box {
            display: none;
        }

        .mobile {
            display: flex;
            width: 120px;
            justify-content: space-between;
        }
    }

    .general__informations-title {
        margin-top: 30px;
        margin-bottom: 10px;
        font-size: 22px;
        font-family: 'Open Sans', sans-serif;
        font-weight: normal;
        line-height: 32px
    }

    .first__section {
        .list__section {
            .list__section-description {
                font-size: 12px;
                margin-top: 10px;
                color: #666666;
            }
            .list__section-item {
                padding-bottom: 9px;
                padding-top: 9px;
                &:last-child {
                    margin-top: 0;
                }
            }
        }
        padding-top: 20px;
        padding-bottom: 25px;
        .first__section-title{
            font-size: 16px;
            line-height: 30px;
        }
    }
    .third-section {
        margin-top: 28px;

        .third-section-program {
            margin-top: 18px;
        }
    }
    .third-section__first-column span {
        margin-top: 31px;
        padding-bottom: 6px;
    }
    .third-section__second-column span {
        margin-top: 18px;
        padding-bottom: 6px;
    }
    .third-section__table {
        margin-bottom: 25px;
        p{
            font-weight: normal;
            font-size: 13px;
            color: #666666;
            line-height: 22px;
            padding: 0;
            margin: 0;
            &:first-child{
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 16px;
                u{
                    text-decoration: none;
                }
            }
        }
             
    }
    .fourth-section {
        padding-bottom: 30px;
        .fourth-section__text {
            order: 1;
            p{
                font-size: 12px;
                font-weight: normal;
                color: #666666;
                line-height: 22px;
            }
        }

        .fourth-section-image{
            order: 0;
        }
        h3{
            font-size: 16px;
            font-weight: 600;
            padding-left: 15px;
        }
    }
    .fifth-section {
        padding-bottom: 0;
        margin-bottom: 25px;
        margin-top: 30px;
        .first {
            margin-top: 12px;
        }
        h2 {
            margin-top: 50px;
            margin-bottom: 50px;
        }
    }
    
    .course-details .navbar .nav-item:last-child {
        max-width: 160px;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
    }
}

@media (max-width: 576px) {
    .for-account-link-mobile{
        border: 2px solid #0097E0;
        margin-top: 30px;
        padding-top: 18px;
        padding-bottom: 18px;
        font-size: 14px;
        font-weight: 600;
        font-family: 'Open Sans', sans-serif;
    }
    .prenotato{
        background-color: #79BC74;
        opacity: 1 !important;
        z-index: 1;
    }
    
    
    .book-form-fixed-button{
        // height: calc(100% - 20px);
        overflow-y: auto;
    }

    .bottom-button{
        position: fixed;
        bottom: 0px;
        width: 100%; 
    }

    .avvisami-button{
        width: 100%;
        height: 57px;
        background-color: #1897E0;
        border-radius: 0px;
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 700;
        font-family: 'Open Sans', sans-serif;     
    }

    .modal-content p {
        text-align: center;
        font-size: 16px;
        padding: 18px;

    }

    .modal-2-para{
        text-align: left !important;
        font-size: 12px !important;
        color:#515151;
        font-family: 'Open Sans', sans-serif;
        margin-left: 23px; 
        margin-top: -10px;   
    }
    .modal-content img{
        margin-left: 20px;
    }

    .button-avvisami{
        margin-left: 45px;
        margin-bottom: 27px;
    }
    .button-avvisami a{
        margin-left: 27px;
        color: #0097E0;
        font-weight: bold;
        font-family: 'Open Sans', sans-serif;
        text-transform: uppercase;
        text-decoration: none;
    }
    .bottom-button {
        display: block;
    }
    .modal-dialog {
       margin: 0 !important; 
       
    }

    .modal-content-one, .modal-content-three{
        margin-top: 657px !important;
        border-radius: 0;
        border: none;
        background-color: #F7F8F9;
    }

    .corsis-heading{
      display: block !important;
    }

    .second-section__text-title-1{
        display: block;
    }

    .mobile-img{
        display: block;
    }

    .modal-dialog {
        max-width: 100%;
    }

    .third-section__table-list2 {
        list-style: disc;
        padding-left: 46px;
        font-size: 12px;
        line-height: 22px;
    }

    .third-section__table-list1 {
        padding-left: 16px;
        font-size: 12px;
        line-height: 22px;
    }

    .hide-this-pagination{
     display: none;
    }
    .fifth-section__text .first {
        font-size: 12px;
        line-height: 22px;
    }
    .fifth-section__text .second {
        font-size: 12px;
    }
    .fifth-section__text .third {
        font-size: 12px;
    }
    .fifth-section p {
        font-size: 12px;
    }
    .fifth-section h1 {
        font-size: 16px;
        margin-bottom: 11px;
        margin-top: -20px;
    }
    .fifth-section__boxes .box-text {
      font-size: 12px;
    }
    .fourth-section h3 {
        font-size: 16px;
    }
    .fourth-section h3 {
        margin-bottom: 0;
    }

    .fourth-section__text .description-1 {
        text-align: left;
        font-weight: 400;
        font-size: 12px;
    }

    .third-section__first-column span, .third-section__second-column span {
        font-size: 12px;
    }

    .third-section__first-column div, .third-section__second-column div {
        font-size: 12px;
    }


}

