$background_color: #F8F9FA;
$date_color: #424242;
$heading_color: #0097E0;
$location_color: #515151;
$background-color1:#EBA661;
$background-color2: #4FC1DD;;


.calendario__corsi--item{
    background-color: $background_color;
    margin-bottom:20px ;

    .calendario__corsi--date{
      font-family: $OpenSans-Regular;
      font-size: 20px;
      text-align: center;
      margin-top:31px;
      margin-left: 10px;
      color: $date_color;
      span{
          font-size: 34px;
      }
    }

 .calendar__corsi--heading{
 color: $heading_color;
 font-size: 20px;
 font-family: $OpenSans-Regular;
 margin-top: 20px;

 }
 .calendario__corsi--location{
 color: $location_color;
 font-family: $OpenSans-SemiBold;
 font-size: 15px;
 }

.calendario__corso--paragraph{
    color: $location_color;
    font-size: 12px;
    font-family:$OpenSans-Regular ;
}
.calendario__corsio--info{
    color: $location_color;
    font-size: 12px;
    font-family:$OpenSans-Regular ;
}

.calendario__corsio--price{
    font-size: 24px;
    color: $location_color;
    font-family: $OpenSans-SemiBold;
    margin-top: 20px;
    span{
        font-size: 14px;
    }
}

.calendario__corsio--gratuito{
    font-size: 24px;
    font-family:$OpenSans-Regular ;
    color: $location_color;
    margin-top: 20px;
    margin-bottom: 20px;
}

.calendario__corsio--posti{
    font-size: 12px;
    font-family:$OpenSans-Bold;
    color: #ffffff;
    background-color: $background-color2;
    border-radius: 21.25px;
    width: 132px;
    padding: 4px 12px 4px 12px;
    margin-top: 44px;
    .fa-user{
        margin-right: 5px;
    }

}

.calendario__corsio--posti--color{
    background-color: $background-color1;
}

}

.calendario__corso--button-1{
    text-align: center;
    .calendario_corso--button{
        color: $background-color2; 
        border: 2px solid $background-color2;
        background-color: #ffffff;
        border-radius: 0px;
        width: 233px;
        height: 55px;
        margin-top: 50px;
        margin-bottom: 60px;
        font-family: $OpenSans-Bold;
     }
     

}
.calendario__corsio--essauriti{
    font-size: 12px;
    font-family: $OpenSans-Regular; 
    color: $location_color;
}

.calendario__corsio--scopri{
    font-size: 12px;
    font-family: $OpenSans-Regular; 
    color: $location_color;
    text-transform: uppercase;
    line-height: 0px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 51px;
    height: 27px;
  }

  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color:#F3F5F6;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  
  .calendario_corsi--resultati{
      font-size: 14px;
      color: #0097E0;
      text-transform: uppercase;
      cursor: pointer;
  }

  .calendario__corsi--heading{
      font-size: 20px;
      color: #424242 ;
      margin-top: 20px;

  }

  .calendario__corsi--dispobili{
      font-size: 14px;
      display: inline-block;
      padding-top: 11px;
  }

  .calendario__corsi--form--control{
      height: 57px;
      width: 388px;
  }

  .calendario__corsi--section{
      border-top: 1px solid #E6E6E6;
      border-bottom: 1px solid #E6E6E6;
  }
  .calendario__corsi--flitra--button{
      color: #ffffff;
      text-transform: uppercase;
      background-color:#0097E0;
      width: 147px;
      height: 40px;
      border-radius: 0px;
      margin-top: 30px;
      margin-bottom: 30px;
  }
  .calendario__corsi--flitra--button1{
     text-align:right ;
  }

  .calendario__corsi--ordina{
font-size: 14px;
color:#424242;
  }
  .dropdown-item:hover{
      background-color:#F7FCFD ;
      color: #2196F3;
  }


  .dropdown-menu1.show {
   border: none;
   border-radius: 0;
   margin-top: -49px;
}

.calendario__corsi--filtra{
    margin-top: 35px;
} 

.calendario__corsi--risultati{
    font-size: 14px;
    color: #424242;
    font-family: $OpenSans-Bold;
    
}





