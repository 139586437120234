.my_profile--inputs{
    background-color: #F7F8F9;
    padding: 46px;
}

.profile__inputs {
    max-width: 800px;
    width: 100%;
    margin: auto;
}

.area__personale--heading{
    font-size:25px;
    color: #424242;
    border-bottom: 1px solid #E6E6E6;
    padding-bottom: 22px;
    margin-top: 42px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
}


.area__personale--paragraph{
    font-size: 14px;
    color:#515151;
    margin-top: 30px;
    margin-bottom: 30px;
    font-family: 'Open Sans', sans-serif;
}

    .email{
        font-size: 14px;
        color: #1897E0;
        font-family: 'Open Sans', sans-serif;
        font-weight: bold;
    }


.area__personale--label{
    font-size: 12px;
    color: #8E8E8E;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
}

.area__personale--info{
    font-size: 14px;
    color: #000000;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
}

.profile__inputs .form-control{
    width: 100%;
    height: 57px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E6E6E6;
    opacity: 1;
    margin: 0 0 20px 0;
    border-radius: 0;
}

.pass__position{
    text-align: right;
    margin-top: 33px;
.modifica___pass{
   font-size: 14px;
   text-transform: uppercase; 
   color: #1897E0;
   justify-content: flex-end;
   font-family: 'Open Sans', sans-serif;
   font-weight: 700;

   .modifica___pass:hover{
       text-decoration: none;
   }

}


}

.area__personal--title{
    font-size: 18px;
    color: #424242 ;
    margin-top: 30px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600 ;
}

.area__personale--input{
    border-bottom: 1px solid #E6E6E6;
}

.area__personale--section{
    line-height: 3px;
    margin-bottom:23px;
}

.profile__inputs--button{
    margin-top: 30px;
    margin-bottom: 100px;
}

.area__personale--codice--certificato{
    font-size:14px ;
    color: #8E8E8E;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
}







h3.offset-1.my-courses-favorite-title-1 {
    margin-left: 166px;
    margin-top: 30px;
    margin-bottom: 30px;
    color: #424242;
    text-align: left;
    letter-spacing: 0px;
    color: #424242;
    opacity: 1;
}



.my-courses-favorite-title h3 {
    margin-top: 50px;
    color: #424242;
    text-align: left;
    letter-spacing: 0px;
    color: #424242;
    opacity: 1;
    font-family: 'Open Sans', sans-serif;

}

.corsi__slider.corsi__slider-1.container {
    margin-bottom: 136px;
}

.has-search .form-control {
    padding-left: 2.375rem;
    height: 57px;
    border: 1px solid #E6E6E6;
    border-radius: 0px;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 3.5rem;
    text-align: center;
    pointer-events: none;
    color: #515151;
}

.form-group.has-search.has-search-favorite {
    margin-top: 52px;
}

.sidebar-list {
    list-style: none;
}

li.nav-item {
  
    height: 47px;
    color: var(--unnamed-color-0097e0);
    text-align: left;
    font: normal normal 600 14px/30px Open Sans;
    letter-spacing: 0px;
    color: #0097E0;
    opacity: 1;
    padding-top: 7px;
    font-size: 14px;
}

.selected-item {
    background: #0097E0;
    color: #ffffff !important;
    border-radius: 5px;
    cursor: pointer;
    
}

.sidebar-title {
    padding-top: 49px;
    text-align: left;
    font: normal normal normal 25px/19px Open Sans;
    letter-spacing: 0px;
    color: #424242;
    opacity: 1;
    font-size: 25px;

}



@media(max-width: 768px) {
    .area__personale--heading{
        display:none
    }

    .register__banner .register__banner--titles .register__banner-paragraph {
        display: none;
    }
}

@media(max-width: 640px) {
    .my_profile--inputs {
        background-color: #F7F8F9;
        padding: 18px;
        padding-bottom: 87px;
    }
}
@media(max-width: 579px) {
    .area__personale--info{
        line-height: 15px;
        margin-top: 15px;
    }
    .profile__inputs{
        .area__personale--paragraph{
            font-weight: normal;
            font-size: 14px;
            color: #666666;
            line-height: 22px;
            a{
                color: #0097E0;
                font-weight: bold;
            }
        }
        .area__personal--title{
            font-size: 14px;
            color: #424242;
            margin-top: 15px;
            margin-bottom: 15px;
            font-family: "Open Sans", sans-serif;
            font-weight: 600;
        }
        .area__personale--input{
            padding-bottom: 10px;
        }
        .area__personale--section{
            margin-bottom: 0;
        }
    }
}

@media(max-width: 400px) {
    .pass__position .modifica___pass {
        font-size: 12px;
    }
}


        

