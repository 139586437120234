
@media only screen and (max-width: 600px)  {

.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    background-color: #ffffff;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
    box-shadow: -3px 0 5px 0 #555;
}

.sidebar-section-1 {
    background-color: #ffffff;
    padding-bottom: 13px;

}

.sidebar-section-2 h3 {
    padding-top: 14px;
    padding: 8px 8px 8px 32px;
    font-weight: normal;
    font-size: 23px;
    color: #09abd0;

}

.sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 23px;
    color: black;
    display: block;
    transition: 0.3s;
}

.sidenav a:hover {
    color: #f1f1f1;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;

    font-size: 36px;

}

.sidebar-section-2 {
    background-color: #efefef4d;
    padding-bottom: 410px;
    padding-top: 1px;
}


.sidebar-section-2 a {
    font-size: 15px;
}

.sidebar-section-margin {
    margin-top: -28px;
}

.prove {
    display: flex;
    margin-top: 19px;
}

.home__notification__text h2 {
    font-size: 15px;
}

.btn-success {
    margin-top: 10px !important;
    margin-left: 67px;
}

.home__training-title {
    text-align: left;
    font-size: 24px;
}

.home__training-subtitle {
    text-align: left;
    font-size: 14px;
    line-height: 23px;
}

.home__training__item__text h2 {
    font-size: 14px;
}

.home__insertImage__img__content p {
    font-size: 17px;
}

.home__slider-title {
    text-align: left;
    font-size: 23px;
    margin-top: -133px;
}

.home__slider-subtitle {
    text-align: left;
    font-size: 11px;

}

.home__video__container__text div {
    font-size: 14px;
}

.home__video__container__text h1 {
    font-size: 22px;
}

.home__video__container__video {
    margin-bottom: 41px;
}

.corsi__nav {
    background-color: #F7FCFD;
}

.corsi__training .corsi__training__text div {
    text-align: left;
}

.corsi__training__text-description {
    font-size: 13px;
}

.corsi__training__text-title {
    font-size: 22px;
}

.corsi__training__button button {
    border-radius: none;
}

.corsi__training-img img {
    position: absolute;
    width: 134px;
    transform: translateX(69px);
    top: -59px;
}

.corsi__training__text-title {
    margin-top: 63px;
}

.copyright-content {
    display: block;

}


.corsi__header h3 {
    font-size: 14px;
}

.corsi__header h1 {
    font-size: 23px;
}

.corsi__install {
    margin-bottom: 20px;
    margin-bottom: 30px;

}

.corsi__online__item__grey-text {
    font-size: 16px;
}

.corsi__online__item__gradient .gradient-text {
    font-size: 19px;
}

.course-details__header__content .title {
    font-size: 25px;
}

.table-header-mobile {
    border: none;
    text-align: left;
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0px;
    color: #006393;
    text-transform: uppercase;
    opacity: 1;
    margin-top: 25px;
}

.second-section .second-section__image {
    max-width: 216px;
}

.second-section__text-title {
    font-size: 17px;
}

.second-section__text-description {
    font-size: 13px;
}

.second-section__image img {
    max-width: 216px;
}

.fifth-section h1 {
    font-size: 18px;
}

.fifth-section h2 {
    font-size: 24px;
}

.corsiCalendar-header h1 {
    font-size: 22px;
}

.corsiCalendar-header__first-input input {
    background: #ffffff;
}

.corsiCalendar-header__second-input select {
    background: #ffffff;
}

.corsiCalendar-header__second-input {
    background: #Ffffff 0% 0% no-repeat padding-box;
}

.corsiCalendar-header__third-input {
    background: #Ffffff 0% 0% no-repeat padding-box;
}

.corsiCalendar-header__third-input select {
    background: #ffffff 0% 0% no-repeat padding-box;

}


.filterResult img {
    z-index: 0;
}

.accordion-mobile-card {
    margin-left: 30px;
    margin-right: 30px;

}



.accordion-mobile-flex {
    display: flex;
}



.see-more-accordion i {
    padding: 19px;
}


.holder-img {
    border-bottom: 1px solid #aaa;
    font-size: 12px;
    color: #8E8E8E;
}

img.heart {
    width: 33.62px;
    margin-top: 3px;
    margin-right: 10px;
}

span.iva {
    font-size: 14px;
    font-weight: normal;
}

.price {
    font-size: 22px;
    font-weight: bold;
    color: #515151;
    margin-top: 14px;

}

button.btn.vai-mobile {
    background-color: #0097E0;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 600;
    width: 302px;
    height: 57px;
    border-radius: 1px;
    width: 100%;
    margin-top: 22px;
}

.month {
    font-size: 14px;
}

.time,
.id {
    font-size: 12px;
    color: #515151;
    margin-bottom: 12px;
}

img.teacher-img {
    width: 27px;
}

.corsis-calendar-button {
    text-align: center;
}

button.btn.btn-outline-primary.loadOthers-mobile {
    width: 100%;
    max-width: 233px;
    height: 57px;
    border: 2px solid #0097E0;
    border-radius: 1px;
    font-size: 14px;
    color: #0097E0;
    font-weight: bold;
    margin-bottom: 68px;
}

.faq__banner--main-title-mobile {
    font-size: 22px;
    color: #FFFFFF;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    text-align: center;
}

.faq__banner--titles {
    margin-top: 0px;
    padding: 20px;
}

.faq__banner--paragraph-mobile {
    font-size: 14px;
    color: #FFFFFF;
    font-family: "Open Sans", sans-serif;
    text-align: center;
}

.faq__section--heading-mobile {
    text-transform: uppercase;
    font-size: 22px;
    color: #424242;
    font-family: "Open Sans", sans-serif;
    margin-top: 27px;
}

.faq__section--paragraph-mobile {
    font-size: 14px;
    color: #515151;
    margin-top: 20px;
    margin-bottom: -132px;
    font-family: "Open Sans", sans-serif;
}

.register__inputs p {
    font-size: 14px;
}

.register-inputs-mobile-privacy-heading {
    font-size: 12px;
    color: #515151;
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
}

p.register-inputs-mobile-privacy-paragrapgh {
    font-size: 12px;
    color: #666666;
}

.register__inputs hr {
    margin-top: 60px;
}

.register__inputs .register__inputs--button {
    max-width: 302px;
    width: 100%;
    height: 55px;
}

h2.supporto-mobile-header {
    font-size: 22px;
    font-weight: bold;
    color: #424242;
    font-family: "Open Sans", sans-serif;
    text-align: center;
    margin-top: 26px;
}

.supporto-mobile-paragraph {
    text-align: center;
    font-size: 14px;
    color: #424242;
    font-family: "Open Sans", sans-serif;
}

.register__inputs--button {
    width: 100%;
    max-width: 302px;
    height: 55px;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: bold;
    border-radius: 0px;
    font-size: 14px;
    margin-top: 23px;
}

.supporto-mobile-link {
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: #0097E0;
    text-transform: uppercase;
    margin-top: 20px;
    margin-bottom: 46px;
}



.corsiCalendar-header-background {
    min-height: 267px !important;
}


.software-mobile-date-one {
    background-color: #F7F8F9;
}

.software-mobile-date-two {
    background-color: #F3F5F6;
}


.table th,
.table td {
    border-top: 1px solid #fff;
}

.software-data-mobile-nome {
    font-size: 14px;
    font-weight: bold;
    color: #0097E0;
    font-family: "Open Sans", sans-serif;

}

.software-mobile-data-dsc {
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    color: #424242;


}

.holder-video-card .holder-img img {
    width: 55.96px;
    height: 30.56px;
}

.video.video-all .btn-outline-primary {
    width: 281px;
    height: 55px;
    border-radius: 0px;
}

.holder-video-card .card-title {
    font-size: 16px;
    margin-bottom: 16px;
}
.holder-img {
    border-bottom: none;
    }

    /*header*/
    .mobile-header {
        padding: 20px;
        display: flex ;
        align-items: center;
        justify-content: space-between;
    }

    .mobile-header-logo {
    text-align: center;
    // margin-top: -27px;
    }

    .mobile-header-search {
    text-align: right;
    // margin-top: -29px;
    font-size: 22px;
    cursor: pointer;
    }

    .mobile-header span {
    font-size: 22px;
    cursor: pointer;
    }

    .menu-line {
        width: 259px;
        border-bottom: 1px solid #F1F3F4;
        margin-top: 0;
        margin-bottom: 0;
    }

    /*footer*/
    .footer-card-header {
        background-color: #F1F3F4;
        height: 45px !important;
    }

    .footer-collapse-img {
        margin-top: -22px;
    }

    .footer-card-body {
        margin-top: -6px;
        background-color: #F1F3F4 !important;
        display: grid;
        text-align: center;

    }

    .footer .footer__logo {
        text-align: left;
        margin-bottom: 22px;
    }

    .footer__logo img {
        width: 127px;
    }

    .footer .footer__follow div {
        padding-top: 14px;
    }

    .footer-heading {
        font-size: 14px;
        color: #000000;
        font-family: "Open Sans", sans-serif;
        font-weight: 600;

    }

    .footer-card-link {
        text-decoration: none;
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        font-size: 14px;
        color: #6c757d;
        line-height: 30px;
    }

    /*risultati */
    .risultati-mobile{
       color: #424242;
       font-family: "Open Sans", sans-serif;
       font-size: 14px;

    }

    .ordina-mobile{
        color: #424242;
       font-family: "Open Sans", sans-serif;
       font-size: 14px
    }

    .ordina-mobile span{
        color: #0097E0; 
        font-family: "Open Sans", sans-serif;
        font-weight: bold;
        text-transform: uppercase;
    }
       
    .risultati-data-mobile {
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
    }

    .video.video-all .btn-outline-primary {
        width: 281px;
        height: 55px;
        border-radius: 0px;
    }

}

@media only screen and (max-width: 600px)  {
.holder-video-card .card-title {
    font-size: 16px;
    margin-bottom: 16px;
}
.holder-img {
    border-bottom: none;
    }

    .nome-video-mobile-heading{
        font-size:22px;
        color: #424242;
        font-family: "Open Sans", sans-serif;
        text-align: center;
        font-weight: 600;
        margin-top:23px;
        margin-bottom: 23px;

    }

}

@media (max-width: 375px) { 
    .accordion-mobile-card {
        max-width: 302px;
        margin: 0 auto;
        float: none;
        margin-bottom: 22px;
        width: 100%;
    }
}


