@import "../variables";
@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";

.slider__content {
    display: flex!important;
    .prevArrow {
        display: flex;
        justify-content: center;
        margin: auto;
        border-radius: 50%;
        //width: 80px;
        //height: 50px;
        width: 81px;
        height: 50px;
        outline: none;
        opacity: 1;
        transform: rotate(180deg);
        cursor: pointer;
        border: 1px solid #000000;
        margin-right: 93px;
        img {
            outline: none;
            align-self: center;
            //width: 17px;
            //height: 17px;
            //left: 15px;
            width: 20px;
            height: 20px;
            left: 17px;
        }
    }
    .prevArrow.slick-disabled {
        background: url("../assets/img/arrow-left.svg") no-repeat;
        background-position: center;
        transform: rotate(0deg);
        border: 1px solid #80808042;
        img {
            display: none;
        }
    }

    .slider__content .prevArrow img {
         left: 0px !important;
    }

    .nextArrow {
        display: flex;
        justify-content: center;
        margin: auto;
        border-radius: 50%;
        //width: 80px;
        //height: 50px;
        width: 81px;
        height: 50px;
        outline: none;
        //border: 1px solid #80808042;
        opacity: 1;
        cursor: pointer;
        border: 1px solid #000000;
        margin-left: 93px;
        img {
            outline: none;
            align-self: center;
            //width: 17px;
            //height: 17px;
            //right: 15px;
            width: 20px;
            height: 20px;
            right: 17px;
        }
    }
    .nextArrow.slick-disabled {
        background: url("../assets/img/arrow-left.svg") no-repeat;
        background-position: center;
        transform: rotate(180deg);
        border: 1px solid #80808042;
        img {
            display: none;
        }
    }
}
.slider__content-item {
    //max-width: 382px;
    position: relative;
    width: 382px;
    height: 314px;
    background: #F8F9FA 0% 0% no-repeat padding-box;
    opacity: 1;
    margin-right: 13px;
    margin-left: 13px;
    outline: none;
    a {
        text-decoration: none;
        outline: none;
    }
}
.slider__content-item-gradient {
    width: 100%;
    height: 190px;
    background: transparent linear-gradient(180deg, #D3EFFF 0%, #35B2FA 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    position: relative;
    img {
        position: absolute;
        margin: auto;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }
    .gradient-text {
        letter-spacing: 0px;
        color: #FFFFFF;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        font-size: 12px;
        position: absolute;
        bottom: 12px;
        left: 24px;
        line-height: 26px;
        span {
            font-family: 'Open Sans', sans-serif;
            font-weight: 700;
            line-height: 26px;
            font-size: 24px;
        }
        .iva {
            font-size: 14px;
        }
    }
}
.slider__content-item-grey {
    width: 100%;
    height: 118px;
    opacity: 1;
    padding: 12px 20px 20px 24px;
    background: #F8F9FA 0% 0% no-repeat padding-box;
    position: relative;
}
.slider__content-item-grey-text {
    font-size: 20px;
    line-height: 30px;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0px;
    color: $blueDefault;
    text-transform: capitalize;
    opacity: 1;
}
.slider__content-item-grey-images {
    position: absolute;
    right: 20px;
    display: flex;
    //bottom: 20px;
    bottom: 14px;
    .calendar {
        cursor: pointer;
    }
    .heart {
        padding-left: 18px;
        opacity: 0.5;
    }
}

.calendar-content {
    position: absolute;
    top: 0;
    background: #ebf0f5;
    height: 314px;
    width: 100%;
    padding: 12px;
    display: none;

    overflow: hidden auto;
    text-align: justify;
    .course-details__part2-title {
        margin-top: 8px;
        margin-bottom: 8px;
        font-size: 13px;
        max-width: unset;
    }
    .course-details__part2__box {
        height: 60px;
        margin-bottom: 10px;
        max-width: unset;
        label {
            margin-left: 0;
            img {
                display: inline;
            }
        }
    }
    .btn-primary {
        img {
            position: absolute;
            right: 50px;
            top: 0px;
            bottom: 5px;
            margin: auto;
        }
        height: 55px;
        opacity: 1;
        bottom: 0;
        position: relative;
        left: 0;
        right: 0;
        margin: auto;
        width: 100%;
        border: none;
    }
}

.slider__content-item-1 {
    position: relative;
    width: 382px;
    height: auto;
    background: #F8F9FA 0% 0% no-repeat padding-box;
    opacity: 1;
    margin-right: 13px;
    margin-left: 13px;
    outline: none;
}

.slider__content-item-grey-1 {
    width: 100%;
    height: auto;
    opacity: 1;
    padding: 12px 20px 20px 24px;
    background: #F8F9FA 0% 0% no-repeat padding-box;
    position: relative;
}

.slider-location{
    color: var(--unnamed-color-515151);
text-align: left;
font: normal normal 600 15px/16px Open Sans;
letter-spacing: 0px;
color: #515151;
text-transform: capitalize;
opacity: 1;
font-size: 14px;
}

.slider-date{
color: #515151;
text-align: left;
letter-spacing: 0px;
color: #515151;
text-transform: capitalize;
opacity: 1;
font-size: 14px;
}

.slider-section-1{
 justify-content: space-between;
}

a.slider-choose {
   
    text-align: left;
    font: normal normal bold 15px/30px Open Sans;
    letter-spacing: 0px;
    color: #0097E0;
    text-transform: uppercase;
    opacity: 1;
    margin-top: 16px;
    font-size: 14px;
}

hr.slider-line {
    margin-top: 0px;
    margin-bottom: -6px;
}


@media (max-width: 2560px) {
    .slider__content .nextArrow {
        width: 81px;
        height: 50px;
    }

    .slider__content .prevArrow {
        width: 81px;
        height: 50px;
    }
}

@media (max-width: 1600px) {
    .slider__content {
        .nextArrow {
            width: 330px;
            height: 58px;
            img {
                right: 19px;
            }
        }
        .prevArrow {
            width: 330px;
            height: 58px;
            img {
                left: 19px;
            }
        }
    }
    .calendar-content {
        .btn-primary {
            padding-right: 45px;
        }
    }
}
@media (max-width: 992px) {
    .slider__content {
        .nextArrow {
            width: 270px;
            height: 58px;
            margin-left: 65px;
            img {
                right: 18px;
            }
        }
        .prevArrow {
            width: 270px;
            height: 58px;
            margin-right: 65px;
            img {
                left: 18px;
            }
        }
    }
}
@media (max-width: 767px) {
    .slider__content {
        position: relative;
        max-width: 420px;
        margin: auto;
        .prevArrow {
            position: absolute;
            bottom: -90px;
            left: 30%;
            width: 50px;
            height: 50px;
            margin: 0;
            img {
                left: 18px;
                width: 15px;
                height: 15px;
            }
        }
        .nextArrow {
            position: absolute;
            bottom: -90px;
            right: 30%;
            width: 50px;
            height: 50px;
            margin: 0;
            img {
                right: 18px;
                width: 15px;
                height: 15px;
            }
        }
    }
}
@media (max-width: 576px) {
.slider__content-item-1 {
    margin-right: 22px;
  
}

.orange-bg {
    left: 130px !important;
}
}