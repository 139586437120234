.order-status-title {
    text-align: left;
    font-size: 25px;
    line-height: 19px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0;
    color: #424242;
    margin-bottom: 22px;
    margin-top: 54px;
    
    span {
        font-weight: 700;
    }
}

.order-status-pagination-content {
    position: relative;
    .download {
        border: 2px solid #0097E0;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        font-size: 14px;
        line-height: 28px;
        color: #0097E0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 15px 30px;
        border-radius: 0;
        margin-bottom: 10px;
        img {
            margin-right: 13px;
        }
    }
    
    .dataTables_scrollHeadInner {
        margin: auto;
    }

    table {
        max-width: 1334px;
        margin: auto;
        tr {
            &:nth-child(even) {
                background: rgba(247, 248, 249, 0.31);
            }
            &:nth-child(odd) {
                background: #F7F8F9
            }
        }
        thead {
            tr {
              th {
                  text-align: left;
                  letter-spacing: 0px;
                  color: #515151;
                  text-transform: capitalize;
                  opacity: 1;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 20px;
                  font-family: 'Open Sans', sans-serif;
                  background: white;
                  white-space: pre;
                  padding-bottom: 20px;
                  border: none;
                  cursor: pointer;
                  .holder-title {
                      display: flex;
                      align-items: center;
                      white-space: nowrap;
                      margin-bottom: -43px;
                  }
                  img {
                      width: 6px;
                      height: 9px;
                      margin-left: 10px;
                  }
              }  
            }
        }
        tbody {
            tr {
                border-top: unset!important;
                border-bottom: unset!important;
                td {
                    border-top: 0;
                    border-bottom: 4px solid white !important;
                    text-align: left;
                    font-family: 'Open Sans', sans-serif;
                    font-weight: 400;
                    line-height: 25px;
                    font-size: 14px;
                    letter-spacing: 0;
                    color: #424242;
                    text-transform: capitalize;
                    opacity: 1;
                }
            }
        }
    }

    #orderStatusTable_wrapper {
        #orderStatusTable_length {
            position: absolute;
            right: 0;
            bottom: 0;
            font-weight: 700;
            font-size: 14px;
            line-height: 28px;
            font-family: "Open Sans", sans-serif;
            color: #0097E0;
            label {
                margin-bottom: 0;
            }
            select {
                cursor: pointer;
                border: 0;
                color: #0097E0;
            }
        }
        #orderStatusTable_filter {
           
            input {
                width: 655px;
                height: 57px;
                padding-left: 17px;
                border: 1px solid #E6E6E6;
                opacity: 1;
                background: url("../images/search.svg");
                background-repeat: no-repeat;
                background-position: 97% 50%;
            }
            input[type="search"]::-webkit-search-decoration,
            input[type="search"]::-webkit-search-cancel-button,
            input[type="search"]::-webkit-search-results-button,
            input[type="search"]::-webkit-search-results-decoration { display: none; }
        }
        .dataTables_info {
            position: absolute;
            bottom: 0;
            right: 100px;
            font-size: 14px;
            line-height: 28px;
            font-family: "Open Sans", sans-serif;
            color: #424242;
            margin-right: 30px;
        }
        #orderStatusTable_paginate {
            max-width: 1334px;
            margin-top: 36px;
            margin-bottom: 126px;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            justify-content: center;
            .paginate_button.first {
                cursor: pointer;
                display: flex;
                align-items: center;
                &:focus {
                    border: 0;
                    outline: 0;
                }
                &::before {
                    content: "\F100";
                    font-family: FontAwesome;
                    opacity: 1;
                    text-decoration: none;
                    font-size: 14px;
                    white-space: nowrap;
                    padding: 0 16px;
                }
            }
            .paginate_button.previous {
                cursor: pointer;
                display: flex;
                align-items: center;
                &:focus {
                    border: 0;
                    outline: 0;
                }
                &::before {
                    content: "\f104";
                    font-family: FontAwesome;
                    opacity: 1;
                    text-decoration: none;
                    font-size: 14px;
                    white-space: nowrap;
                    padding: 0 16px;
                }
            }
            .paginate_button.next {
                cursor: pointer;
                display: flex;
                align-items: center;
                &:focus {
                    border: 0;
                    outline: 0;
                }
                &::before {
                    content: "\f105";
                    font-family: FontAwesome;
                    opacity: 1;
                    text-decoration: none;
                    font-size: 14px;
                    white-space: nowrap;
                    padding: 0 16px;
                }
            }
            .paginate_button.last {
                cursor: pointer;
                display: flex;
                align-items: center;
                &:focus {
                    border: 0;
                    outline: 0;
                }
                &::before {
                    content: "\f101";
                    font-family: FontAwesome;
                    opacity: 1;
                    text-decoration: none;
                    font-size: 14px;
                    white-space: nowrap;
                    padding: 0 16px;
                }
            }
            span {
                display: flex;
                .paginate_button {
                    cursor: pointer;
                    opacity: 1;
                    text-decoration: none;
                    white-space: nowrap;
                    padding: 0 16px;
                    height: 30px;
                    width: 30px;
                    display: flex;
                    align-items: center;
                    &:focus {
                        border: 0;
                        outline: 0;
                    }
                }
                .paginate_button.current {
                    cursor: pointer;
                    background-color: #0097E0;
                    padding: 0 16px;
                    color: white;
                    border-radius: 50px;
                    opacity: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-decoration: none;
                    text-align: left;
                    font-weight: 700;
                    white-space: nowrap;
                    height: 30px;
                    width: 30px;
                }
            }
            .paginate_button.disabled {
                &::before {
                    opacity: 0.5;
                }
            }
        }
    }
}

.sidebar-title {
    padding-top: 49px;
    text-align: left;
    font: normal normal normal 25px/19px Open Sans;
    letter-spacing: 0px;
    color: #424242;
    opacity: 1;
    font-size: 25px;
    margin-left: 40px;
    margin-top: 12px;

}

.current-link{
    color: #515151;
}

.nav-item a:hover{
    text-decoration: none;
}

.mycourses-section a:hover{
    text-decoration: none;
}

a.not-selected {
    color: var(--unnamed-color-515151);
    text-align: left;
    font: normal normal normal 14px/16px Open Sans;
    letter-spacing: 0px;
    color: #515151;
    opacity: 1;
    line-height: 25px;
}

.my-courses-btn{
    MARGIN-LEFT: 9PX;
}

.chevron-link{

    margin-right: 9PX;
    MARGIN-TOP: 8PX;
}

a.not-selected {
    margin-left: 13px;
}

a.current-link {
    margin-left: 13px;
}

.selected-item a{
    color: #ffffff;
}

.sidebar-list {
    list-style: none;
}


.sidebar-title {
    padding-top: 49px;
    text-align: left;
    font: normal normal normal 25px/19px Open Sans;
    letter-spacing: 0px;
    color: #424242;
    opacity: 1;
    font-size: 25px;
    margin-left: 40px;
    margin-top: 12px;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 10px;

}

.my-course-content-1 {
    width: 100%;
    height: 100%;
    background: #F7F8F9 0% 0% no-repeat padding-box;
}

.my-course-content-1.d-flex {
    justify-content: space-around;
    align-items: center;
    padding: 10px 5px;
    position: relative;
}

.my-courses-2-para {
    width: 50px;
    height: 50px;
    background: #1897E0 0% 0% no-repeat padding-box;
    opacity: 0.5;
    border-radius: 27px;
    color: #F7F8F9;
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 5px;
}

.my-courses-1-para {
    color: #515151;
    text-align: left;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 0;
    padding-right: 60px;
}

.my-courses-1 {
    margin-top: 60px;
    margin-bottom: 60px;
}

.my-courses-1  .col-lg-3 {
    max-width: 240px;
}


h3.offset-1.my-courses-favorite-title-1 {
    margin-left: 166px;
    margin-top: 30px;
    margin-bottom: 30px;
    color: #424242;
    text-align: left;
    letter-spacing: 0px;
    color: #424242;
    opacity: 1;
}


.my-courses-favorite-title h3 {
    margin-top: 50px;
    color: #424242;
    text-align: left;
    letter-spacing: 0px;
    color: #424242;
    opacity: 1;
    font-family: 'Open Sans', sans-serif;
    margin-left: 19px;

}

.corsi__slider.corsi__slider-1.container {
    margin-bottom: 136px;
}

.has-search .form-control {
    padding-left: 2.375rem;
    height: 57px;
    border: 1px solid #E6E6E6;
    border-radius: 0px;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 3.5rem;
    text-align: center;
    pointer-events: none;
    color: #515151;
}

.elenco-completo-dropdown select {
    height: 57px;
    border: 1px solid #E6E6E6;
    border-radius: 0px;
}

.elenco-button {
    margin-top: 0px;
}

.complete-point {
    font-size: 9px;
}

.attesa {
    color: #FABB45;

}

.superato {
    color: #79BC74;

}

.non-superato {
    color: #EF6660;
}

.Complete-list-form {
    margin-top: 37px;
    margin-bottom: 18px;
    margin-left: 21px;
}

table#CompleteListTable {
    margin-bottom: 90px;
}


.hide-in-web{
    display: none;
}

.my-courses-mobile-title{
    // display: none;
}

.order-status{
    display: none;
}

.order-mobile{
    display: none;
}

@media (max-width: 1600px) {
    .order-status-pagination-content .result {
        margin-right: 15px;
    }
    .order-status-title {
        margin-left: 0;
    }
    .order-status-pagination-content {
        #orderStatusTable_wrapper {
            #orderStatusTable_length {
                margin-left: 0;
            }
            #orderStatusTable_filter {
                margin-left: 0;
                margin-top: 75px;
            }
        }
        .download {
            left: 0;
        }
    } 

}

@media (max-width: 1300px) and (min-width: 992px) {
    .order-status-pagination-content #orderStatusTable_wrapper #orderStatusTable_filter input {
        width: 100%;
        max-width: 655px;
    }

    .order-status-pagination-content #orderStatusTable_wrapper #orderStatusTable_filter > label {
        width: 100%;
        max-width: calc(100% - 240px);
        display: block;
    }

    .order-status-pagination-content .download {
        margin-top: 18px;
    }
}

@media (max-width: 1199px) {
    .order-status-pagination-content {
        #orderStatusTable_wrapper {
            #orderStatusTable_length {
                bottom: -40px;
                right: 0;
            }
            .dataTables_info {
                bottom: -40px;
                right: 100px;
            }
        }
    }
}


@media (max-width: 992px) {
    .order-status-pagination-content {
        #orderStatusTable_wrapper {
            #orderStatusTable_length {
                right: 250px;
            }
            .dataTables_info {
                right: 340px;
            }
        }
        .download {
            left: 0;
        }
    }

    .order-status-pagination-content #orderStatusTable_wrapper #orderStatusTable_filter input {
        max-width: 655px;
        height: 57px;
        padding-left: 17px;
        border: 1px solid #E6E6E6;
        opacity: 1;
        background: url(../images/search.svg);
        background-repeat: no-repeat;
        background-position: 97% 50%;
        width: 100%;
    }

}

@media (max-width: 991px) {
    .my-courses-1 .col-lg-3 {
        max-width: 100%;
        margin-bottom: 10px;
    }

    .my-course-content-1.d-flex {
        min-height: 70px;
    }
}

@media (max-width: 768px) {
    .order-status-pagination-content table tr td.first-td {
        display: block;
    }
    .order-status-pagination-content .result {
        bottom: 70px;
        right: 0;
        left: 0;
        margin: 0;
        display: flex;
        justify-content: center;
    }

    .order-status-pagination-content {
        #orderStatusTable_wrapper {
            #orderStatusTable_length {
                margin-left: 0;
                right: 162px;
            }
            .dataTables_info {
                right: 243px;
            }
            #orderStatusTable_filter {
                margin-left: 0;
                input {
                    width: 350px;
                }
            }
            
        }
    }
}

@media (max-width: 991px) and (min-width: 576px) {
    .order-status-pagination-content #orderStatusTable_wrapper #orderStatusTable_filter > label {
        width: 100%;
        display: block;
    }

    .order-status-pagination-content > .dt-buttons {
        display: block;
        width: 100%;
        float: left;
    }

    .order-status-pagination-content > .dt-buttons .download.float-right {
        float: left !important;
    }
}

@media (max-width: 575px) {

    .content-my-courses{
        .dt-buttons{
            .stampa__button{
                display: none;
            }
        }
    }

    div#orderStatusTable_info {
        display: none;
    }

    label {
        display: none;
    }

    .download {
        // display: none !important;
    }

    label {
        display: block;
    }
   
    .order-status-pagination-content #orderStatusTable_wrapper #orderStatusTable_length {
       display: none;
    }
    .order-status-pagination-content {
        .download {
            display: none;
        }
    } 
    
    .order-status-title {
        display: none;
    }

    div#orderStatusTable_filter {
        text-align: center;
    }

    .order-status-pagination-content #orderStatusTable_wrapper #orderStatusTable_filter {
        margin-top: 93px;
        margin-bottom: 37px;
    }

    .download-mobile{
        width: 221px;
        height: 55px;
        border: 2px solid #0097E0;
        opacity: 1;
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        font-size: 14px;
        line-height: 28px;
        letter-spacing: 0px;
        color: #0097E0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        top: 4px;
        right: 92px;
        position: absolute;
        cursor: pointer;
    }


    .download-button-mobile {
        border: 2px solid #0097E0;
        opacity: 1;
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        font-size: 14px;
        line-height: 28px;
        color: #0097E0;
        border-radius: 0;
        padding: 15px 30px;
        margin: 50px auto;
        margin-top: 0;    
        width: 100%;
        max-width: 302px;
    }

    .order-status{
        display: flex ;
    }

   
    div#orderStatusTable_wrapper {
        display: none;
    }

  
    .mobile-search{
        margin-top: 37px;
    }

    .result.result-1.rapid_news-result {
        margin-right: 20px;
        margin-left: 20px;
        margin-top: 16px !important;
    }
    .order-name{
        color: #0097E0 !important;
        font-size: 14px;
        font-family: "Open Sans", sans-serif;
    }

    .order-info{
        font-weight: bold;
        font-size: 10px;
        font-family: "Open Sans", sans-serif;
    }

    .order-status-menu-info a{
        color: #ffffff;
        font-size: 14px;
        font-weight: 400;
    }
    .order-status-menu-info a:hover{
        text-decoration: none;
        color: #ffffff;
    }

    .order-line{
        color: #ffffff;
        margin-left: 5px;
        margin-right: 5px;
    
    }

    .order-mobile{
        display: block;
    }


    .hide-this-table{
        display: none;
    }

    .my-courses-favorite-title h3{
        display: none;
    }

    button.btn.btn-primary.elenco-button.stampa__button {
        // width: 100%;
        // margin-top: 10px;
        display: none;
    }


    .stampa__button-mobile{
        color: #0097e0;
        background-color: #ffffff;
        border: 2px solid #0097e0;
        border-radius: 0px;
        text-transform: uppercase;
        font-family: "Open Sans", sans-serif;
        font-weight: bold;
        margin-top: 10px;
        padding: 15px 35px;
        width: 90%;
    }

    .button-stamp {
        text-align: center;
        margin: 30px auto 60px;
    }

    .Complete-list-form {
        margin-left: 20px;
        margin-right: 20px;
    }

    .hide-table{
        display: none;
    }

    .my-course-content-1 {
        width: 100%;
    }

    a.slider-choose {
        font-size: 12px;
    }

    .slider-date {
        font-size: 12px;
    }

    .my-courses-mobile-title h3{
        font-size: 22px;
        font-weight: bold;
        margin-left: 32px;
        margin-top: 0;
        margin-bottom: 35px;
    }

    .paginatioon{
        display: none;
    }

    .hide-in-mobile{
        display: none;
    }
    .hide-in-web{
        display: block;
    }
    .order-reference-info{
        background-color: #F7F8F9 ;
        margin-top: 13px;
    margin-bottom: 38px;
    padding-top: 21px;
    padding-bottom: 21px;
    }
    .order-reference-div{
        justify-content: space-around;
    }

    .order-type{
       color: #515151;
       font-size: 12px;
       font-family: "Open Sans", sans-serif;
       line-height: 20px;
       text-align: left;
       font-weight: 600;

    }
    .order-type-description{
        color: #515151;
        font-size: 12px;
        font-family: "Open Sans", sans-serif;
        line-height: 20px;
        text-align: right;
        
    }

    hr.order-reference-hr {
        max-width: 272px;
        margin-top: 10px;
        width: 100%;
    }
    .d-flex.order-reference-div.order-header {
        margin-bottom: 25px;
    }

    .back-this span a{
        color:#515151;
        font-size: 14px;
        font-family: "Open Sans", sans-serif;
        

    }

    .back-this{
        margin-top: 20px;
        margin-left: 10px;
    }

    .modal-info-1{
        border-bottom: 1px solid #E6E6E6;
        padding: 10px 0;
    }
    .modal-partecipant{
        border-bottom: 1px solid #E6E6E6;
    }

    .modal-header {
     border-bottom: none !important ;
        
    }
    .modal-title{
        font-family: "Open Sans", sans-serif;
        font-weight: bold;
        font-size: 20px;
        color: #0097E0;
        line-height: 30px;

    }

    .modal-partecipant h5{
        font-size: 14px;
        font-family: "Open Sans", sans-serif;
        color: #000000;
        margin-top: 23px;
    }
    .modal-span-1{
        font-size: 12px;
        font-family: "Open Sans", sans-serif;
        color: #515151;
      

    }

    .modal-span-2{
        color: #0097E0;
        font-size:12px;
        font-weight: 600;
        font-family: "Open Sans", sans-serif;
    }

    .modal-span-3, .modale-addr, .modale-duration, .modal-users, .location{
        font-size: 12px;
        font-family: "Open Sans", sans-serif;
        color: #515151;
    }

    .modal-line{    
    padding: 0 5px 0 5px;
    }

    .modal-users{
    padding: 11px 17px 0;
    }

    .modal-user-list{
        padding-left: 1.6rem;
        padding-right: 1.6rem;
    }

    .modal-header .close { 
        padding-top: 21px !important;
        color: black;

    }
    .my-courses-1 {
        margin-bottom: 40px;
    }
}

@media (max-width: 414px) {

.my-course-content-1 {
    width: 100%;
    height: 55px;
    background: #F7F8F9 0% 0% no-repeat padding-box;
}

.my-courses-1 {
    margin-left: 20px;
    margin-right: 20px;
}

// .my-courses-1-para {
//     margin-bottom: -32px;
//     top: 1032px;
//     left: 455px;
//     width: 96px;
//     height: 41px;
//     color: #515151;
//     text-align: left;
//     font-family: "Open Sans", sans-serif;
//     letter-spacing: 0px;
//     opacity: 1;
//     font-size: 12px;
//     margin-top: 6px !important;
// }

.my-courses-1-para {
    color: #515151;
    text-align: left;
    font-family: "Open Sans", sans-serif;
    letter-spacing: 0px;
    opacity: 1;
    font-size: 12px;
    display: block;
    width: 100%;
}


// .my-courses-2-para {
//     top: 1028px;
//     left: 871px;
//     width: 39px;
//     height: 39px;
//     background: #1897E0 0% 0% no-repeat padding-box;
//     opacity: 0.5;
//     border-radius: 27px;
//     padding-left: 15px;
//     padding-top: 6px;
//     color: #F7F8F9;
//     margin-top: 9px;
// }

.number-2{
    padding-left: 10px !important;
    padding-top: 8px !important;

}

.my-courses-mobile-title{
    display: block;
}

tr{
    height: 57px;
}




}