$border_color: #E6E6E6;
$bold_font: Bold Open Sans;
$regular_font: Regular Open Sans;
$semibold: Semibold Open Sans;
$color_title: #424242;
$color_text: #8E8E8E;

// .register {
//     .register__banner {
//         position: relative;
//         display: inline-block;
//     }
//     .register__banner--titles {
//         position: absolute;
//         margin: 0;
//         z-index: 1;
//         top: 35%;
//         left: 35%;
//         text-align: center;
//     }
// }


.register__banner {
    position: relative;
    background-image: url(../assets/img/prodotti.svg);
    padding: 70px 15px;
    background-color: #006393;

    .register__banner--titles{

        .register__title,
        .register__subtitle {
            text-align: center;
            font-family: 'Open Sans', sans-serif;
            color: #FFFFFF;
        }

        .register__title {
            font-size: 32px;
            line-height: 43px;
            font-weight: bold;
        }

        .register__subtitle {
            font-size: 18px;
            line-height: 24px;
        }
    }
}


    .register__inputs {
        max-width: 800px; 
        width: 100%; 
        margin: auto; 
       
    }
    p {
        text-align: left;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0px;
        color: $color_title;
        opacity: 1;
        margin-top: 20px;
    }
    .register__input--validation {
        text-align: left;
        font-weight: 600px;
        font-family: 'Open Sans', sans-serif;
        font-size: 12px;
        color: $color_text;
    }
    .register__input--section .form-control {
        width: 100%;
        height: 57px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #E6E6E6;
        // margin: 0 0 20px 0;
        border-radius: 0;
    }
    
    .register__input--section .form-control option{
        color: $color_text; 
    }
    .register__input--section .form-control::placeholder {
        // width: 108px;
        font-weight: 700px;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        color: $color_text;
        line-height: 19px;
        
    }
    .register__input--container {
        position:relative;

        img {
            position:absolute;
            left: 28px;
            top: 20px;
            width:18px;
            height:18px;
            right: 28px;
        }

        select.form-control {
           color:$color_text ;
        }
    }
    .register__inputs--privacy {
        max-width: 794px;
        width: 100%;
        height: 136px;
        margin: auto;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #E6E6E6;
        overflow-y: scroll; 
        h3 {
            line-height: 19px;
            max-width: 748px;
            width: 100%;
            text-align: left;
            font-family: 'Open Sans', sans-serif;
            font-weight: bold;
            font-size: 14px;
            color: #000000;
            padding: 7px 46px;
        }  
        p {
            line-height: 19px;
            text-align: left;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            color: #000000;
            padding: 0 46px
        }
        a {
            text-decoration: none;
            color: #0097E0;
            font-size: 14px;
            font-weight: bold;
            font-family: 'Open Sans', sans-serif;
        }
    }
    .register__inputs--privacy-2 {
        margin-top: 20px;
    }
    .register__inputs--privacy::-webkit-scrollbar {
        width: 21px;
    }
    .register__inputs--privacy::-webkit-scrollbar-button {
        display: none;
    }
    .register__inputs--privacy::-webkit-scrollbar-thumb {
        max-width: 10px;
        width: 100%;
        margin: auto;
        height: 25px;
        transform: matrix(0, -1, 1, 0, 0, 0);
        border-radius: 10px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000017;
    }
    .register__inputs--privacy::-webkit-scrollbar-track {
        background: #F1F3F4 0% 0% no-repeat padding-box;
        width: 21px;
        transform: matrix(0, -1, 1, 0, 0, 0);
    }
    .register__inputs--check {
        display: inline-flex;
        margin-top: 40px;

        label {
            padding-left: 20px;
            height: 38px;
            max-width: 658px;
            width: 100%;
            text-align: left;
            font-family: 'Open Sans', sans-serif;
            font-size: 16px;
            line-height: 22px;
            text-transform: uppercase;
            font-weight: bold;
            margin-top: -2px;
            .si-no-paragraph {
                font-family: 'Open Sans', sans-serif;
                font-size: 12px;
                line-height: 17px;
                text-transform: none;
            }
        }
    }
    input[type=radio] {
        width: 25px;
        height: 25px;
    }

    hr {
        max-width: 803px;
        width: 100%;
        border: 1px solid #E6E6E6;
        margin-top: 44px;
    }

    .register_button{
        text-align:right;
    }
    
    .register__inputs--button {
        width: 211px;
        height: 55px;
        background: #0097E0;
        border: 2px solid #0097E0;
        border-radius: unset;
        font-size: 14px;
        line-height: 19px;
        font-weight: bold;
        font-family: 'Open Sans', sans-serif;
        color: #FFFFFF;
        text-transform: uppercase;
        margin: 15px 0;
    }
        
    .personal-detail-field{
        margin-bottom: 20px;
    }



.left-inner-addon {
    position: relative;
}

.left-inner-addon input {
    padding: 20px 30px;
    font-weight: 500;
    font-size: 18px;
    border-radius: 0;
    line-height: 22px;
    background-color: #fbfbfb;
    margin-bottom: 10px;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    border: 3px solid #000;
}

.left-inner-addon textarea {
    padding: 10px 30px;
    color: #3c3c3c;
    font-weight: 500;
    font-size: 18px;
    border-radius: 0;
    line-height: 22px;
    background-color: #fbfbfb;
    margin-bottom: 10px;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    border: 3px solid #000;
}
.left-inner-addon i {
    position: absolute;
    padding: 20px 8px;
    pointer-events: none;
    font-size: 17px;
    color: #8E8E8E;
    margin-left: 3px;
}

.register__banner-paragraph {
    text-align: center;
    color: #ffffff;
}

.contact-section-icon{
    font-size: 18px;
    margin-top: 23px;
    margin-right: 8px;
    color: #8E8E8E;
}

.thankyou-link {
    font: normal normal bold 14px/58px "Open Sans";
    color: #0097E0;
    text-transform: uppercase;
    margin-bottom: 50px;
    font-size: 14px;
}


.mycourses-section{   
    display: grid;
    margin-top: 11px;
}

hr.sidemenu-hr {
    margin-top: 21px;
    margin-bottom: 7px;
}


.mobile-profile-para{
    display: block;
}
p.mobile-profile-para {
    padding: 27px;
    margin-top: 22px;
    font-size: 14px;
}

.my_profile--inputs {
    padding-bottom: 20px;
}
.color-this{
    color: #0097E0;
}


@media (max-width: 767px) { 
    .register__banner {

        padding-top: 25px;
        padding-bottom: 35px;


        .register__banner--titles{


            .register__title {
                font-size: 22px;
                line-height: 26px;
            }

            .register__subtitle {
                font-size: 14px;
                line-height: 21px;
            }
        }
    }
}


@media (max-width: 640px) { 
    .register_button {
        text-align:center !important;
    }
    
    .register__inputs .register__inputs--privacy p {
        padding: 0;
        word-break: break-all;
    }

    .register__inputs .register__inputs--privacy h3 {
        padding: 0px;
    }
}

 @media (max-width: 576px) { 
     .field--not-empty{
        i{
            padding-top: 30px;
        }
     }
     .continue-button-register{
         text-align: center;
         .float-right{
            float: none !important;
         }
     }
    hr {        
        width: 100%;
        max-width: 302px;
        margin-top: 30px;
        margin-bottom: 5px;
    }
    
    .mobile-profile-para{
        display: block;
    }

    p.mobile-profile-para {
        padding: 27px;
        margin-top: 22px;
    }

    .my_profile--inputs {
        padding-bottom: 20px;
    }
    .color-this{
        color: #0097E0;
    }
    .register__inputs--privacy {
        margin-right: 20px;
        margin-left: 20px;
        .privacy_description{
            font-weight: normal;
        }
    }

    .register-paragraph {
        display: block;
        .privacy_importante{
            font-weight: normal;
        }
    }

    .register__inputs--check label {
        margin-bottom: 23px;
    }

    .si-no-paragraph{
        font-weight: 400;
    }

    .register_button{
        width: 100%;
        max-width: 302px;
        margin: auto;
    }
    
 }


/* float placeholder */

.float-placeholder {
    border-radius: 0px;;
    position: relative; 
    margin-bottom: 25px;
    height: 57px;
    line-height: 50px;
    border: 1px solid #E6E6E6;

}

.float-placeholder-label,
.float-placeholder-input {
    text-indent: 10px;
    transition: font-size 160ms;
    transition: line-height 160ms;
}

.float-placeholder-label {
    position: absolute;
    top: 0;
    left: 0;
    height: inherit;
    line-height: inherit;
    color: #8E8E8E;
    font-size: 14px;
    font-weight: 500;
    font-family: "Open Sans", sans-serif;
}

.float-placeholder-input {
    position: absolute;
    bottom: 0;
    left: 0;

    background: transparent;
    width: 100%;
    border: 0;

    height: 40px;
    line-height: 40px;
}

.float-placeholder-input::-webkit-input-placeholder {
    color: transparent;
}

.float-placeholder-input:-webkit-autofill {
    background: #fff !important;
}

.float-placeholder-input:focus {
    outline: 0;
}

.is-focused {
    border: 1px solid #E6E6E6;
}

.is-floating .float-placeholder-input {
    height: 50px;
    line-height: 50px;
}

.is-floating+.float-placeholder-label {
    color: #999;
    font-size: 12px;
    line-height: 20px;
    height: 20px
}