.coursiCalendar {
    background: #F7FCFD;
    .navbar {
        min-height: 46px;
        opacity: 1;
        .nav-item {
            color: $blueDefault;
            font-size: 12px;
            line-height: 22px;
            font-family: 'Open Sans', sans-serif;
            font-weight: 700;
            letter-spacing: 0px;
            cursor: pointer;
            padding: 0;

            &:after {
                content: ' ';
                color: #424242;
                margin-left: 8px;
                margin-right: 8px;
                border-right: 1px solid #666666;
            }

            &:last-child {
                text-align: left;
                color: #666666;
                font-weight: 400;
                &:after {
                    content: none;
                }
            }
        }
    }
}
.corsiCalendar-header {
    opacity: 1;
    margin: auto;
    h1 {
        color: white;
        text-align: center;
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        font-size: 32px;
        line-height: 45px;
        letter-spacing: 0px;
        opacity: 1;
        padding-top: 74px;
        margin-bottom: 26px;
    }
    .holder-search-toggle {
        position: relative;
        max-width: 1180px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        .corsiCalendar-header__search-input {
            max-width: 796px;
            width: 100%;
            position: relative;
            margin-right: 45px;
            input {
                height: 56px;
                background: #F7FCFD 0% 0% no-repeat padding-box;
                width: 100%;
                padding-left: 20px;
                text-align: left;
                color: #8E8E8E;
                outline: none;
                border: none;
                &::placeholder {
                    text-align: left;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 28px;
                    font-family: 'Open Sans', sans-serif;
                    color: #8E8E8E;
                }
            }
            img {
                position: absolute;
                right: 15px;
                top: 13px;
                width: 25px;
                height: 25px;
                cursor: pointer;
            }
        }

        .holder-toggle-btn {
            position: absolute;
            right: 0;
            cursor: pointer;
            display: flex;
            flex-flow: row-reverse;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            font-weight: 600;
            line-height: 28px;
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;
            .switchery {
                width: 51px;
                height: 25px;
                background-color: #A9CFE2!important;
                border-color: #A9CFE2!important;
                border-radius: 50px;
                opacity: 1;
                margin-right: 10px;
                small {
                    width: 22px;
                    height: 23px;
                    background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255);
                    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
                    border-radius: 50px;
                    opacity: 1;
                }
            }
            input:checked + .switchery{
                background-color: #60C05F!important;
                border-color: #60C05F!important;
            }
        }
    }
}
.corsiCalendar-header-background {
    background-image: url('../assets/img/prodotti.svg');
    min-height: 320px;
    opacity: 1;
    width: 100%;
    background-size: cover;
    // background-repeat: no-repeat;
}

.corsiCalendar-header__inputs {
    display: flex;
    justify-content: center;
    margin-top: 14px;
}
.corsiCalendar-header__first-input {
    max-width: 380px;
    width: 100%;
    position: relative;
    margin-left: 8px;
    margin-right: 8px;
    padding-right: 18px;
    background: #F7FCFD 0% 0% no-repeat padding-box;
    select {
        box-shadow: none!important;
        height: 57px;
        background: #F7FCFD 0% 0% no-repeat padding-box;
        opacity: 1;
        width: 100%;
        padding-left: 20px;
        text-align: left;
        letter-spacing: 0;
        color: #8E8E8E;
        outline: none;
        border: none;
        font-size: 14px;
        line-height: 28px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        border-radius: 0;
        &:focus {
            background: #F7FCFD 0% 0% no-repeat padding-box;
        }
    }
}
.corsiCalendar-header__second-input {
    max-width: 380px;
    width: 100%;
    position: relative;
    margin-left: 8px;
    margin-right: 8px;
    padding-right: 18px;
    background: #F7FCFD 0% 0% no-repeat padding-box;
    select {
        box-shadow: none!important;
        height: 57px;
        background: #F7FCFD 0% 0% no-repeat padding-box;
        opacity: 1;
        width: 100%;
        padding-left: 20px;
        text-align: left;
        letter-spacing: 0;
        color: #8E8E8E;
        outline: none;
        border: none;
        font-size: 14px;
        line-height: 28px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        border-radius: 0;
        &:focus {
            background: #F7FCFD 0% 0% no-repeat padding-box;
        }
    }
}
.corsiCalendar-header__third-input {
    max-width: 380px;
    width: 100%;
    position: relative;
    margin-left: 8px;
    background: #F7FCFD 0% 0% no-repeat padding-box;
    img {
        position: absolute;
        z-index: 99999;
        top: 0;
        left: 20px;
        bottom: 0;
        margin: auto;
        cursor: pointer;
    }
    input {
        outline: 0;
        position: relative;
        max-width: 579px;
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 0;
        background-color: #F7FCFD;
        padding-left: 50px;
        color: rgba(1, 23, 48, 0.43);
        font-family: "Source Sans Pro", sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 25px;
        text-transform: uppercase;
        &:focus {
            background: #F7FCFD 0% 0% no-repeat padding-box;
        }
    }
}
.corsiCalendar-header__data-input {
    display: none;
    max-width: 380px;
    width: 100%;
    position: relative;
    margin-left: 8px;
    margin-right: 8px;
    padding-right: 18px;
    background: #F7FCFD 0% 0% no-repeat padding-box;
    select {
        box-shadow: none!important;
        height: 57px;
        background: #F7FCFD 0% 0% no-repeat padding-box;
        opacity: 1;
        width: 100%;
        padding-left: 20px;
        text-align: left;
        letter-spacing: 0;
        color: #8E8E8E;
        outline: none;
        border: none;
        font-size: 14px;
        line-height: 28px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        border-radius: 0;
        &:focus {
            background: #F7FCFD 0% 0% no-repeat padding-box;
        }
    }
}
.corsiCalendar-header__toggle-mob {
    display: none;
    max-width: 380px;
    width: 100%;
    position: relative;
    margin-left: 8px;
    .toggle-btn {
        display: flex;
        cursor: pointer;
        flex-flow: row-reverse;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        .switchery {
            width: 51px;
            height: 25px;
            background-color: #A9CFE2!important;
            border-color: #A9CFE2!important;
            border-radius: 50px;
            opacity: 1;
            margin-right: 10px;
            small {
                width: 22px;
                height: 23px;
                background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255);
                box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
                border-radius: 50px;
                opacity: 1;
            }
        }
        input:checked + .switchery{
            background-color: #60C05F!important;
            border-color: #60C05F!important;
        }
    }
}

.result {
    margin-top: 51px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
    margin-left: auto;
    span {
        text-align: left;
        font-size: 14px;
        line-height: 28px;
        font-family: "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #424242;
        margin-right: 21px;
        padding-top: 5px;
        b {
            padding-right: 4px;
        }
    }
    span.text {
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        line-height: 28px;
        letter-spacing: 0;
        color: #424242;
        margin-right: 2px;
    }
    .dropdown {
        padding-top: 4px;
        padding-right: 15px;
        .dropdown-toggle {
            z-index: 9999;
            position: relative;
            text-align: left;
            font-weight: 700;
            font-size: 14px;
            line-height: 28px;
            font-family: "Open Sans", sans-serif;
            letter-spacing: 0px;
            color: #0097E0;
            opacity: 1;
            cursor: pointer;
        }
    }
    .dropdown-menu {
        top: -40px !important;
        text-align: left;
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        line-height: 28px;
        font-weight: bold;
        letter-spacing: 0px;
        color: #0097E0;
        opacity: 1;
        min-width: 222px;
        padding-top: 65px;
        left: 20px !important;
        border: none;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 36px #0725361A;
        a {
            text-align: left;
            font-family: "Open Sans", sans-serif;
            font-size: 14px;
            font-weight: 600;
            line-height: 28px;
            letter-spacing: 0;
            opacity: 1;
            color: #424242;
            height: 40px;
            padding-top: 10px;
            &:hover {
                color: #0097E0;
            }
        }
    }
}
.filterResult {
    margin-top: 51px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
    img {
        margin-right: 7px;
        z-index: 99999;
    }
    .dropdown {
        padding-top: 4px;
        padding-right: 15px;
        .dropdown-toggle {
            z-index: 9999;
            position: relative;
            text-align: left;
            font-weight: 700;
            font-size: 14px;
            line-height: 28px;
            font-family: "Open Sans", sans-serif;
            letter-spacing: 0px;
            color: #0097E0;
            opacity: 1;
            cursor: pointer;
            &:after {
                display: none!important;
            }
        }
    }
    .dropdown-menu {
        top: -40px !important;
        text-align: left;
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        line-height: 28px;
        font-weight: bold;
        letter-spacing: 0px;
        color: #0097E0;
        opacity: 1;
        min-width: 222px;
        padding-top: 65px;
        left: -52px !important;
        border: none;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 36px #0725361A;
        padding-left: 27px;
        padding-right: 27px;
        a {
            text-align: left;
            font-family: "Open Sans", sans-serif;
            font-size: 14px;
            font-weight: 600;
            line-height: 28px;
            letter-spacing: 0;
            opacity: 1;
            color: #424242;
            height: 53px;
            &:hover {
                color: #0097E0;
            }
        }
        h5 {
            margin-top: 26px;
            margin-bottom: 10px;
            text-align: left;
            letter-spacing: 0;
            color: #424242;
            opacity: 1;
            font-family: 'Open Sans', sans-serif;
            font-size: 20px;
            line-height: 30px;
            font-weight: 600;
        }
        .holder-date {
            width: 388px;
            height: 57px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 1px solid #E6E6E6;
            opacity: 1;
            display: flex;
            align-items: center;
            margin-bottom: 31px;
            img {
                width: 27px;
                height: 27px;
                margin-left: 7px;
            }
            .data {
                padding-left: 10px;
                text-align: left;
                font-size: 14px;
                line-height: 18px;
                font-weight: 600;
                font-family: 'Open Sans', sans-serif;
                letter-spacing: 0;
                color: #515151;
                opacity: 1;
                margin-top: unset;
                span {
                    text-align: left;
                    font-size: 11px;
                    line-height: 17px;
                    font-weight: 600;
                    font-family: 'Open Sans', sans-serif;
                    letter-spacing: 0px;
                    color: #8E8E8E;
                    opacity: 1;
                }
            }
        }
        .holder-toggle-btn {
            width: 388px;
            height: 57px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 1px solid #E6E6E6;
            border-right: 0;
            border-left: 0;
            opacity: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 31px;
            .switch {
                float: right;
                position: relative;
                display: inline-block;
                width: 51px;
                height: 27px;
                background: #F3F5F6 0% 0% no-repeat padding-box;
                border-radius: 50px;
                opacity: 1;
                margin-bottom: 0;
                input {
                    opacity: 0;
                    width: 0;
                    height: 0;
                }
                span {
                    padding-left: 2px;
                }
            }
        }
        .btn-primary {
            float: right;
            margin-bottom: 34px;
            font-family: 'Open Sans', sans-serif;
            font-weight: 700;
            border-radius: 0;
            font-size: 14px;
        }
    }
}

.holder-box__calendar {
    .box-skyCommercial {
        width: 100%;
        min-height: 190px;
        background: #F8F9FA 0% 0% no-repeat padding-box;
        opacity: 1;
        display: flex;
        margin-bottom: 21px;
        .holder-img {
            display: flex;
            .data {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-flow: column;
                text-align: center;
                color: #424242;
                text-transform: capitalize;
                opacity: 1;
                font-weight: 400;
                font-family: 'Open Sans', sans-serif;
                font-size: 20px;
                line-height: 18px;
                letter-spacing: 0;
                width: 190px;
                height: 190px;
                span {
                    font-size: 34px;
                    color: #424242;
                }
            }
            .image {
                height: 190px;
                background: transparent linear-gradient(180deg, #D3EFFF 0%, #35B2FA 100%) 0% 0% no-repeat padding-box;
                opacity: 1;
                position: relative;
                width: 190px;
                img {
                    position: absolute;
                    margin: auto;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                }
            }
        }
        .data {
            margin-left: 42px;
            h5 {
                text-align: left;
                letter-spacing: 0px;
                font-size: 20px;
                line-height: 30px;
                font-family: 'Open Sans', sans-serif;
                color: #0097E0;
                text-transform: capitalize;
                opacity: 1;
                margin-top: 28px;
                margin-bottom: 7px;
            }
            .location {
                text-align: left;
                letter-spacing: 0px;
                font-family: 'Open Sans', sans-serif;
                font-size: 15px;
                line-height: 16px;
                font-weight: 600;
                color: #515151;
                text-transform: capitalize;
                opacity: 1;
                margin-bottom: 9px;
                img {
                    margin-right: 7px;
                }
            }
            .text {
                max-width: 586px;
                text-align: left;
                letter-spacing: 0px;
                font-size: 12px;
                line-height: 18px;
                font-family: 'Open Sans', sans-serif;
                color: #515151;
                opacity: 1;
                margin-bottom: 15px;
            }
            .time {
                text-align: center;
                font-size: 12px;
                line-height: 23px;
                font-weight: 400;
                font-family: 'Open Sans', sans-serif;
                letter-spacing: 0px;
                color: #515151;
                opacity: 1;
                display: flex;
                align-items: center;
                margin-right: 60px;
                img {
                    padding-right: 8px;
                }
                span {
                    padding-left: 8px;
                }
            }
            .id {
                text-align: center;
                font-size: 12px;
                line-height: 23px;
                font-weight: 400;
                font-family: 'Open Sans', sans-serif;
                letter-spacing: 0px;
                color: #515151;
                opacity: 1;
                display: flex;
                align-items: center;
                margin-right: 60px;
                div {
                    margin-right: 8px;
                    width: 15px;
                    height: 15px;
                    background: #8E8E8E 0% 0% no-repeat padding-box;
                    border: 1px solid #8E8E8E;
                    opacity: 1;
                    color: white;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                span {
                    padding-left: 8px;
                }
            }
            .internship {
                text-align: center;
                font-size: 12px;
                line-height: 23px;
                font-weight: 400;
                font-family: 'Open Sans', sans-serif;
                letter-spacing: 0px;
                color: #515151;
                opacity: 1;
                .holder-img {
                    display: inline;
                    width: 25px;
                    height: 20px;
                    position: relative;
                    padding-right: 8px;
                    .teacher-img {
                        width: 25px;
                        height: 20px;
                    }
                    .tools-img {
                        width: 14px;
                        height: 13px;
                        position: absolute;
                        bottom: 1px;
                        right: 10px;
                        background: white;
                        padding-left: 2px;
                        padding-top: 3px;
                    }
                }
            }
        }
        .placesAvailable {
            margin-left: auto;
            margin-right: 31px;
            .price {
                margin-top: 25px;
                text-align: right;
                font-weight: 600;
                font-size: 24px;
                font-family: 'Open Sans', sans-serif;
                letter-spacing: 0;
                color: #515151;
                .iva {
                    font-size: 14px;
                    font-weight: 600;
                }
            }
            .available {
                width: 154px;
                height: 28px;
                background: #4FC1DD 0% 0% no-repeat padding-box;
                border-radius: 19px;
                opacity: 1;
                text-align: left;
                font-family: 'Open Sans', sans-serif;
                font-weight: 700;
                font-size: 12px;
                line-height: 23px;
                letter-spacing: 0px;
                color: #FFFFFF;
                text-transform: lowercase;
                margin-top: 38px;
                margin-bottom: 14px;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    padding-right: 7px;
                }
            }
            .heart {
                width: 26px;
                height: 23px;
                opacity: 0.6;
                float: right;
            }
        }
    }
    .box-Certification {
        width: 100%;
        min-height: 190px;
        background: #F8F9FA 0% 0% no-repeat padding-box;
        opacity: 1;
        display: flex;
        margin-bottom: 21px;
        .holder-img {
            display: flex;
            .data {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-flow: column;
                text-align: center;
                color: #424242;
                text-transform: capitalize;
                opacity: 1;
                font-weight: 400;
                font-family: 'Open Sans', sans-serif;
                font-size: 20px;
                line-height: 18px;
                letter-spacing: 0;
                width: 190px;
                height: 190px;
                span {
                    font-size: 34px;
                    color: #424242;
                }
            }
            .image {
                height: 190px;
                background: transparent linear-gradient(180deg, #D3EFFF 0%, #35B2FA 100%) 0% 0% no-repeat padding-box;
                opacity: 1;
                position: relative;
                width: 190px;
                img {
                    position: absolute;
                    margin: auto;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                }
            }
        }
        .data {
            margin-left: 42px;
            h5 {
                text-align: left;
                letter-spacing: 0px;
                font-size: 20px;
                line-height: 30px;
                font-family: 'Open Sans', sans-serif;
                color: #0097E0;
                text-transform: capitalize;
                opacity: 1;
                margin-top: 28px;
                margin-bottom: 7px;
            }
            .location {
                text-align: left;
                letter-spacing: 0px;
                font-family: 'Open Sans', sans-serif;
                font-size: 15px;
                line-height: 16px;
                font-weight: 600;
                color: #515151;
                text-transform: capitalize;
                opacity: 1;
                margin-bottom: 9px;
                img {
                    margin-right: 7px;
                }
            }
            .text {
                max-width: 586px;
                text-align: left;
                letter-spacing: 0px;
                font-size: 12px;
                line-height: 18px;
                font-family: 'Open Sans', sans-serif;
                color: #515151;
                opacity: 1;
                margin-bottom: 15px;
            }
            .time {
                text-align: center;
                font-size: 12px;
                line-height: 23px;
                font-weight: 400;
                font-family: 'Open Sans', sans-serif;
                letter-spacing: 0px;
                color: #515151;
                opacity: 1;
                display: flex;
                align-items: center;
                margin-right: 60px;
                img {
                    padding-right: 8px;
                }
                span {
                    padding-left: 8px;
                }
            }
            .id {
                text-align: center;
                font-size: 12px;
                line-height: 23px;
                font-weight: 400;
                font-family: 'Open Sans', sans-serif;
                letter-spacing: 0px;
                color: #515151;
                opacity: 1;
                display: flex;
                align-items: center;
                margin-right: 60px;
                div {
                    margin-right: 8px;
                    width: 15px;
                    height: 15px;
                    background: #8E8E8E 0% 0% no-repeat padding-box;
                    border: 1px solid #8E8E8E;
                    opacity: 1;
                    color: white;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                span {
                    padding-left: 8px;
                }
            }
            .internship {
                text-align: center;
                font-size: 12px;
                line-height: 23px;
                font-weight: 400;
                font-family: 'Open Sans', sans-serif;
                letter-spacing: 0px;
                color: #515151;
                opacity: 1;
                .holder-img {
                    display: inline;
                    width: 25px;
                    height: 20px;
                    position: relative;
                    padding-right: 8px;
                    .teacher-img {
                        width: 25px;
                        height: 20px;
                    }
                    .tools-img {
                        width: 14px;
                        height: 13px;
                        position: absolute;
                        bottom: 1px;
                        right: 10px;
                        background: white;
                        padding-left: 2px;
                        padding-top: 3px;
                    }
                }
            }
        }
        .placesAvailable {
            margin-left: auto;
            margin-right: 31px;
            .price {
                margin-top: 25px;
                text-align: right;
                font-weight: 600;
                font-size: 24px;
                font-family: 'Open Sans', sans-serif;
                letter-spacing: 0;
                color: #515151;
                .iva {
                    font-size: 14px;
                    font-weight: 600;
                }
            }
            .available {
                height: 28px;
                border-radius: 19px;
                opacity: 1;
                text-align: left;
                font-family: 'Open Sans', sans-serif;
                font-weight: 700;
                font-size: 12px;
                line-height: 23px;
                letter-spacing: 0px;
                color: #FFFFFF;
                text-transform: lowercase;
                margin-top: 38px;
                margin-bottom: 14px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 163px;
                background: #EBA661 0% 0% no-repeat padding-box;
                img {
                    padding-right: 7px;
                }
            }
            .heart {
                width: 26px;
                height: 23px;
                opacity: 0.6;
                float: right;
            }
        }
    }
    .box-airQuality {
        width: 100%;
        min-height: 190px;
        background: #F8F9FA 0% 0% no-repeat padding-box;
        opacity: 1;
        display: flex;
        margin-bottom: 21px;
        position: relative;
       .link-calendario-corso{
           position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;     
            z-index: 999;       
        }
    
        .holder-img {
            display: flex;
            .data {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-flow: column;
                text-align: center;
                color: #424242;
                text-transform: capitalize;
                opacity: 1;
                font-weight: 400;
                font-family: 'Open Sans', sans-serif;
                font-size: 20px;
                line-height: 18px;
                letter-spacing: 0;
                width: 190px;
                height: 190px;
                span {
                    font-size: 34px;
                    color: #424242;
                }
            }
            .image {
                height: 190px;
                background: transparent linear-gradient(180deg, #D3EFFF 0%, #35B2FA 100%) 0% 0% no-repeat padding-box;
                position: relative;
                width: 190px;
                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: transparent linear-gradient(180deg, rgba(211,239,255, .3) 0%, rgba(53,178,250, .6) 100%) 0% 0% no-repeat padding-box;
                    // background: transparent linear-gradient(180deg, rgba(14, 131, 188, 0) 0%, rgba(14, 131, 188, 0.37) 35%, rgba(14, 131, 188, 0.8) 70%, rgba(14, 131, 188, 0.93) 100%) 0% 0% no-repeat padding-box;
                }
                img {
                    position: absolute;
                    margin: auto;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .data {
            margin-left: 42px;
            h5 {
                text-align: left;
                font-size: 20px;
                line-height: 30px;
                font-family: 'Open Sans', sans-serif;
                color: #0097E0;
                text-transform: capitalize;
                margin-top: 28px;
                margin-bottom: 7px;
                
                a {
                    text-decoration: none;
                }
            }
            .location {
                text-align: left;
                letter-spacing: 0px;
                font-family: 'Open Sans', sans-serif;
                font-size: 15px;
                line-height: 16px;
                font-weight: 600;
                color: #515151;
                text-transform: capitalize;
                opacity: 1;
                margin-bottom: 9px;
                img {
                    margin-right: 7px;
                }
            }
            .text {
                max-width: 586px;
                text-align: left;
                letter-spacing: 0px;
                font-size: 12px;
                line-height: 18px;
                font-family: 'Open Sans', sans-serif;
                color: #515151;
                opacity: 1;
                margin-bottom: 15px;
            }
            .time {
                text-align: center;
                font-size: 12px;
                line-height: 23px;
                font-weight: 400;
                font-family: 'Open Sans', sans-serif;
                letter-spacing: 0px;
                color: #515151;
                opacity: 1;
                display: flex;
                align-items: center;
                margin-right: 60px;
                img {
                    padding-right: 8px;
                }
                span {
                    padding-left: 8px;
                }
            }
            .id {
                text-align: center;
                font-size: 12px;
                line-height: 23px;
                font-weight: 400;
                font-family: 'Open Sans', sans-serif;
                letter-spacing: 0px;
                color: #515151;
                opacity: 1;
                display: flex;
                align-items: center;
                margin-right: 60px;
                div {
                    margin-right: 8px;
                    width: 15px;
                    height: 15px;
                    background: #8E8E8E 0% 0% no-repeat padding-box;
                    border: 1px solid #8E8E8E;
                    opacity: 1;
                    color: white;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                span {
                    padding-left: 8px;
                }
            }
            .internship {
                text-align: center;
                font-size: 12px;
                line-height: 23px;
                font-weight: 400;
                font-family: 'Open Sans', sans-serif;
                color: #515151;
                display: inline-flex;
                align-items: center;
                .learn {
                    width: 22px;
                    height: 20px;
                    margin-right: 8px;
                }
            }
        }

        .placesAvailable {
            margin-left: auto;
            margin-right: 31px;
            .price {
                text-align: right;
                font-weight: 400;
                font-size: 24px;
                line-height: 26px;
                font-family: 'Open Sans', sans-serif;
                letter-spacing: 0px;
                color: #515151;
                opacity: 1;
                margin-top: 24px;
            }
            .available {
                background: #4FC1DD 0% 0% no-repeat padding-box;
                font-size: 12px;
                line-height: 23px;
                font-family: 'Open Sans', sans-serif;
                border-radius: 19px;
                color: #515151;
                margin-bottom: 15px;
                margin-top: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    padding-right: 8px;
                }
                span {
                    text-transform: none;
                }
            }

            .heart {
                width: 26px;
                height: 23px;
                opacity: 0.6;
                float: right;
            }
        }

        &.many-spots ,
        &.limited-spots {
            .placesAvailable {
                .available {
                    color: #fff;
                    font-weight: 700;
                    padding: 3px 15px;
                }
            }
        }

        &.limited-spots {
            .placesAvailable {
                .available {
                    background: #EBA661 0% 0% no-repeat padding-box;
                }
            }
        }
    }
    .loadOthers {
        width: 233px;
        height: 55px;
        border: 2px solid #0097E0;
        opacity: 1;
        text-align: center;
        font: Bold 14px/24px Open Sans;
        letter-spacing: 0px;
        color: #0097E0;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        line-height: 24px;
        font-weight: 700;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: 64px;
        margin-bottom: 122px;
        &:hover {
            color: white;
        }
    }
}


.corsi-accordion {
    .card-header {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0 !important;
    }
}

@media (max-width: 1600px) {
    .corsiCalendar-header {
        .holder-search-toggle {
            .corsiCalendar-header__search-input {
                max-width: 545px;
            }
        }
    }
    .holder-box__calendar {
        .box-skyCommercial {
            .holder-img {
                .data {
                    width: 180px;
                    //height: 100%;
                }
                .image {
                    width: 180px;
                    //height: 100%;
                }
            }
            .data {
                margin-left: 20px;
                max-width: 340px;
                margin-bottom: 8px;
                h5 {
                    margin-top: 12px;
                }
                .text {
                    margin-bottom: 10px;
                }
                .d-flex {
                    flex-flow: column;
                    .time, .id, .internship {
                        margin-right: auto;
                    }
                }
            }
        }
        .box-Certification {
            .holder-img {
                .data {
                    width: 180px;
                    //height: 100%;
                }
                .image {
                    width: 180px;
                    //height: 100%;
                }
            }
            .data {
                margin-left: 20px;
                max-width: 340px;
                margin-bottom: 8px;
                h5 {
                    margin-top: 12px;
                }
                .text {
                    margin-bottom: 10px;
                }
                .d-flex {
                    flex-flow: column;
                    .time, .id, .internship {
                        margin-right: auto;
                    }
                }
            }
        }
        .box-airQuality {
            .holder-img {
                .data {
                    width: 180px;
                    //height: 100%;
                }
                .image {
                    width: 180px;
                    //height: 100%;
                }
            }
            .data {
                margin-left: 20px;
                max-width: 340px;
                margin-bottom: 8px;
                h5 {
                    margin-top: 12px;
                }
                .text {
                    margin-bottom: 10px;
                }
                .d-flex {
                    flex-flow: column;
                    .time, 
                    .id, 
                    .internship {
                        margin-right: auto;
                    }
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .corsiCalendar-header-background {
        position: relative;
        min-height: 406px;
        h1 {
            margin-bottom: 10px;
            padding-top: 20px;
        }
        .holder-search-toggle {
            // position: unset;
            display: block;
            .corsiCalendar-header__search-input {
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 15px;
                max-width: 380px;
            }
            .holder-toggle-btn {
                position: relative;
                justify-content: flex-end;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 15px;
                max-width: 380px;

                //bottom: 13px;
                //left: 0;
                //right: 0;
                //max-width: 170px;
                //margin: auto;
            }
        }
        .corsiCalendar-header__inputs {
            flex-flow: column;
            margin-top: 0;
            .corsiCalendar-header__first-input {
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 15px;
            }
            .corsiCalendar-header__second-input {
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 15px;
            }
            .corsiCalendar-header__third-input {
                margin: auto;
                height: 57px;
                margin-bottom: 15px; 
            }

            .corsiCalendar-header__data-input {
                display: block;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 15px;
            }
            .corsiCalendar-header__toggle-mob {
                margin: auto;
                display: flex;
            }
        }
    }

    .holder-box__calendar {
        .box-skyCommercial {
            padding-top: 15px;
            padding-bottom: 15px;
            flex-flow: column;
            .holder-img {
                margin: auto;
                .data {
                    width: 190px;
                    height: 190px;
                }
                .image {
                    width: 190px;
                    height: 190px;
                }
            }
            .data {
                max-width: unset;
                margin-right: 20px;
                .d-flex {
                    flex-flow: unset;
                }
            }
            .placesAvailable {
                margin: auto;
                .price {
                    text-align: center;
                }
            }
        }
        .box-Certification {
            flex-flow: column;
            padding-top: 15px;
            padding-bottom: 15px;
            .holder-img {
                margin: auto;
                .data {
                    width: 190px;
                    height: 190px;
                }
                .image {
                    width: 190px;
                    height: 190px;
                }
            }
            .data {
                max-width: unset;
                margin-right: 20px;
                .d-flex {
                    flex-flow: unset;
                }
            }
            .placesAvailable {
                margin: auto;
                .price {
                    text-align: center;
                }
            }
        }
        .box-airQuality {
            padding-top: 15px;
            padding-bottom: 15px;
            flex-flow: column;
            .holder-img {
                margin: auto;
                .data {
                    width: 190px;
                    height: 190px;
                }
                .image {
                    width: 190px;
                    height: 190px;
                }
            }
            .data {
                max-width: unset;
                margin-right: 20px;
                .d-flex {
                    flex-flow: unset;
                }
            }
            .placesAvailable {
                margin: auto;
                .price {
                    text-align: center;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .holder-box__calendar {
        .box-skyCommercial {
            .holder-img {
                .data {
                    width: 135px;
                    height: 135px;
                    margin: 0;
                }
                .image {
                    width: 135px;
                    height: 135px;
                }
            }
            .data {
                .d-flex {
                    flex-flow: column;
                }
            }
        }
        .box-Certification {
            .holder-img {
                .data {
                    width: 135px;
                    height: 135px;
                    margin: 0;
                }
                .image {
                    width: 135px;
                    height: 135px;
                }
            }
            .data {
                .d-flex {
                    flex-flow: column;
                }
            }
        }
        .box-airQuality {
            .holder-img {
                .data {
                    width: 135px;
                    height: 135px;
                    margin: 0;
                }
                .image {
                    width: 135px;
                    height: 135px;
                }
            }
            .data {
                .d-flex {
                    flex-flow: column;
                }
            }
        }
    }
    .holder-result {
        flex-flow: column;
    }
    .filterResult {
        margin-top: 20px;
        justify-content: flex-end;
    }
    .result {
        
            justify-content: space-between;
            margin-left: 0;
            margin-bottom: 14px !important;
    
    }
}


// mobile part
    .see-more-accordion {
        background-color: #EDEFF0;
        width: 50px;
        height: 50px;
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none !important;

    }

    .accordion-mobile-date {
        font-size: 22px;
    }

    .accordion-mobile-title {
        font-size: 16px;
        color: #0097E0;
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        color: #424242;
    }

    .accordion-mobile-location {
        font-size: 12px;
        color: #515151;
        font-weight: 500;
        margin-top: 10px;
        margin-bottom: 0;
    }

    .accordion-mobile-paragraph {
        font-size: 12px;
        color: #666666;
    }

    .btn-accordion {
        background-color: #0097E0;
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 600;
        border-radius: 1px;
        width: 100%;
        margin-top: 22px;
        padding: 10px 15px;
    }


    .heart {
        margin-left: auto;
    }

    .available--styled {
        background: #4FC1DD 0% 0% no-repeat padding-box;
        border-radius: 19px;
        text-align: left;
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        font-size: 9px;
        line-height: 23px;
        color: #FFFFFF;
        padding: 7px 17px;
      
        margin-top: 15px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .carica-button {
        text-align: center;
        margin-top: 46px;
        margin-bottom: 57px;
    }

    .btn-outline-primary.loadOthers {
        max-width: 233px;
        height: 55px;
        width: 100%;
        border-radius: 0px;
        border: 2px solid;
        text-transform: uppercase;
        font-weight: bold;
    }

    .mobile-card-header{
        height: 161px !important;
    }

    .toggle-btn {
        margin-bottom: 28px;
    }
    .accordion-mobile-paragraph {
        margin-top: -25px;
    }
    .corsis-hr {
        margin-top: 6px;
    }


   
