
.software-result {
    display: none;
}

@media (max-width: 767px){
	.corsiCalendar-header-background h1 {
	    margin-bottom: 25px;
	    padding-top: 20px;
	}
	.software-div {
	    display: flex;
	}

	.software-download {
	    color: #0097E0;
	    font-size: 19px;
	    margin-left: 20;
	    margin-right: 10px;
	    margin-top: 11px;
	}

	.software-result {
	    display: flex;
	}
	
	.pagination-content .dataTables_length {
	    // display: none;
	}
}