
.btn-file {
    position: relative;
    overflow: hidden;
}
.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;   
    cursor: inherit;
    display: block;
}

.course-form__header {
    min-height: 300px;
    background: url('../assets/img/Group1.png') no-repeat padding-box;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #0297e0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 15px 30px;
}

.course-form__header__content .title {
    color: #fff;
    font-family: "Open Sans",sans-serif;
    font-weight: 700;
    font-size: 32px;
    line-height: 45px;
}

//  Thankyou page
.thank-you-section {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 80px;
}

.thank-you-section h3 {
    color: #424242;
    font-size: 32px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    margin-top: 40px;
}

.thank-you-section p {
    color: #424242;
    font-size: 18px;
    font-weight: normal;
    font-family: 'Open Sans', sans-serif;
}

.thank-you-section img {
    width: 100%;
    max-width: 549px;
}

.thank-you-section .return-btn{
    padding: 18px 38px;
    background: #0097E0;
    border: 2px solid #0097E0;
    border-radius: 0;
    font-size: 14px;
    font-weight: bold;
    font-family: "Open Sans", sans-serif;
    color: #FFFFFF;
    text-transform: uppercase;
    line-height: 19px;
    margin-top: 50px;
    margin-bottom: 15px;
}


.contact-form-paragraph-1{
    color: #424242;
    font-size: 14px;
}

.contact-form-paragraph-2{
    font-size: 12px;
    color: #666666;
}

.contact__form--button {
    background: #ffffff;
    border: 2px solid #0097E0;
    border-radius: 0;
    font-size: 14px;
    font-weight: bold;
    font-family: "Open Sans", sans-serif;
    color: #0097E0;
    text-transform: uppercase;
    line-height: 19px;
    margin-top: 15px;
    padding: 12px 18px;
}

#exampleFormControlTextarea1 {
    height: auto;
}

.hide-big{
    display: none;
}


@media (max-width: 767px) {
    .course-form__header {
        min-height: 100px;
    }

    .course-form__header__content .title {
        font-size: 22px;
        line-height: 28px;
    }

    .thank-you-section h3 {
        font-size: 14px;
        margin-bottom: 7px;
    }

    .thank-you-section p {
        margin-top: 0;
        font-size: 12px;
    }

    .thank-you-section .return-btn {
        width: 100%;
    }

    .register_button-2 {
        text-align: center;
    }

    .hide-small{
        display: none;
    }

    .hide-big{
        display: block;
    }
}

@media (max-width: 575.98px) {
    
    .register_button-2{
        p{
            margin: auto;
            text-align: center;
            .contact__form--button {
                width: 100%;
                max-width: 302px;
                height: 55px;
                margin-top: 0;                
                padding: 12px 18px;
                
            }
        }
    }
    
}